import { FC, useEffect, useState } from "react";
import { CheckboxLeftRight } from "../setting/checkboxLeftRight";
import {
  AddParticipant,
  DeleteParticipant,
  participantType,
} from "../../../store/settingSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { subscribeType } from "../../../store/subscribeSlice";
import { Users } from "../../../store/loginSlice";

type ParticipantSelectType = {
  gender: number;
  Part: participantType[];
  s: subscribeType;
  championship_id: number;
  categoryABC: string;
  championship_category_id: number;
  championship_sub_category_id: number;
  login: Users[];
  user: Users;
};

export const ParticipantSelect: FC<ParticipantSelectType> = ({
  gender,
  Part,
  s,
  categoryABC,
  championship_category_id,
  championship_id,
  championship_sub_category_id,
  login,
  user,
}) => {
  const dispatch = useAppDispatch();
  const findChildPart: participantType =
    Part.find(
      (item: participantType) =>
        item.child === s?.following_id &&
        item.championship_id === championship_id &&
        item.championship_category_id === championship_category_id &&
        item.categoryABC === categoryABC &&
        item.championship_sub_category_id === championship_sub_category_id
    ) || {};
  const anotherChildPart: participantType[] = Part.filter(
    (item: participantType) =>
      item.championship_id === championship_id && item.child === s?.following_id
  );
  const myChild: Users =
    login.find((item: Users) => item?.id === s?.following_id) || {};

  let child: number = s?.following_id || 0;

  let j = false;
  if (findChildPart?.id) {
    j = true;
  } else {
    j = false;
  }

  let dublic = false;
  if (anotherChildPart.length !== 0 && !j) {
    dublic = true;
  } else {
    dublic = false;
  }

  const [isChecked, setIsChecked] = useState(j);
  const handleCheckboxChange = () => {
    if (isChecked === false) {
      setIsChecked(true);
      dispatch(
        AddParticipant({
          isChecked,
          championship_id,
          child,
          categoryABC,
          championship_category_id,
          championship_sub_category_id,
          gender,
        })
      );
    } else {
      const id = findChildPart?.id;
      if (isChecked === true) {
        setIsChecked(false);
        dispatch(DeleteParticipant({ id, isChecked }));
      }
    }
  };
  return (
    <>
      {!dublic && (
        <div>
          <CheckboxLeftRight
            isChecked={isChecked}
            handleCheckboxChange={handleCheckboxChange}
          />{" "}
          <div>
            {myChild?.name} {myChild?.year}
          </div>
        </div>
      )}
    </>
  );
};
