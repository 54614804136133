import React, { useState } from "react";
import axios from "axios";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { resetPass } from "../../../store/loginSlice";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const error = useAppSelector((state) => state.login.error);

  const { token } = useParams(); // Получаем токен из динамического URL
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email"); // Получаем email из query параметров

  const message = useAppSelector((state) => state.login.resetPassMessage);

  const dispatch = useAppDispatch();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(resetPass({ token, email, password, password_confirmation }));
  };

  return (
    <div>
      <h1>Сброс пароля</h1>
      {message && <p className="green">{message}</p>}
      {error && <p className="red">{error}</p>}
      {message === "Your password has been reset." && (
        <Link to="/signin" id="texts">
          Авторизация
        </Link>
      )}
      <form onSubmit={handleSubmit}>
        <label className="labelForm">
          {password === "" ? (
            <div className="registerInputDiv"></div>
          ) : (
            <div className="registerInputDiv">Новый пароль</div>
          )}
          <input
            id="registerInput"
            type="password"
            value={password}
            placeholder="Новый пароль"
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>

        <label className="labelForm">
          {password_confirmation === "" ? (
            <div className="registerInputDiv"></div>
          ) : (
            <div className="registerInputDiv">Подтверждение пароля</div>
          )}
          <input
            id="registerInput"
            type="password"
            value={password_confirmation}
            placeholder="Подтверждение пароля"
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
        </label>

        <input type="submit" value="Сбросить пароль" />
      </form>
    </div>
  );
};

export default ResetPassword;
