import { FC, useState } from 'react'
import './style.css'
import { useAppDispatch } from '../../../../../store/hook';
import { AddCategory } from '../../../../../store/settingSlice';
import { Link } from 'react-router-dom';

export const SettingMyChamp:FC<any> = () => {
    const [name, setName] = useState('');
    const dispatch = useAppDispatch();

    dispatch(AddCategory({name}))

    return (
        <div className='SettingMyChampContainer'>
            <ul>
                <li><Link to='/AdministratorControl'> Администратор контроль</Link></li>
            </ul>
        </div>
    )
}