import { FC, useEffect, useState } from "react"
import { MdGrade } from "react-icons/md"
import { GradeType } from "../../../../../store/gradeSlice";

export const Star: FC<any> = ({ functionStar, class_id, subject, user }) => {

    const [grade, setGrade] = useState(6);

    useEffect(() => {
        if (grade !== 6) {
            functionStar(subject, class_id, user, grade)
        }
    }, [grade]);
    useEffect(() => {
        setGrade(6)
    }, [subject, class_id]);

    const offClick = () => {
    }
    return (
        <div className="Star">
            <MdGrade className="transparent MdGrade" fill={grade > 0 && grade < 6 ? "rgb(195, 210, 53)" : ""} onClick={grade === 6 ? (e) => setGrade(1) : offClick} />
            <MdGrade className="transparent MdGrade" fill={grade > 1 && grade < 6 ? "rgb(195, 210, 53)" : ""} onClick={grade === 6 ? (e) => setGrade(2) : offClick} />
            <MdGrade className="transparent MdGrade" fill={grade > 2 && grade < 6 ? "rgb(195, 210, 53)" : ""} onClick={grade === 6 ? (e) => setGrade(3) : offClick} />
            <MdGrade className="transparent MdGrade" fill={grade > 3 && grade < 6 ? "rgb(195, 210, 53)" : ""} onClick={grade === 6 ? (e) => setGrade(4) : offClick} />
            <MdGrade className="transparent MdGrade" fill={grade > 4 && grade < 6 ? "rgb(195, 210, 53)" : ""} onClick={grade === 6 ? (e) => setGrade(5) : offClick} />
            <div className={grade === 7 ? "NYellow" : ""} id="N" onClick={(e) => setGrade(7)}>N</div>
        </div >
    )
}