import { FC, useEffect, useMemo, useState } from "react";
import { MdOutlinePlace, MdPeopleAlt } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { AddCountry, Users } from "../../../../store/loginSlice";
import { url } from "../../../../store/url";
import { RiSettingsLine } from "react-icons/ri";
import "./style.css";
import {
  AddCategory,
  AddSubCategory,
  DeleteCategory,
  DeleteSubCategory,
  UpdateCategory,
  UpdateSubCategory,
  createChampType,
  createKTGType,
  getCategory,
  getParticipant,
  getSubCategory,
  participantType,
  subCategoryType,
} from "../../../../store/settingSlice";
import { Participant } from "./participant/page";
import {
  getSubscribeType,
  subscribeType,
} from "../../../../store/subscribeSlice";
import { FaMale, FaFemale, FaPencilAlt, FaCheck } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";
import {
  AdmissionAdminsType,
  GetAdmissionAdmins,
  SType,
} from "../../../../store/setkaSlice";
import { notDublicate } from "../../../notDublicate";
import { CoachMap } from "./CoachMap/page";

let Api = url();

type ChampResultType = {
  ch: createChampType;
  login: Users[];
  user: Users;
  subsAllCoachChild: subscribeType[];
};

export const ChampResult: FC<ChampResultType> = ({
  ch,
  login,
  user,
  subsAllCoachChild,
}) => {
  const [categoryABC, setKtg] = useState("");
  const [gender, setGender] = useState(1);
  const dispatch = useAppDispatch();
  const CategoryChamp = useAppSelector(
    (state) => state.setting.championshipCategory
  );
  const SubCategoryChamp = useAppSelector(
    (state) => state.setting.championshipSubCategory
  );
  const Part = useAppSelector((state) => state.setting.participant);

  const championship_id = ch?.id;
  const CreatorData: Users =
    login.find((item: Users) => item?.id === ch?.creator) || {};
  let fCategoryChamp: createKTGType[] = CategoryChamp.filter(
    (item: createKTGType) =>
      item?.championship_id === championship_id &&
      item?.categoryABC === categoryABC &&
      item.gender === gender
  );
  let fPartA: any[] = Part.filter(
    (item: participantType) =>
      item?.championship_id === championship_id && item?.categoryABC === "A"
  );
  let fPartB: any[] = Part.filter(
    (item: participantType) =>
      item?.championship_id === championship_id && item?.categoryABC === "B"
  );
  let fPartC: any[] = Part.filter(
    (item: participantType) =>
      item?.championship_id === championship_id && item?.categoryABC === "C"
  );

  const subscribe = useAppSelector((state) => state.subscribe.subscribe);

  // balalardin trenerin tabu
  let ChildrensCoaches = useMemo(() => {
    let ChildrensCoaches: number[] = [];
    for (let i = 0; i < Part.length; i++) {
      const id: number = Part[i]?.child;
      let fSubsCoach: subscribeType[] = subscribe.filter(
        (item: subscribeType) => item?.following_id === id && item.type === 2
      );

      // if (fSubsCoach.length === 0) {
      //   dispatch(getSubscribeType({ id, type: 2 })); ...
      // }

      // bir balanin  trenerinin associasi
      let filterCoachAssocSubs: subscribeType[] = subscribe.filter(
        (item: subscribeType) =>
          item?.following_id === Part[i].coach &&
          item?.approved === 1 &&
          item?.type === 3
      );
      if (filterCoachAssocSubs.length === 0) {
        // dispatch(getSubscribeType({ id: Part[i]?.coach || 0, type: 3 })); // trenerdin associasin tabu ...
      }
      if (filterCoachAssocSubs.length > 0) {
        for (let i2 = 0; i2 < filterCoachAssocSubs.length; i2++) {
          let id = filterCoachAssocSubs[i2]?.follower_id;

          let f: any[] = ChildrensCoaches.filter(
            (item: any) => item === Part[i]?.coach
          );
          //Users
          let userAssoc: Users =
            login.find((item: Users) => item?.id === id) || {};
          let userCoach: Users =
            login.find(
              (item: Users) =>
                item?.id === filterCoachAssocSubs[i2]?.following_id
            ) || {};
          let userChild: Users =
            login.find((item: Users) => item?.id === Part[i]?.child) || {};

          if (userCoach.country === null) {
            dispatch(
              AddCountry({
                id: userCoach?.id,
                country: userAssoc?.country || null,
                region: userAssoc?.region || null,
                city: userAssoc?.city || null,
              })
            );
          }
          if (userChild.country === null) {
            dispatch(
              AddCountry({
                id: userChild?.id,
                country: userAssoc?.country || null,
                region: userAssoc?.region || null,
                city: userAssoc?.city || null,
              })
            );
          }

          if (f.length < 1) {
            ChildrensCoaches = [...ChildrensCoaches, Part[i]?.coach || 0];
          }
        }
      }
    }
    return ChildrensCoaches;
  }, [subscribe, Part]);

  useEffect(() => {
    dispatch(getCategory({ championship_id }));
    dispatch(getSubCategory({ championship_id }));
    dispatch(getParticipant({ championship_id }));
    dispatch(GetAdmissionAdmins({ championship_id }));
  }, []);

  const CountryStor = useAppSelector((state) => state.login.country);
  const CityStor = useAppSelector((state) => state.login.city);
  const RegionStor = useAppSelector((state) => state.login.region);
  let hu: Users = login.find((item: Users) => item.id === ch.creator) || {};
  // let findKG = KG?.filter(item => item.years === year && item.categoryABC === categoryABC)

  let CS = CountryStor.find((item) => item.id === hu.country),
    rC = RegionStor.find((item) => item.id === hu.region),
    cC = CityStor.find((item) => item.id === hu.city);

  let open_tournamentParse = Date.parse(ch.open_tournament);
  let open_tournament = new Date(open_tournamentParse);
  let close_registerParse = Date.parse(ch.close_register);
  let close_register = new Date(close_registerParse);
  let close_tournamentParse = Date.parse(ch.close_tournament);
  let close_tournament = new Date(close_tournamentParse);

  const participant = useAppSelector((state) => state.setting.participant);
  const AdmissionAdmin = useAppSelector((state) => state.setka.admissionAdmin);

  const allSetka: SType[] = useAppSelector((state) => state.setka.setka);
  let fAllSetka: SType[] = allSetka.filter(
    (item: SType) => item?.championship_id === championship_id
  );
  let Coach: any[] = useMemo(() => {
    let coach: any[] = [];
    for (let p = 0; p < participant.length; p++) {
      for (let s = 0; s < fAllSetka.length; s++) {
        if (fAllSetka[s]?.championship_participant_id === participant[p]?.id) {
          let fCoach: any[] = coach.filter(
            (item: any) => item?.coach === participant[p]?.coach
          );
          if (fCoach.length === 0) {
            if (fAllSetka[s]?.place === 1) {
              coach = [
                ...coach,
                {
                  coach: participant[p]?.coach,
                  gold: 1,
                  silver: 0,
                  bronze: 0,
                },
              ];
            }
            if (fAllSetka[s]?.place === 2) {
              coach = [
                ...coach,
                {
                  coach: participant[p]?.coach,
                  gold: 0,
                  silver: 1,
                  bronze: 0,
                },
              ];
            }
            if (fAllSetka[s]?.place === 4) {
              coach = [
                ...coach,
                {
                  coach: participant[p]?.coach,
                  gold: 0,
                  silver: 0,
                  bronze: 1,
                },
              ];
            }
          } else {
            for (let ci = 0; ci < coach.length; ci++) {
              if (participant[p]?.coach === coach[ci]?.coach) {
                if (fAllSetka[s]?.place === 1) {
                  let medalGold = coach[ci]?.gold + 1;
                  let medalSilver = coach[ci]?.silver;
                  let medalBronze = coach[ci]?.bronze;
                  coach[ci] = {
                    coach: participant[p]?.coach,
                    gold: medalGold,
                    silver: medalSilver,
                    bronze: medalBronze,
                  };
                }
                if (fAllSetka[s]?.place === 2) {
                  let medalGold = coach[ci]?.gold;
                  let medalSilver = coach[ci]?.silver + 1;
                  let medalBronze = coach[ci]?.bronze;
                  coach[ci] = {
                    coach: participant[p]?.coach,
                    gold: medalGold,
                    silver: medalSilver,
                    bronze: medalBronze,
                  };
                }
                if (fAllSetka[s]?.place === 4) {
                  let medalGold = coach[ci]?.gold;
                  let medalSilver = coach[ci]?.silver;
                  let medalBronze = coach[ci]?.bronze + 1;
                  coach[ci] = {
                    coach: participant[p]?.coach,
                    gold: medalGold,
                    silver: medalSilver,
                    bronze: medalBronze,
                  };
                }
              }
            }
          }
        }
      }
    }
    return coach;
  }, [allSetka, participant]);

  Coach.sort(
    (a, b) => b.gold - a.gold || b.silver - a.silver || b.bronze - a.bronze
  );

  return (
    <div>
      <h2 className="nameChamp">{ch.name}</h2>
      {/* <HeaderChamp /> */}
      <BodyChampInfo
        ch={ch}
        hu={hu}
        user={user}
        close_register={close_register}
        open_tournament={open_tournament}
        close_tournament={close_tournament}
        CS={CS}
        rC={rC}
        cC={cC}
        participant={participant}
      />
      <SelectParticipant
        fPartA={fPartA}
        fPartB={fPartB}
        fPartC={fPartC}
        ch={ch}
        setKtg={setKtg}
        categoryABC={categoryABC}
      />
      <Category
        allSetka={allSetka}
        AdmissionAdmin={AdmissionAdmin}
        gender={gender}
        setGender={setGender}
        Part={Part}
        subsAllCoachChild={subsAllCoachChild}
        championship_id={championship_id}
        fCategoryChamp={fCategoryChamp}
        categoryABC={categoryABC}
        SubCategoryChamp={SubCategoryChamp}
        user={user}
        ch={ch}
      />
      {categoryABC === "Coach" && (
        <div>
          {Coach.map((c, index) => (
            <CoachMap
              coach={c}
              login={login}
              participant={participant}
              championship_id={championship_id}
              index={index}
            />
          ))}
        </div>
      )}
      <div className="leftovers"></div>
    </div>
  );
};

const HeaderChamp: FC<any> = () => {
  return (
    <div>
      <div className="HeaderChamp">
        <span>О турнире</span>
        <span>Участьники</span>
        <span>Сетка</span>
        <span>Создать сетку</span>
        <span>Изменить сетку</span>
      </div>
    </div>
  );
};

const BodyChampInfo: FC<any> = ({
  ch,
  hu,
  close_register,
  open_tournament,
  close_tournament,
  CS,
  rC,
  cC,
  user,
  participant,
}) => {
  return (
    <div id="bodyChampInfoContainer">
      {ch?.creator === user?.id && (
        <Link to={"/settingChampionship"}>
          <RiSettingsLine id="setttingIconChamp" />
        </Link>
      )}

      <div>
        {hu.ava && (
          <div id="avaClubChampRemove" className="ava">
            <img
              className="avaImg"
              src={Api + "storage/championship/avatar/" + ch.foto}
              alt=""
            />
          </div>
        )}
      </div>
      <div className="divBodyChamp">
        <span>
          <div>
            <div>Клуб:</div>
            <div className="f">{hu?.name}</div>
          </div>
        </span>
        <span>
          <div>
            <div>Категория: </div>
            <div className="f">
              {ch.ranking === 1 && "L1"}
              {ch.ranking === 2 && "L2"}
              {ch.ranking === 3 && "L3"}
              {ch.ranking === 4 && "L4"}
              {ch.ranking === 5 && "L5"}
              {ch.ranking === 6 && "L6"}
              {ch.ranking === 7 && "L7"}
              {ch.ranking === 8 && "L8"}
              {ch.ranking === 9 && "L9"}
              {ch.ranking === 10 && "W1"}
              {ch.ranking === 11 && "W2"}
              {ch.ranking === 12 && "W3"}
              {ch.ranking === 13 && "W4"}
              {ch.ranking === 14 && "W5"}
              {ch.ranking === 15 && "W6"}
              {ch.ranking === 16 && "W7"}
              {ch.ranking === 17 && "W8"}
              {ch.ranking === 18 && "W9"}
              {ch.ranking === 19 && "W10"}
            </div>
          </div>
        </span>
        <span>
          <div>
            <div className="f">
              {participant?.length}/{ch?.restric}
            </div>
          </div>
        </span>
        <span>
          <div>
            <div className="f">
              Регистрация заканчивается: {close_register.getDate()}.
              {close_register.getMonth()}.{close_register.getFullYear()}г.
            </div>
          </div>
        </span>
        <span>
          <div>
            <div>Начало соревнований:</div>
            <div className="f">
              {open_tournament.getDate()}.{open_tournament.getMonth()}.
              {open_tournament.getFullYear()}г.
            </div>
          </div>
        </span>
        <span>
          <div>
            <div>Конечный день:</div>
            <div className="f">
              {close_tournament.getDate()}.{close_tournament.getMonth()}.
              {close_tournament.getFullYear()}г.
            </div>
          </div>
        </span>
        <span>
          <div className="plaseChamp">
            <div>
              <div>
                <MdOutlinePlace className="pCh" />
              </div>
              <div>{CS?.name}</div>
            </div>
            <div>
              <div>
                <MdOutlinePlace className="pCh" />
              </div>
              <div>{rC?.name}</div>
            </div>
            <div>
              <div>
                <MdOutlinePlace className="pCh" />
              </div>
              <div>г.{cC?.name}</div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};

export const Coach: FC<any> = ({}) => {
  return <div></div>;
};

const SelectParticipant: FC<any> = ({
  fPartA,
  fPartB,
  fPartC,
  ch,
  setKtg,
  categoryABC,
}) => {
  let date = new Date();
  return (
    <div className="SettingMyChampContainer">
      <div className="selectParticipant">
        <div className="sportInfo">
          {ch.checkA === 1 && (
            <div
              className="nameChampCategory"
              id={categoryABC === "A" ? "click" : ""}
              onClick={(e) => setKtg("A")}
            >
              A{" "}
              {fPartA?.length > 0 ? (
                <main className="champPeopleLength">
                  <MdPeopleAlt className="champPeopleIcon" />
                  {fPartA?.length}
                </main>
              ) : (
                ""
              )}
            </div>
          )}
          {ch.checkB === 1 && (
            <div
              className="nameChampCategory"
              id={categoryABC === "B" ? "click" : ""}
              onClick={(e) => setKtg("B")}
            >
              B{" "}
              {fPartB?.length > 0 ? (
                <main className="champPeopleLength">
                  <MdPeopleAlt className="champPeopleIcon" />
                  {fPartB?.length}
                </main>
              ) : (
                ""
              )}
            </div>
          )}
          {ch.checkC === 1 && (
            <div
              className="nameChampCategory"
              id={categoryABC === "C" ? "click" : ""}
              onClick={(e) => setKtg("C")}
            >
              C{" "}
              {fPartC?.length > 0 ? (
                <main className="champPeopleLength">
                  <MdPeopleAlt className="champPeopleIcon" />
                  {fPartC?.length}
                </main>
              ) : (
                ""
              )}
            </div>
          )}
          <div
            className="nameChampCategory"
            id={categoryABC === "Coach" ? "click" : ""}
            onClick={(e) => setKtg("Coach")}
          >
            Лучшие тренеры{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

type CategoryType = {
  allSetka: SType[];
  AdmissionAdmin: AdmissionAdminsType[];
  championship_id: number;
  fCategoryChamp: createKTGType[];
  categoryABC: string;
  SubCategoryChamp: subCategoryType[];
  user: Users;
  ch: createChampType;
  subsAllCoachChild: subscribeType[];
  Part: participantType[];
  gender: number;
  setGender: any;
};

const Category: FC<CategoryType> = ({
  allSetka,
  AdmissionAdmin,
  gender,
  setGender,
  Part,
  subsAllCoachChild,
  championship_id,
  fCategoryChamp,
  categoryABC,
  SubCategoryChamp,
  user,
  ch,
}) => {
  const [name, setName] = useState("");
  const dispatch = useAppDispatch();
  const creator = ch?.creator;

  const [championship_category_id, setChampionship_category_id] = useState(0);
  const [championship_sub_category_id, setSubCategory] = useState(0);
  useEffect(() => {
    setChampionship_category_id(0);
    setSubCategory(0);
  }, [categoryABC, gender]);

  useEffect(() => {
    setSubCategory(0);
  }, [championship_category_id]);

  const addCategoryFunction = () => {
    if (isValid) {
      dispatch(
        AddCategory({ name, championship_id, categoryABC, creator, gender })
      );
    }
    setName("");
  };
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if (categoryABC && name) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [categoryABC, name]);

  let fSubCategoryChamp: subCategoryType[] = SubCategoryChamp.filter(
    (item: subCategoryType) =>
      item?.championship_id === championship_id &&
      item?.championship_category_id === championship_category_id
  );

  const [subName, setSubName] = useState("");
  const [isValidSub, setIsValidSub] = useState(false);
  useEffect(() => {
    if (championship_category_id !== 0 && subName) {
      setIsValidSub(true);
    } else {
      setIsValidSub(false);
    }
  }, [championship_sub_category_id, subName]);

  const addSubCategoryFunction = () => {
    if (isValidSub) {
      const name = subName;
      dispatch(
        AddSubCategory({
          name,
          championship_id,
          championship_category_id,
          creator,
        })
      );
    }
    setSubName("");
  };

  const [textCategory, setTextCategory] = useState("");

  const updateTextCategory = (e: string, type: string) => {
    if (type === "category") {
      setTextCategory(e);
    }
    if (type === "subCategory") {
      setTextCategory(e);
    }
  };
  const [globalInput, setGlobalInput] = useState(0);

  return (
    <div className="SettingMyChampContainer">
      {(categoryABC === "A" || categoryABC === "B" || categoryABC === "C") && (
        <div className="selectParticipant">
          <div className="sportInfo">
            <div
              className="nameChampCategory"
              id={gender === 1 ? "clickGenderMale" : ""}
              onClick={(e) => setGender(1)}
            >
              <FaMale
                id={gender === 1 ? "clickGenderMaleIcon" : ""}
                className="champGender"
              />
            </div>
            <div
              className="nameChampCategory"
              id={gender === 2 ? "clickGenderFemale" : ""}
              onClick={(e) => setGender(2)}
            >
              <FaFemale
                id={gender === 2 ? "clickGenderFemaleIcon" : ""}
                className="champGender"
              />
            </div>
          </div>
        </div>
      )}
      {user?.id === creator &&
        (categoryABC === "A" || categoryABC === "B" || categoryABC === "C") && (
          <div className="inputColumn">
            <input
              type="text"
              value={name}
              id="registerInput"
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="submit"
              id={isValid ? "submitIsValid" : ""}
              value={"Создать категорию"}
              onClick={addCategoryFunction}
              disabled={!isValid}
            />
          </div>
        )}
      <div className="selectParticipant">
        <div className="sportInfo">
          {fCategoryChamp.map((ctg) => (
            <CategoryMap
              creator={creator}
              user={user}
              Part={Part}
              globalInput={globalInput}
              setGlobalInput={setGlobalInput}
              ctg={ctg}
              ctgClick={setChampionship_category_id}
              championship_category_id={championship_category_id}
              textCategory={textCategory}
              updateTextCategory={updateTextCategory}
            />
          ))}
        </div>
      </div>
      {user?.id === creator &&
      categoryABC &&
      fCategoryChamp.length !== 0 &&
      championship_category_id ? (
        <div className="inputColumn">
          <input
            type="text"
            value={subName}
            id="registerInput"
            onChange={(e) => setSubName(e.target.value)}
          />
          <input
            type="submit"
            id={isValidSub ? "submitIsValid" : ""}
            value={"Создать подкатегорию"}
            onClick={addSubCategoryFunction}
            disabled={!isValidSub}
          />
        </div>
      ) : (
        <div></div>
      )}
      <div className="selectParticipant">
        <div className="sportInfo">
          {fSubCategoryChamp.map((ctg) => (
            <SubCategoryMap
              creator={creator}
              user={user}
              Part={Part}
              globalInput={globalInput}
              setGlobalInput={setGlobalInput}
              ctg={ctg}
              ctgClick={setSubCategory}
              championship_sub_category_id={championship_sub_category_id}
              textCategory={textCategory}
              updateTextCategory={updateTextCategory}
            />
          ))}
        </div>
      </div>
      <Participant
        allSetka={allSetka}
        AdmissionAdmin={AdmissionAdmin}
        user={user}
        ch={ch}
        gender={gender}
        championship_id={championship_id}
        subsAllCoachChild={subsAllCoachChild}
        categoryABC={categoryABC}
        championship_sub_category_id={championship_sub_category_id}
        championship_category_id={championship_category_id}
        Part={Part}
      />
    </div>
  );
};

type CategoryMapType = {
  creator: number;
  user: Users;
  ctg: createKTGType;
  ctgClick: any;
  championship_category_id?: number;
  championship_sub_category_id?: number;
  updateTextCategory: any;
  textCategory: string;
  setGlobalInput: any;
  globalInput: number;
  Part: participantType[];
};

export const CategoryMap: FC<CategoryMapType> = ({
  creator,
  user,
  Part,
  globalInput,
  setGlobalInput,
  textCategory,
  ctg,
  ctgClick,
  championship_category_id,
  updateTextCategory,
}) => {
  const [Input, setInput] = useState(false);
  const dispatch = useAppDispatch();
  let fPart: any[] = Part.filter(
    (item: participantType) => item?.championship_category_id === ctg?.id
  );

  const clickInput = () => {
    setGlobalInput(ctg?.id);
    if (creator === user?.id) {
      setInput(true);
    }
  };

  useEffect(() => {
    if (globalInput !== ctg?.id) {
      setInput(false);
    }
  }, [globalInput]);

  const clickCategory = () => {
    ctgClick(ctg?.id);
  };

  useEffect(() => {
    if (Input) {
      updateTextCategory(ctg?.name, "category");
    }
  }, [Input]);

  const deleteCategoryClick = () => {
    let del = window.confirm(
      `Точно хотите удалить категорию '${ctg?.name}'? Тогда будет удаляться все подкатегории которые вы создали!`
    );
    const id = ctg?.id;
    if (del) {
      dispatch(DeleteCategory({ id }));
    }
  };

  const updateClick = () => {
    const id = ctg?.id,
      name = textCategory;
    dispatch(UpdateCategory({ id, name }));
    setInput(false);
  };

  const clickCancel = () => {
    setInput(false);
    updateTextCategory("", "category");
  };

  return (
    <>
      {Input ? (
        <main>
          <input
            type="text"
            autoFocus
            id="champCategoryInput"
            value={textCategory}
            onChange={(e) => updateTextCategory(e.target.value, "category")}
          />
          <FaCheck onClick={updateClick} />
          <ImCancelCircle onClick={clickCancel} />
        </main>
      ) : (
        <div
          id={championship_category_id === ctg?.id ? "click" : ""}
          className="nameChampCategory"
          onClick={clickCategory}
        >
          {ctg?.name}{" "}
          {fPart?.length > 0 ? (
            <main className="champPeopleLength">
              <MdPeopleAlt className="champPeopleIcon" />
              {fPart?.length}
            </main>
          ) : (
            ""
          )}{" "}
          {
            <main className="champRemoveCategoryIcons">
              {creator === user?.id && (
                <FaPencilAlt
                  className="PenChampCategory"
                  onClick={clickInput}
                />
              )}
              {creator === user?.id && (
                <MdDelete
                  className="PenChampCategory"
                  onClick={deleteCategoryClick}
                />
              )}
            </main>
          }
        </div>
      )}
    </>
  );
};

export const SubCategoryMap: FC<CategoryMapType> = ({
  creator,
  user,
  Part,
  globalInput,
  setGlobalInput,
  textCategory,
  ctg,
  ctgClick,
  championship_sub_category_id,
  updateTextCategory,
}) => {
  const [Input, setInput] = useState(false);
  const dispatch = useAppDispatch();
  let fPart: any[] = Part.filter(
    (item: participantType) => item?.championship_sub_category_id === ctg?.id
  );

  const clickInput = () => {
    setGlobalInput(ctg?.id);
    if (creator === user?.id) {
      setInput(true);
    }
  };

  useEffect(() => {
    if (globalInput !== ctg?.id) {
      setInput(false);
    }
  }, [globalInput]);

  const clickCategory = () => {
    ctgClick(ctg?.id);
  };

  useEffect(() => {
    if (Input) {
      updateTextCategory(ctg?.name, "subCategory");
    }
  }, [Input]);

  const deleteCategoryClick = () => {
    let del = window.confirm(
      `Точно хотите удалить подкатегорию '${ctg?.name}'?`
    );
    const id = ctg?.id;
    if (del) {
      dispatch(DeleteSubCategory({ id }));
    }
  };

  const updateClick = () => {
    const id = ctg?.id,
      name = textCategory;
    dispatch(UpdateSubCategory({ id, name }));
    setInput(false);
  };

  const clickCancel = () => {
    setInput(false);
    updateTextCategory("", "subCategory");
  };

  return (
    <>
      {Input ? (
        <main>
          <input
            type="text"
            autoFocus
            id="champCategoryInput"
            value={textCategory}
            onChange={(e) => updateTextCategory(e.target.value, "subCategory")}
          />
          <FaCheck onClick={updateClick} />
          <ImCancelCircle onClick={clickCancel} />
        </main>
      ) : (
        <div
          id={championship_sub_category_id === ctg?.id ? "click" : ""}
          className="nameChampCategory"
          onClick={clickCategory}
        >
          {ctg?.name}{" "}
          {fPart?.length > 0 ? (
            <main className="champPeopleLength">
              <MdPeopleAlt className="champPeopleIcon" />
              {fPart?.length}
            </main>
          ) : (
            ""
          )}{" "}
          {
            <main className="champRemoveCategoryIcons">
              {creator === user?.id && (
                <FaPencilAlt
                  className="PenChampCategory"
                  onClick={clickInput}
                />
              )}
              {creator === user?.id && (
                <MdDelete
                  onClick={deleteCategoryClick}
                  className="PenChampCategory"
                />
              )}
            </main>
          }
        </div>
      )}
    </>
  );
};
