import { FC, useMemo, useState } from 'react'
import { Users } from '../../../../../store/loginSlice'
import { exercisesCoachType } from '../../../../../store/exercisesSlice'
import { ExercisesAll } from './exercisesAll'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

type AssocExerType = {
    a: string
    Userter: Users[]
    exercisesCoach: exercisesCoachType[]
    User?: Users
    pageData?: string
}


export const AssocExer: FC<AssocExerType> = ({ a, Userter, exercisesCoach, User, pageData }) => {
    const [show, setShow] = useState(false);
    const exerShow = () => {
        setShow(true)
    }
    const exerHide = () => {
        setShow(false)
    }

    let f: Users = Userter.find((item: Users) => item?.id === a) || {}
    // let exerCoach: exercisesCoachType[] = exercisesCoach.filter((item: exercisesCoachType) => item?.coach === a)
    let exerCoach: exercisesCoachType[] = useMemo(() => {
        let exer: exercisesCoachType[] = []
        for (let i = 0; i < exercisesCoach.length; i++) {
            
            if (exercisesCoach[i]?.creator === User?.id && User?.id === pageData && exercisesCoach[i]?.creator === a) {
                    exer = [...exer, exercisesCoach[i]]
            }else{
                if (exercisesCoach[i]?.creator === a && exercisesCoach[i]?.forChild === 1) {
                    exer = [...exer, exercisesCoach[i]]
                }
            }
        }
        return exer
    }, [exercisesCoach]);
    
    return (
        <div className='ContinerAssocExer'>
            <div className='AssocName'>
                <div className='nameHomework'>
                    <div>{f?.name}</div>
                    <div>
                        {f?.position === 2 && <main className="position">Coach</main>}
                        {f?.position === 3 && <main className="position">Association</main>}
                    </div>
                </div>
                
                {!show && <IoIosArrowDown className='arrow' onClick={exerShow} />}
                {show && <IoIosArrowUp className='arrow' onClick={exerHide} />}
            </div>
            
            {show && exerCoach.map((e) => (
                <ExercisesAll ex={e} User={User} pageData={pageData} />
            ))}
        </div>
    )
}