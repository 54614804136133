import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../store/hook";
import { RootState } from "../../store";
import { Navigate } from "react-router-dom";


const HashPass: FC<any> = ({ hashPass, nullEmail }) => {
    const hashP = useAppSelector((state: RootState) => state.login.hashPasss);
    const recov = useAppSelector(state => state.login.recPass);
    const status = useAppSelector(state => state.login.user.status);
    if (recov.status) {
        nullEmail();
    }
    let Adam = {
        hash: '',
      }
      
    const [names, setNames] = useState(Adam);

    const updateSignin = (e: React.ChangeEvent<HTMLInputElement>, name: any) => {
        setNames(prevState => ({
          ...prevState,
          [name]: e.target.value
        }));
    }

    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset
    } = useForm({
        mode: "onBlur"
    });
    if (status) {
        return <Navigate to={"/password"} />
    }
    return (
        <div>
            <h1>Восстановление пароля</h1>
            <form onSubmit={handleSubmit(hashPass)}>
                <div style={{ height: 40, color: "green" }}>Письмо с кодом подтверждения отправено на Вашу электронную почту</div>
                <label className="labelForm">
                    {names?.hash === '' ? <div className="registerInputDiv"></div> : <div className="registerInputDiv">Введите код</div>}
                    <input
                        type="text"
                        placeholder='Введите код'
                        {...register("hash", {
                            onChange: (e) =>{ updateSignin(e, 'hash') },
                            required: "Поле обязательно к заполнению",
                            minLength: {
                                value: 3,
                                message: 'Минимум 3 символа!'
                            }
                        })} />
                </label>
                <div style={{ height: 40, color: 'red' }}>
                    {errors?.hash && <p>{(errors as any)?.hash?.message || "Error!"}</p>}
                </div>
                {hashP!.error && <div style={{ height: 40, color: "red" }}><p>"К сожалению, вы ввели неправильный код.Проверьте правильность кода и повторите попытку."</p></div>}

                <input type="submit" value="Восстановить пароль" disabled={!isValid} />
            </form>
        </div>
    )
};
export default HashPass;