import { createSlice, createAsyncThunk, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { api, instanceGET, instancePOST } from './axiosInstans';
import { string } from 'yup';
import { url } from './url';
let Apiq = url();

export type classADDType = {
    id?: any
    assoc_id?: any
    title?: any
    Subject?: any
}
export type childAddClassType = {
    id?: any
    child?: any
    coach?: any
    class_id?: any
}
export type GradeType = {
    id?: any
    subject_id?: any
    class_id?: any
    grades?: any
    study_time?: any
    child?: any
    coach?: any
    assoc?: any
    created_at?: any
    status?: any
    message?: any
    length?: any
}

export const classADD = createAsyncThunk<classADDType, classADDType, { rejectValue: string }>(
    'grade/classADD',
    async function ({ title }, { rejectWithValue }) {
        try {
            const jsonS = {
                title
            }

            const response: any = await api.post(Apiq + 'api/auth/AddClass', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as classADDType
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const classDelete = createAsyncThunk<any, any, { rejectValue: string }>(
    'grade/classDelete',
    async function ({ id }, { rejectWithValue }) {
        try {
            const jsonS = {
                id
            }

            const response: any = await api.post(Apiq + 'api/auth/DeleteClass', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as any
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const getClass = createAsyncThunk<classADDType[], classADDType, { rejectValue: string }>(
    'grade/getClass',
    async function ({ assoc_id }, { rejectWithValue }) {
        try {
            const jsonS = {
                assoc_id,
            }

            const response: any = await api.post(Apiq + 'api/auth/GetClass', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as classADDType[]
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const classUpdate = createAsyncThunk<any, any, { rejectValue: string }>(
    'grade/classUpdate',
    async function ({ id, title }, { rejectWithValue }) {
        try {
            const jsonS = {
                id,
                title
            }

            const response: any = await api.post(Apiq + 'api/auth/UpdateClass', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as any
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const pageOpen = createAsyncThunk<any, any, { rejectValue: string }>(
    'grade/pageOpen',
    async function ({ assoc_id }, { rejectWithValue }) {
        try {
            return (await assoc_id) as any
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);

export const SubjectADD = createAsyncThunk<classADDType, classADDType, { rejectValue: string }>(
    'grade/SubjectADD',
    async function ({ Subject }, { rejectWithValue }) {
        try {
            const title = Subject;
            const jsonS = {
                title
            }

            const response: any = await api.post(Apiq + 'api/auth/AddSubject', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as classADDType
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const subjectDelete = createAsyncThunk<any, any, { rejectValue: string }>(
    'grade/subjectDelete',
    async function ({ id }, { rejectWithValue }) {
        try {
            const jsonS = {
                id
            }

            const response: any = await api.post(Apiq + 'api/auth/DeleteSubject', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as any
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const getSubject = createAsyncThunk<classADDType[], classADDType, { rejectValue: string }>(
    'grade/getSubject',
    async function ({ assoc_id }, { rejectWithValue }) {
        try {
            const jsonS = {
                assoc_id,
            }

            const response: any = await api.post(Apiq + 'api/auth/GetSubject', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as classADDType[]
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const subjectUpdate = createAsyncThunk<any, any, { rejectValue: string }>(
    'grade/subjectUpdate',
    async function ({ id, title }, { rejectWithValue }) {
        try {
            const jsonS = {
                id,
                title
            }

            const response: any = await api.post(Apiq + 'api/auth/UpdateSubject', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as any
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);

export const childAddClass = createAsyncThunk<childAddClassType, childAddClassType, { rejectValue: string }>(
    'grade/childAddClass',
    async function ({ child, class_id }, { rejectWithValue }) {
        try {
            const jsonS = {
                child,
                class_id,
            }

            const response: any = await api.post(Apiq + 'api/auth/AddClassChildren', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as childAddClassType
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const childDeleteClass = createAsyncThunk<childAddClassType, childAddClassType, { rejectValue: string }>(
    'grade/childDeleteClass',
    async function ({ id }, { rejectWithValue }) {
        try {
            const jsonS = {
                id
            }
            console.log({jsonS});

            const response: any = await api.post(Apiq + 'api/auth/DeleteClassChildren', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as childAddClassType
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const childDeleteClass2 = createAsyncThunk<childAddClassType, childAddClassType, { rejectValue: string }>(
    'grade/childDeleteClass2',
    async function ({ class_id, child }, { rejectWithValue }) {
        try {
            const jsonS = {
                class_id,
                child
            }
            console.log({jsonS});

            const response: any = await api.post(Apiq + 'api/auth/DeleteClassChildren', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as childAddClassType
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const getChildClass = createAsyncThunk<any[], any, { rejectValue: string }>(
    'grade/getChildClass',
    async function ({ id }, { rejectWithValue }) {
        try {
            const jsonS = {
                id
            }

            const response: any = await api.post(Apiq + 'api/auth/GetClassChildren', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as any[]
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const pageOpenChildClass = createAsyncThunk<any, any, { rejectValue: string }>(
    'grade/pageOpenChildClass',
    async function ({ id }, { rejectWithValue }) {
        try {
            return (await id) as any
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);

export const AddGrade = createAsyncThunk<GradeType, GradeType, { rejectValue: string }>(
    'grade/AddGrade',
    async function ({ subject_id, class_id, child, coach, grades, assoc, study_time }, { rejectWithValue }) {
        try {
            const jsonS = {
                subject_id,
                class_id,
                child,
                grades,
                coach,
                assoc,
                study_time,
            }

            const response: any = await api.post(Apiq + 'api/auth/AddGrades', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as GradeType
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const UpdateGrade = createAsyncThunk<GradeType, GradeType, { rejectValue: string }>(
    'grade/UpdateGrade',
    async function ({ id, grades }, { rejectWithValue }) {
        try {
            const jsonS = {
                id,
                grades,
            }

            const response: any = await api.post(Apiq + 'api/auth/UpdateGrades', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as GradeType
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const DeleteGrade = createAsyncThunk<GradeType, GradeType, { rejectValue: string }>(
    'grade/DeleteGrade',
    async function ({ id }, { rejectWithValue }) {
        try {
            const jsonS = {
                id,
            }

            const response: any = await api.post(Apiq + 'api/auth/DeleteGrades', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as GradeType
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const getGrade = createAsyncThunk<GradeType[], GradeType, { rejectValue: string }>(
    'grade/getGrade',
    async function ({ id }, { rejectWithValue }) {
        try {
            const jsonS = {
                id
            }
            const response: any = await api.post(Apiq + 'api/auth/GetGrades', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as GradeType[]
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const pageOpenGrade = createAsyncThunk<string, any, { rejectValue: string }>(
    'grade/pageOpenGrade',
    async function ({ id }, { rejectWithValue }) {
        try {
            return (await id) as string
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);


// const setError = (state, action) => {
//     state.status = 'rejected';
//     state.error = action.payload;
// }
type gradeStateType = {
    class: any
    stateOpenPage: any
    subject: any
    loading: any
    error: any
    childClass: childAddClassType[]
    stateGetChildClassPage: any[]
    grade: GradeType[]
    statePageGrade: string[]
}

const initialState: gradeStateType = {
    class: [],
    stateOpenPage: [],
    stateGetChildClassPage: [],
    subject: [],
    loading: false,
    error: null,
    childClass: [],
    grade: [],
    statePageGrade: [],
}

const gradeSlice = createSlice({
    name: 'grade',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(classADD.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(classADD.fulfilled, (state, action) => {
                state.class.push(action.payload);
            })
            .addCase(getClass.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getClass.fulfilled, (state, action) => {
                for (let i = 0; i < action.payload.length; i++) {
                    state.class.push(action.payload[i]);
                }
            })
            .addCase(classDelete.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(classDelete.fulfilled, (state, action) => {
                state.class = state.class.filter((t: any) => t.id !== action.payload.id);
            })
            .addCase(classUpdate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(classUpdate.fulfilled, (state, action) => {
                const updateClass = state.class.find((todo: any) => todo.id === action.payload.id) || { grades: "" };
                updateClass!.title = action.payload.title;
            })
            .addCase(getSubject.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSubject.fulfilled, (state, action) => {
                for (let i = 0; i < action.payload.length; i++) {
                    state.subject.push(action.payload[i]);
                }
            })
            .addCase(subjectDelete.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(subjectDelete.fulfilled, (state, action) => {
                state.subject = state.subject.filter((t: any) => t.id !== action.payload.id);
            })
            .addCase(SubjectADD.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(SubjectADD.fulfilled, (state, action) => {
                state.subject.push(action.payload);
            })
            .addCase(subjectUpdate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(subjectUpdate.fulfilled, (state, action) => {
                const updateSubj = state.subject.find((todo: any) => todo.id === action.payload.id) || { grades: "" };
                updateSubj!.title = action.payload.title;
            })
            .addCase(pageOpen.fulfilled, (state, action) => {
                state.stateOpenPage.push(action.payload);
            })
            .addCase(childAddClass.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(childAddClass.fulfilled, (state, action) => {
                state.childClass.push(action.payload);
            })
            .addCase(childDeleteClass.fulfilled, (state, action) => {
                state.childClass = state.childClass.filter((t: any) => t.id !== action.payload?.id);
            })
            .addCase(childDeleteClass2.fulfilled, (state, action) => {
                state.childClass = state.childClass.filter((t: any) => t.id !== action.payload?.id);
            })
            .addCase(getChildClass.fulfilled, (state, action) => {
                let d: childAddClassType[] = state.childClass;
                let g: childAddClassType[] = action.payload

                for (let i = 0; i < g.length; i++) {
                    let fD: childAddClassType[] = d.filter((item: childAddClassType) => item?.id === g[i].id)
                    if (fD.length === 0) {
                        d = [...d, g[i]];
                    }
                }
                state.childClass = d;
            })
            .addCase(pageOpenChildClass.fulfilled, (state, action) => {
                state.stateGetChildClassPage.push(action.payload);
            })
            .addCase(AddGrade.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(AddGrade.fulfilled, (state, action) => {
                state.grade.push(action.payload);
            })
            .addCase(UpdateGrade.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(UpdateGrade.fulfilled, (state, action) => {
                const updateGr = state.grade.find(todo => todo.id === action.payload.id) || { grades: "" };
                updateGr!.grades = action.payload.grades;
            })
            .addCase(DeleteGrade.fulfilled, (state, action) => {
                state.grade = state.grade.filter((t: any) => t.id !== action.payload?.id);
            })
            .addCase(pageOpenGrade.fulfilled, (state, action) => {
                let g = state.statePageGrade;
                state.statePageGrade = [...g, action.payload];
            })
            .addCase(getGrade.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getGrade.fulfilled, (state, action) => {
                let d: GradeType[] = state.grade;
                let g: GradeType[] = action.payload;

                for (let i = 0; i < g.length; i++) {
                    let fD: GradeType[] = d.filter((item: GradeType) => item?.id === g[i].id)
                    if (fD.length === 0) {
                        d = [...d, g[i]];
                    }
                }
                state.grade = d;
            })
    },
});

export default gradeSlice.reducer;
const isError = (action: AnyAction) => {
    return action.type.endsWith('rejectad');
}