import { FC } from "react";
import { KGType, participantType } from "../../../../../../store/settingSlice";
import {
  AdminType,
  AdmissionAdminsType,
  SType,
  getSetka,
} from "../../../../../../store/setkaSlice";
import { SetkiB } from "./page";

export interface setkaItemProps {
  fAdmin: AdminType[];
  AdmissionAdmin: AdmissionAdminsType[];
  childPart: participantType[];
  login: any;
  categoryABC: string;
  creator: any;
  championship_id: any;
  allGridPart: SType[];
  l: any;
}
export const SetkaContainer: FC<setkaItemProps> = ({
  fAdmin,
  AdmissionAdmin,
  categoryABC,
  childPart,
  login,
  creator,
  championship_id,
  allGridPart,
  l,
}) => {
  return (
    <div>
      {/* <Setki childPart={childPart} login={login} creator={creator} championship_id={championship_id} allGridPart={allGridPart} l={l} categoryABC={categoryABC} />  */}
      <SetkiB
        fAdmin={fAdmin}
        AdmissionAdmin={AdmissionAdmin}
        childPart={childPart}
        login={login}
        categoryABC={categoryABC}
        creator={creator}
        championship_id={championship_id}
        allGridPart={allGridPart}
        l={l}
      />
    </div>
  );
};
