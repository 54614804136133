import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate } from "react-router-dom";
import { useAppSelector } from "../../store/hook";
import { RootState } from "../../store";

const Recover: FC<any> = ({ recover }) => {
  let Adam = {
    email: "",
  };

  const [names, setNames] = useState(Adam);
  const updateSignin = (e: React.ChangeEvent<HTMLInputElement>, name: any) => {
    setNames((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  const message = useAppSelector((state) => state.login.forgotPassMessage);
  const error = useAppSelector((state) => state.login.error);

  const recP = useAppSelector((state: RootState) => state.login.recPass);
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur",
  });
  return (
    <div>
      <h1>Восстановление пароля</h1>
      {message && <p className="green">{message}</p>}
      {error && <p className="red">{error}</p>}
      <form onSubmit={handleSubmit(recover)}>
        <label className="labelForm">
          {names?.email === "" ? (
            <div className="registerInputDiv"></div>
          ) : (
            <div className="registerInputDiv">Ваш адрес эл. почты</div>
          )}
          <input
            id="registerInput"
            type="email"
            placeholder="Ваш адрес эл. почты"
            {...register("email", {
              onChange: (e) => {
                updateSignin(e, "email");
              },
              required: "Поле обязательно к заполнению",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message:
                  "Введенное значение не соответствует формату электронной почты",
              },
              minLength: {
                value: 5,
                message: "Минимум 5 символов!",
              },
            })}
          />
        </label>
        <div style={{ height: 40, color: "red" }} className="regErr">
          {errors?.email && (
            <p>{(errors as any)?.email?.message || "Error!"}</p>
          )}
          {recP.error && <p>{"Такой email не существует в базе данных!"}</p>}
        </div>
        {recP.status && <Navigate to={"/hash_Password"} />}

        <input type="submit" value="Отправить" disabled={!isValid} />
      </form>
    </div>
  );
};
export default Recover;
