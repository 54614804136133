import { FC, useRef } from "react"
import { url } from "../../../../../store/url";
import { Users } from "../../../../../store/loginSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hook";
import { AdminType, addAdmin, deleteAdmin } from "../../../../../store/setkaSlice";

type AdminMapType = {
    adm: AdminType
}

export const Admin: FC<AdminMapType> = ({ adm }) => {
    let Api = url();
    const dispatch = useAppDispatch();

    const imageRef = useRef(null);

    let admin = adm?.admin;
    let id = adm?.id;
    const deleteAdm = () => {
        dispatch(deleteAdmin({ id }))
    }

    const Users = useAppSelector(state => state.login.log);
    let AdminUser: Users = Users.find((item: Users) => item?.id === admin) || {}

    return (
        <div className="sportUser">
            <div>
                <div className="ava">
                    {AdminUser?.ava ? <img className="avaImg" ref={imageRef} src={Api + 'storage/avatar/prev_' + AdminUser?.ava} alt="" /> : <img className="avaImg" ref={imageRef} src={"https://yaponaroll.ru/images/20200701_001.jpg"} alt="" />}
                </div>
            </div>
            <div>
                <div>Логин :{AdminUser?.login}</div>
                <div>{AdminUser?.name}</div>
            </div>
            <div>
                <input type="button" value={'Удалить'} onClick={deleteAdm} />
            </div>
        </div>
    )
}