import { FC } from 'react'
import { subscribeType } from '../../../../../store/subscribeSlice'
import { Users } from '../../../../../store/loginSlice'

type ChildExercisesType = {
    Userter: Users[]
    o: string
}

export const ChildExercises: FC<ChildExercisesType> = ({ Userter, o }) => {
    let f: Users = Userter.find((item: Users) => item?.id === o) || {}
    return (
        <div>
            {f?.name}
        </div>
    )
}