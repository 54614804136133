import { FC } from "react";
import { Users } from "../../../../../store/loginSlice";
import { TableGrade } from "./TableGrade";
import { GradeType } from "../../../../../store/gradeSlice";

type AllGradesType = {
  Grades: GradeType[]; // osi kungi ocenka
  monthDay: any;
  g: any; // id child
  Userter: Users[];
  grade: GradeType[]; // barlik ocenka
  classCh: any;
  subjectCh: any;
};

export const AllGrades: FC<AllGradesType> = ({
  Grades,
  monthDay,
  g,
  Userter,
  grade,
  classCh,
  subjectCh,
}) => {
  let FGrades: GradeType[] = Grades.filter(
    (item: GradeType) => item?.child === g
  );
  let fUsers: Users = Userter.find((item: Users) => item?.id === g) || {};

  let allGMonth = 0;
  for (let i = 0; i < FGrades.length; i++) {
    if (Number(FGrades[i]?.grades) !== 7) {
      allGMonth = allGMonth + Number(FGrades[i]?.grades);
    }
  }

  let AllFGrades: GradeType[] = grade.filter(
    (item: GradeType) =>
      item?.child === g &&
      item?.class_id === classCh &&
      item?.subject_id === subjectCh
  );
  let allGrade = 0;
  for (let i = 0; i < AllFGrades.length; i++) {
    if (Number(AllFGrades[i]?.grades) !== 7) {
      allGrade = allGrade + Number(AllFGrades[i]?.grades);
    }
  }

  let gk: any[] = [];
  for (let i = 0; i < monthDay.length; i++) {
    for (let ig = 0; ig < FGrades.length; ig++) {
      // let date = new Date(Number(FGrades[ig]?.created_at));
      let day = FGrades[ig]?.created_at?.toString().slice(8).slice(0, -9);
      if (day === monthDay[i]) {
        gk = [...gk, FGrades[ig]?.grades];
      } else {
        gk = [...gk, 0];
      }
    }
  }


  return (
    <tr className="trNameS">
      <th className="tbodyName">
        <div className="thNameS">{fUsers?.name}</div>
        <div className="thNameSDublicate">{fUsers?.name}</div>
      </th>
      <th>{allGMonth}</th>
      <th>{allGrade}</th>
      {monthDay.map((ar: GradeType) => (
        <TableGrade ar={ar} FGrades={FGrades} />
      ))}
    </tr>
  );
};
