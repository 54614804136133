import { FC, useEffect, useMemo, useState } from 'react'
import { exerChildADD, exerChildDelete, exerChildUpdate, exercisesChildType, exercisesCoachType, exercisesDelete, exercisesUpdate, exercisesUpdateSquats } from '../../../../../store/exercisesSlice'
import { useAppDispatch, useAppSelector } from '../../../../../store/hook'
import { Users } from '../../../../../store/loginSlice'
import { FaCheck } from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { Static } from './static';
import { MonthLi } from './monthLi';
import { YearLi } from './yearLi';

type ExercisesAllType = {
    ex?: exercisesCoachType
    User?: Users
    pageData?: string
}
export const ExercisesAll: FC<ExercisesAllType> = ({ ex, User, pageData }) => {
    let id = ex?.id
    let exercise_id: string | number | undefined = ex?.id
    let doing = pageData
    let newDate = new Date()
    let date = Number(newDate)
    let day = newDate.getDate()
    let month = newDate.getMonth()
    let year = newDate.getFullYear()

    const dispatch = useAppDispatch();
    const deleteBtn = () => {

        let del = window.confirm(`Точно хотите удалить упражнение "${ex?.name}"?`);
        if (del) {
            setClick(false)
            dispatch(exercisesDelete({ id }))
        }
    }
    const [name, setName] = useState(ex?.name);
    const [save, setSave] = useState(false);
    const [click, setClick] = useState(false);
    const updateBtn = () => {
        setClick(true)
    }
    const btnText = (e: string) => {
        setName(e)
    }
    const cancelBtn = () => {
        setName(ex?.name)
        setClick(false)
    }
    const saveBtn = () => {
        dispatch(exercisesUpdate({ id, name }))
        setClick(false)
        setSave(false)
    }

    useEffect(() => {
        if (ex?.name === name) {
            setSave(false)
        } else {
            setSave(true)
        }
    }, [name]);


    const [max_number, setMax_number] = useState(ex?.max_number);
    const [saveS, setSaveS] = useState(false);
    const btnSquats = (e: any) => {
        setMax_number(e)
    }
    const [clickS, setClickS] = useState(false);
    const updateSquatsBtn = () => {
        setClickS(true)
    }
    const cancelSquatsBtn = () => {
        setMax_number(ex?.max_number)
        setClickS(false)
    }
    const saveSquatsBtn = () => {
        dispatch(exercisesUpdateSquats({ id, max_number }))
        setClickS(false)
        setSaveS(false)
    }

    useEffect(() => {
        if (ex?.max_number === max_number) {
            setSaveS(false)
        } else {
            setSaveS(true)
        }
    }, [max_number]);

    // squats\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    const exercisesChild = useAppSelector(state => state.exercisess.exercisesChild);
    let filterChildExer: exercisesChildType[] = exercisesChild.filter((item: exercisesChildType) => item?.exercise_id === exercise_id && item?.doing === doing)
    let exerDay: exercisesChildType[] = useMemo(() => {
        let h: exercisesChildType[] = []
        for (let index = 0; index < filterChildExer.length; index++) {
            let dateParse = Date.parse(filterChildExer[index]?.created_at);
            let oldDate = new Date(dateParse)
            let oldDay = oldDate.getDate()
            let oldMonth = oldDate.getMonth()
            let oldYear = oldDate.getFullYear()

            if (day === oldDay && month === oldMonth && year === oldYear) {
                h = [...h, filterChildExer[index]]
            }
        }
        if (h.length > 1) {
            let z: number = Math.abs(h.length - 1)
            for (let i = 0; i < h.length; i++) {
                if (i !== z) {
                    let id = h[i]?.id
                    dispatch(exerChildDelete({ id }))
                }
            }
        }
        return h
    }, [exercisesChild]);
    let exerMonth: exercisesChildType[] = useMemo(() => {
        let h: exercisesChildType[] = []
        for (let index = 0; index < filterChildExer.length; index++) {
            let dateParse = Date.parse(filterChildExer[index]?.created_at);
            let oldDate = new Date(dateParse)
            let oldMonth = oldDate.getMonth()
            let oldYear = oldDate.getFullYear()

            if (month === oldMonth && year === oldYear) {
                h = [...h, filterChildExer[index]]
            }
        }
        return h
    }, [exercisesChild]);
    let exerMonthLength: number = useMemo(() => {
        let h: number = 0
        for (let index = 0; index < filterChildExer.length; index++) {
            let dateParse = Date.parse(filterChildExer[index]?.created_at);
            let oldDate = new Date(dateParse)
            let oldMonth = oldDate.getMonth()
            let oldYear = oldDate.getFullYear()

            if (month === oldMonth && year === oldYear) {
                h = h + Number(filterChildExer[index]?.quantity)
            }
        }
        return h
    }, [exercisesChild]);
    let exerYear: exercisesChildType[] = useMemo(() => {
        let h: exercisesChildType[] = []
        for (let index = 0; index < filterChildExer.length; index++) {
            let dateParse = Date.parse(filterChildExer[index]?.created_at);
            let oldDate = new Date(dateParse)
            let oldYear = oldDate.getFullYear()

            if (year === oldYear) {
                h = [...h, filterChildExer[index]]
            }
        }
        return h
    }, [exercisesChild]);
    let exerYearLength: number = useMemo(() => {
        let h: number = 0
        for (let index = 0; index < filterChildExer.length; index++) {
            let dateParse = Date.parse(filterChildExer[index]?.created_at);
            let oldDate = new Date(dateParse)
            let oldYear = oldDate.getFullYear()

            if (year === oldYear) {
                h = h + Number(filterChildExer[index]?.quantity)
            }
        }
        return h
    }, [exercisesChild]);
    const [squat, setSquats] = useState(exerDay[0]?.quantity ? exerDay[0]?.quantity : 0);
    console.log({squat});
    const [pen, setPen] = useState(false);
    const [squatsSave, setSquatsSave] = useState(false);
    if (Number(squat) > Number(ex?.max_number)) {
        setSquats(ex?.max_number ? ex?.max_number : 0)
    }
    if (Number(squat) < 0) {
        setSquats(0)
    }
    // let quantity: any = squat.replace(/^0+(?!\.|$)/, '')
    let quantity: any = squat
    const loadingSave = useAppSelector(state => state.exercisess.loadingSave);

    const penSquats = () => {
        setSquats(exerDay[0]?.quantity ? exerDay[0]?.quantity : 0)
        setSquatsSave(true)
        setPen(true)

    }
    const cancelSquats = () => {
        setSquatsSave(false)
        setPen(false)
    }
    const updateSquats = (e: string) => {
        setSquats(parseInt(e, 10))
    }
    const saveExerSquats = () => {
        setSquatsSave(false)
        setPen(false)
        if (exerDay.length > 0) {
            let id = exerDay[0]?.id
            dispatch(exerChildUpdate({ id, quantity }))
        } else {
            dispatch(exerChildADD({ doing, quantity, exercise_id }))
        }
    }

    let yearArr: number[] = [2023, 2024]
    type monthArrType = {
        id: number
        name: string
    }
    let monthArr: monthArrType[] = [
        { id: 0, name: "Январь" },
        { id: 1, name: "Февраль" },
        { id: 2, name: "Март" },
        { id: 3, name: "Апрель" },
        { id: 4, name: "Май" },
        { id: 5, name: "Июнь" },
        { id: 6, name: "Июль" },
        { id: 7, name: "Август" },
        { id: 8, name: "Сентябрь" },
        { id: 9, name: "Октябрь" },
        { id: 10, name: "Ноябрь" },
        { id: 11, name: "Декабрь" },
    ]

    const [yearSel, setYearSel] = useState(year);
    const [monthSel, setMonthSel] = useState(month);
    const yearF = (n: number) => {
        setYearSel(n)
    }
    const monthF = (n: number) => {
        setMonthSel(n)
    }

    let h: exercisesChildType[] = []
    for (let index = 0; index < filterChildExer.length; index++) {
        let dateParse = Date.parse(filterChildExer[index]?.created_at);
        let oldDate = new Date(dateParse)
        let oldMonth = oldDate.getMonth()
        let oldYear = oldDate.getFullYear()

        if (Number(monthSel) === oldMonth && Number(yearSel) === oldYear) {
            h = [...h, filterChildExer[index]]
        }
    }
    const falseFunc = () => {

    }
    return (
        <div className='ContainerExercises'>
            <div className='ExercisesName' id={ex?.forChild === 0 ? "ExercisesName" : ''}>
                {!click && <div className='ExercisesNameCoach'>{ex?.name}</div>}

                {ex?.max_number && !clickS && <div id='maxSquats'>Макс. количество: {ex?.max_number}</div>}
                {/* {(User?.position === 2 || User?.position === 3) && clickS && <input type="number" value={max_number} onChange={(e) => btnSquats(e.target.value)} />}
                {(User?.position === 2 || User?.position === 3) && <main className='inputsExer'>
                    {(pageData === User?.id) && !clickS && <input id='dExer' type="button" value="Изменить" onClick={updateSquatsBtn} />}
                    {(pageData === User?.id) && clickS && <input id='dExer' type="button" value="Отменить" onClick={cancelSquatsBtn} />}
                    {(pageData === User?.id) && saveS && <input id='saveExer' type="button" value="Сохранить" onClick={saveSquatsBtn} />}
                </main>} */}

                <div className='squats'>
                    <div>Сегодня</div>
                    {!pen && <div onClick={(pageData === User?.id) ? penSquats : falseFunc}>{exerDay[0]?.quantity ? exerDay[0]?.quantity : "0"}</div>}
                    {(pageData === User?.id) && pen && <input value={quantity !== 0 ? quantity.toString() : ""} type="number" max="10" onChange={(e) => updateSquats(e.target.value)} />}
                    {(pageData === User?.id) && !pen && <FaPencilAlt className='checkSquats' onClick={penSquats} />}
                    {(pageData === User?.id) && pen && <GiCancel className='checkSquats' onClick={cancelSquats} />}
                    {(pageData === User?.id) && squatsSave && !loadingSave && <FaCheck className='checkSquats' onClick={saveExerSquats} />}
                    {loadingSave && <div className="loading">Loading&#8230;</div>}
                </div>
                <div className='squats'>
                    <div>За месяц</div>
                    <div>{exerMonthLength}</div>
                </div>
                <div className='squats'>
                    <div>За год</div>
                    <div>{exerYearLength}</div>
                </div>
                {click && <input type="text" value={name} onChange={(e) => btnText(e.target.value)} />}
                <main className='inputsExer'>
                    {(pageData === User?.id) && (ex?.creator === User?.id) && !click && <input id='dExer' type="button" value="Изменить" onClick={updateBtn} />}
                    {(pageData === User?.id) && (ex?.creator === User?.id) && click && <input id='dExer' type="button" value="Отменить" onClick={cancelBtn} />}
                    {(pageData === User?.id) && (ex?.creator === User?.id) && save && <input id='saveExer' type="button" value="Сохранить" onClick={saveBtn} />}
                </main>
                {(pageData === User?.id) && (ex?.creator === User?.id) && <input id='deleteExer' type="button" value="Удалить" onClick={deleteBtn} />}

            </div>
            <main className="staticExer" id={ex?.forChild === 0 ? "ExercisesName" : ""}>
                <div>
                    Статистика
                </div>
                <ul id='ulDate'>
                    {yearArr.map((y) => (
                        <YearLi y={y} yearF={yearF} yearSel={yearSel} />
                    ))}
                </ul>
                <ul id='ulDate'>
                    {monthArr.map((m) => (
                        <MonthLi m={m} monthF={monthF} monthSel={monthSel} />
                    ))}
                </ul>
                <div className='containerMapStatic'>
                    {h.map((eM, i) => (
                        <Static eM={eM} maxSquats={ex?.max_number} i={i} h={h} />
                    ))}
                </div>
            </main>

        </div>
    )
}