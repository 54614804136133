import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Users, getProfile } from "../../../store/loginSlice";
import { useAppDispatch } from "../../../store/hook";
import { ChildrenComponent } from "./components/childrenSearch";
import './style.css'

const Search: FC<any> = ({ log }) => {

    const dispatch = useAppDispatch();

    const filterLogin = (searchText: string, listOfLogin: Users[]) => {
        let d = [...searchText]
        if (!searchText || d.length < 3) {
            return [];
        }
        return listOfLogin.filter(({ login }) =>
            login.toLowerCase().includes(searchText.toLowerCase())
        );
    }

    const [loginList, setLoginList] = useState<Users[]>([]);
    const [text, setText] = useState('');

    useEffect(() => {
        const Debounce = setTimeout(() => {
            const filteredLogin: any = filterLogin(text, log)
            setLoginList(filteredLogin)
        }, 500)

        for (let i = 0; i < loginList.length; i++) {
            let t: Users[] = log.filter((item: Users) => item.login === loginList[i]?.login)
            if (t.length === 0 && loginList.length !== 0) {
                let login: any = loginList[i]?.login
                dispatch(getProfile({ login }))
            }
        }
        return () => clearTimeout(Debounce)
    }, [text]);

    // let searchFilter: Users[] = log.filter((item: Users) => item.login === text.toLowerCase())

    return (
        <div className="flex">
            <div className="Form">
                <input id="registerInput" type="search" placeholder="Поиск по логину" onChange={(e) => setText(e.target.value)} />
                <form className="profile">
                    {loginList.map((prof: any) => (
                        <Link className="linkSport" to={"/" + prof.login}>
                            <ChildrenComponent prof={prof} profmap={log} text={text} />
                        </Link>
                    ))}
                </form>
            </div>
        </div>
    )
}
export default Search