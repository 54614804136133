import { FC } from 'react'

export const MonthLi: FC<any> = ({ m, monthF, monthSel }) => {
    const click = () => {
        monthF(m.id)
    }
    return (
        <li onClick={click} id={monthSel === m?.id ? "liSelSquats" : ""}>
            {m?.name}
        </li>
    )
}