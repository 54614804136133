import { FC, useEffect, useState } from "react";
import { MdGrade } from "react-icons/md";
import { GradeType } from "../../../../../store/gradeSlice";

export const StarState: FC<any> = ({ NewDataGrade, id, functionStarState, classId, subject, user, subsAssocId, grade, subscribed, NewDay, NewYear, NewMonth, }) => {
    let lii = NewDataGrade.length - 1;

    let numG: any = 6
    numG = Number(NewDataGrade[lii]?.grades)
    if (Number(numG) === 6) {
        numG = 6
    }

    const [grades, setGrade] = useState(numG);

    const clickGrade0 = () => setGrade(7)
    const clickGrade1 = () => setGrade(1)
    const clickGrade2 = () => setGrade(2)
    const clickGrade3 = () => setGrade(3)
    const clickGrade4 = () => setGrade(4)
    const clickGrade5 = () => setGrade(5)

    let dateParse = Date.parse(NewDataGrade[lii]?.created_at);
    let date = new Date(dateParse);
    let day = date.getDate();
    let year = date.getFullYear();
    let month = date.getMonth();

    useEffect(() => {
        if ((numG === 7 || numG === 1 || numG === 2 || numG === 3 || numG === 4 || numG === 5) && day === NewDay && month === NewMonth && year === NewYear) {
            setGrade(numG)
        } else {
            setGrade(6)
        }

    }, [subject, classId, numG]);

    useEffect(() => {
        if (numG !== grades && grades !== 6) {
            functionStarState(id, grades)
        }
    }, [grades]);

    return (
        <div className="Star">
            <MdGrade className={grades === 1 && grades < 6 ? "MdGradeBig MdGradeBigBoss" : "MdGradeBig"} fill={grades > 0 && grades < 6 ? "rgb(53, 210, 71)" : ""} onClick={clickGrade1} />
            <MdGrade className={grades === 2 && grades < 6 ? "MdGradeBig MdGradeBigBoss" : "MdGradeBig"} fill={grades > 1 && grades < 6 ? "rgb(53, 210, 71)" : ""} onClick={clickGrade2} />
            <MdGrade className={grades === 3 && grades < 6 ? "MdGradeBig MdGradeBigBoss" : "MdGradeBig"} fill={grades > 2 && grades < 6 ? "rgb(53, 210, 71)" : ""} onClick={clickGrade3} />
            <MdGrade className={grades === 4 && grades < 6 ? "MdGradeBig MdGradeBigBoss" : "MdGradeBig"} fill={grades > 3 && grades < 6 ? "rgb(53, 210, 71)" : ""} onClick={clickGrade4} />
            <MdGrade className={grades === 5 && grades < 6 ? "MdGradeBig MdGradeBigBoss" : "MdGradeBig"} fill={grades > 4 && grades < 6 ? "rgb(53, 210, 71)" : ""} onClick={clickGrade5} />
            <div className={grades === 7 ? "Nred" : ""} id="N" onClick={clickGrade0}>N</div>
        </div>
    )
}