import { FC, useState } from "react"
import { AiTwotoneDelete } from "react-icons/ai";
import { classDelete, classUpdate, subjectDelete, subjectUpdate } from "../../../../../store/gradeSlice";
import { Link } from "react-router-dom";
import { Users } from "../../../../../store/loginSlice";
import { useAppDispatch } from "../../../../../store/hook";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import { url } from "../../../../../store/url";

export const RemoveChildrens: FC<any> = ({ shadowOf, User, myGradeCoach, pageData, coachSel, assocSel, classSel, status, subjectSel, hide, title, id, functionR, R, Userter }) => {
    const Api = url()
    const dispatch = useAppDispatch();
    let f: Users = {}
    if (assocSel) {
        f = Userter.find((item: Users) => item?.id === id) || {}
    }

    if (coachSel) {
        if (myGradeCoach) {
            id = id?.follower_id
        } else {
            id = id?.following_id
        }
        f = Userter.find((item: Users) => item?.id === id) || {}
    }
    const deleeteChild = () => {
        let del = window.confirm(`Точно хотите удалить "${title}"?`);
        if (del) {
            classSel && dispatch(classDelete({ id }))
            subjectSel && dispatch(subjectDelete({ id }))
        }
    }
    const clickR = () => {
        functionR(status, id)
        setTimeout(() => {
            shadowOf()
        }, 300);
    }

    const [update, setUpdate] = useState(false);
    const updateChild = () => {
        setUpdate(true)
    }
    const cancelChild = () => {
        setUpdate(false)
        setUpdateVal(title)
    }
    const [updateVal, setUpdateVal] = useState(title);
    const updateChildVal = (e: any) => {
        setUpdateVal(e)
    }
    const saveChild = () => {
        title = updateVal
        classSel && dispatch(classUpdate({ id, title }))
        subjectSel && dispatch(subjectUpdate({ id, title }))
        setUpdate(false)
    }


    return (
        <div className="removeChildrens" id={id === R ? "ChBlue" : ""} >
            {
                update ? <input type="text" value={updateVal} onChange={(e) => updateChildVal(e.target.value)} /> : <div className="selectCoachAndAss">
                              { (assocSel || coachSel) && <main className="ava avaCh" id='avaCheck30'>
                                    {f?.ava ? <img className="avaImg"  src={Api + 'storage/avatar/prev_' + f?.ava} alt="" /> : <img className="avaImg" src={"https://yaponaroll.ru/images/20200701_001.jpg"} alt="" />}
                                </main>}
                                <h3 onClick={clickR}>{(assocSel || coachSel) ? f?.login : title}</h3>
                            </div>
            }
            {
                ((User?.position === 3 && pageData === User?.id && (classSel || subjectSel)) && <div className="iconGradContainer">
                        {update && <IoMdCheckmark className="TwotoneDelete Checkmark" onClick={saveChild} />}
                        {update ? <MdOutlineCancel className="TwotoneDelete cancelRed" onClick={cancelChild} /> : <FaPencilAlt className="TwotoneDelete penGrad" onClick={updateChild} />}
                        <AiTwotoneDelete className="TwotoneDelete" onClick={deleeteChild} />
                    </div>)
            }
        </div>
    )
}