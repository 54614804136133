import { FC } from 'react'
import { PaymentDelete, paymentType } from '../../../../../store/payment'
import { AiTwotoneDelete } from 'react-icons/ai'
import { url } from '../../../../../store/url'

type CheckType = {
    check: paymentType
    name: string
    dispatch: any
    ava: string
}

export const Check: FC<CheckType> = ({ check, name, dispatch, ava }) => {
    const Api = url()
    let hourUTC = Date.parse(check?.created_at) / 3600000;

    let newDate = new Date()
    let newDateH = Number(newDate)/ 3600000;
    // let hours = date.getHours()
    // let min = date.getMinutes()
    // let day = date.getDate()
    // let month = date.getMonth()
    // let year = date.getFullYear()
    let timeOut = hourUTC + 7
    // let minF = "" + min
    // let hoursF = '' + hours
    // if (min < 10) {
    //     minF = "0" + min
    // }
    // if (hours < 10) {
    //     hoursF = "0" + hours
    // }

    const deleteCheck = () => {

        let del = window.confirm(`Точно хотите удалить сумму "${check?.amount}тг"?`);
        if (del) {
            let id = check?.id
            dispatch(PaymentDelete({ id }))
        }
    }
    return (
        <main className='checkAllChild'>
            <main className='containerData'>
                <main className="ava avaCh" id='avaCheck'>
                    {ava ? <img className="avaImg"  src={Api + 'storage/avatar/prev_' + ava} alt="" /> : <img className="avaImg" src={"https://yaponaroll.ru/images/20200701_001.jpg"} alt="" />}
                </main>
                <main className='dataName'>{name}</main>
            </main>
            
            <main id='checkPay'>
                <main>Пополнения:</main>
                {<main id={check?.amount > 0 ? 'checkPayment' : 'checkPaymentRed'}>{check?.amount} тг</main>}
            </main>
            {check?.title && <main id='checkComment'>
                {check?.title}
            </main>}
            <main className='timeCheck' >
                {check?.created_at?.toString().slice(11).slice(0, -3)}
            </main>
            <main className='dateCheck'>
                {check?.created_at?.toString().slice(0, -9)}
            </main>
            {timeOut > newDateH &&  <main className='deleteCheck' onClick={deleteCheck} >
                Удалить
            </main>}
        </main >
    )
}