import { FC } from "react"
import { Users } from "../../../../../store/loginSlice"
import { subscribeType } from "../../../../../store/subscribeSlice"
import { Link } from "react-router-dom"
import { url } from "../../../../../store/url"
import { paymentType } from "../../../../../store/payment"

type schType = {
    Userter: Users[]
    paym: paymentType
}

export const Payment: FC<schType> = ({ Userter, paym }) => {
    let subsU = Userter.find((item: Users) => item.id === paym.client_id)
    const Api = url()
    return (
        <div className='paymentDataContainer'>
            <div className="paymentData">
                <main className="ava" id='avaCheck2'>
                    {subsU?.ava ? <img className="avaImg" src={Api + 'storage/avatar/prev_' + subsU?.ava} alt="" /> : <img className="avaImg" src={"https://yaponaroll.ru/images/20200701_001.jpg"} alt="" />}
                </main>
                <div className='childText' >
                    <div>
                        {subsU?.firstName} {subsU?.lastName}
                    </div>
                    <Link className='loginLink' to={"/" + subsU?.login} >
                        {subsU?.login}
                    </Link>
                    
                </div>
            </div>
            <div className="posithinUserPayment">
                {subsU?.position === 2 && <main className="positionMini">Coach</main>}
                {subsU?.position === 3 && <main className="positionMini">Association</main>}
            </div>
            
            <div className="ChekContainer">
                <main id='checkPay'>
                    <main className="checkT">Пополнения:</main>
                    {<main id={paym?.amount > 0 ? 'checkPayment' : 'checkPaymentRed'}>{paym?.amount} тг</main>}
                </main>    
                {paym.title && <main id='checkComment'>
                    {paym.title}
                </main>}
                <main className='timeCheck' >
                    {paym.created_at?.toString().slice(11).slice(0, -3)}
                </main>
                <main className='dateCheck'>
                    {paym.created_at?.toString().slice(0, -9)}
                </main>
                
            </div>
        </div>
    )
}