import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  api,
  instanceGET,
  instanceJsonPOST,
  instancePOST,
} from "./axiosInstans";
import { string } from "yup";
import axios from "axios";
import { url } from "./url";
import { stat } from "fs/promises";
let Apiq = url();

export type Users = {
  id?: any;
  idChild?: any;
  firstName?: any;
  lastName?: any;
  name?: any;
  patronymic?: any;
  login?: any;
  tel?: any;
  email?: any;
  coach?: any;
  thisCoach?: any;
  sportsName?: any;
  city?: any;
  birthday?: any;
  kg?: any;
  sc?: any;
  club?: any;
  ava?: any;
  newSC?: any;
  admin?: any;
  position?: number;
  association?: any;
  association_names?: any;
  country?: any;
  region?: any;
  type_association?: any;
  status?: any;
  statusFalse?: any;
  TA?: any;
  TR?: any;
  password?: any;
  hash_pass?: any;
  rttp?: any;
  attt?: any;
  message?: any;
  out?: any;
  error?: any;
  fields?: any;
  year?: string;
};
export type resetPassType = {
  token?: any;
  email?: any;
  password?: any;
  password_confirmation?: any;
  message?: string;
};
export type regType = {
  firstName?: any;
  lastName?: any;
  patronymic?: any;
  login?: any;
  tel?: any;
  email?: any;
  password?: any;
};
export type regStatusType = {
  status?: any;
  type?: any;
  message?: any;
  error?: any;
  fields?: any;
  post_id?: any;
  name?: string;
  login?: string;
  isAssociations?: any;
  checked?: any;
  id?: any;
};
export type coaches = {
  status?: any;
  firstName?: any;
  lastName?: any;
  patronymic?: any;
};
export type region = {
  status: any;
  id: any;
  name: any;
  country: any;
};
export type country = {
  status: any;
  id?: number;
  name: any;
};
export type city = {
  status: any;
  id: any;
  name: any;
  region: any;
};
export type sport = {
  id: any;
  sports: any;
};
export type AssociationType = {
  id?: number;
  name?: string;
  type?: number;
};
export const posts = createAsyncThunk<any, any, { rejectValue: string }>(
  "login/posts",
  async function ({ text, like }, { rejectWithValue }) {
    let Data = {
      text,
      like,
    };

    try {
      const response: any = await axios.post(Apiq + "likes", Data);
      console.log(response.data.data);
      return await response.data.data;
    } catch (error: any) {
      return rejectWithValue("");
    }
  }
);
export const allLogin = createAsyncThunk<
  any,
  undefined,
  { rejectValue: string }
>("login/allLogin", async function (_, { rejectWithValue }) {
  try {
    const response: any = await axios.post(Apiq + "api/auth/alluser");

    return (await response.data.data) as Users[];
  } catch (error: any) {
    // if(error.response.data.message === "Unauthenticated."){
    //     console.log(error);
    //     let User = {
    //         status: false
    //     }
    //     return User as Users
    // }
    // if (error.response.data.message === 'Token has expired') {
    //     console.log(error.response.data.message);
    //     return axios.post(Apiq + 'api/auth/refresh', {}, {
    //         headers: {
    //             'Authorization': `Bearer ${localStorage.getItem('attt')}`
    //         }
    //     }).then(res => {
    //         console.log({res});
    //         localStorage.setItem('attt', res.data.access_token);
    //         res.config.headers.Authorization = `Bearer ${localStorage.getItem('attt')}`;

    //         return instancePOST.request(res.config);
    //     })
    // }
    return rejectWithValue("");
  }
});
export const out = createAsyncThunk<any>(
  "login/out",
  async function (_, { rejectWithValue }) {
    try {
      const response: any = await api.post(Apiq + "api/auth/logout");

      const f: any = { out: true };
      return f as any;
    } catch (error: any) {
      return rejectWithValue("");
    }
  }
);
export const meSlice = createAsyncThunk<
  Users,
  undefined,
  { rejectValue: string }
>("login/meSlice", async function (_, { rejectWithValue }) {
  try {
    const response: any = await api.post(Apiq + "api/auth/me");

    return (await response.data) as Users;
  } catch (error: any) {
    if (
      error.response.data.message === "The token has been blacklisted" ||
      error.response.data.message === "Unauthenticated." ||
      error.response.data.message ===
        "Token could not be parsed from the request." ||
      error.response.data.message ===
        "Token Signature could not be verified." ||
      error.response.data.message ===
        "Could not decode token: Error while decoding from Base64Url, invalid base64 characters detected"
    ) {
      let User = {
        status: false,
      };
      return User as Users;
    }
    // if(error.response.data.message === "Unauthenticated."){
    //     console.log(error);
    //     let User = {
    //         status: false
    //     }
    //     return User as Users
    // }
    // if (error.response.data.message === 'Token has expired') {
    //     console.log(error.response.data.message);
    //     return axios.post(Apiq + 'api/auth/refresh', {}, {
    //         headers: {
    //             'Authorization': `Bearer ${localStorage.getItem('attt')}`
    //         }
    //     }).then(res => {
    //         localStorage.setItem('attt', res.data.access_token);
    //         res.config.headers.Authorization = `Bearer ${localStorage.getItem('attt')}`;
    //         console.log(res);
    //         return instancePOST.request(res.config);
    //     })
    // }
    return rejectWithValue(error.response.data.message);
  }
});
export const fetchSetka = createAsyncThunk<
  Users[],
  undefined,
  { rejectValue: string }
>("login/fetchSetka", async function (_, { rejectWithValue }) {
  try {
    const response: any = await instanceGET("reg/userAll/post");
    const data = await response.data;
    return data;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const getProfile = createAsyncThunk<
  Users,
  Users,
  { rejectValue: string }
>("login/getProfile", async function ({ id }, { rejectWithValue }) {
  try {
    let jData: any = {
      id,
    };

    const response: any = await api.post(Apiq + "api/auth/PageData", jData);
    return (await response.data.data) as Users;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const getProfileId = createAsyncThunk<
  regStatusType,
  Users,
  { rejectValue: string }
>("login/getProfileId", async function ({ idChild }, { rejectWithValue }) {
  try {
    let jData: any = {
      id: idChild,
    };

    const u = "reg/userAll/post";
    const response: any = await instancePOST.post(u, jData);
    return (await response.data) as Users;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const addNewChild = createAsyncThunk<
  regStatusType,
  Users,
  { rejectValue: string }
>(
  "login/addNewChild",
  async function (
    { name, login, email, password, birthday },
    { rejectWithValue }
  ) {
    try {
      let jData: any = {
        name,
        login,
        email,
        birthday,
        password,
      };

      const response: any = await instancePOST.post("signup", jData);
      // dispatch(addError(response.data));
      return (await response.data) as Users;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const signInSlice = createAsyncThunk<
  Users,
  Users,
  { rejectValue: string }
>(
  "login/signInSlice",
  async function ({ login, password }, { rejectWithValue }) {
    try {
      let jData: any = {
        login,
        password,
      };

      const u = "api/auth/login";
      const response: any = await instanceJsonPOST.post(
        u,
        JSON.stringify(jData)
      );
      localStorage.setItem("attt", response.data.access_token);
      const response2: any = await api.post(Apiq + "api/auth/me");

      return (await response2.data) as Users;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const axiosUser = createAsyncThunk<
  Users,
  undefined,
  { rejectValue: string }
>("login/axiosUser", async function (_, { rejectWithValue }) {
  try {
    // const    getUserTok = {
    const rttp: any = JSON.parse(sessionStorage.getItem("rttp") || "{}"),
      attt: any = JSON.parse(localStorage.getItem("attt") || "{}");
    // const acc = JSON.stringify(getUserTok);
    let jData: any = {
      rttp,
      attt,
    };

    const u = "reg/getAccount/get";
    const response: any = await instancePOST.post(u, JSON.stringify(jData));
    // dispatch(userValue(response.data));
    return (await response.data) as Users;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const updateToken = createAsyncThunk<unknown>(
  "login/updateToken",
  async function (_, { rejectWithValue }) {
    try {
      // const    getUserTok = {
      const rttp: any = sessionStorage.getItem("rttp"),
        attt: any = localStorage.getItem("attt");

      // const acc = JSON.stringify(getUserTok);
      const formData = new FormData();
      formData.append("rttp", rttp);
      formData.append("attt", attt);

      const u = "reg/getAccount/get";
      const response: any = await instancePOST.post(u, formData);

      const Anukc = response.data.TA;
      const Rnukc = response.data.TR;

      if (Rnukc) {
        sessionStorage.setItem("rttp", Rnukc);
        localStorage.setItem("attt", Anukc);
      }
    } catch (error) {
      return rejectWithValue("");
    }
  }
);
export const removeUser = createAsyncThunk<
  Users,
  Users,
  { rejectValue: string }
>(
  "login/removeUser",
  async function ({ name, login, tel, email, birthday }, { rejectWithValue }) {
    try {
      let jData: any = {
        name,
        login,
        tel,
        email,
        birthday,
      };

      const response: any = await api.post(Apiq + "api/auth/remove", jData);
      return (await response.data.data) as Users;
    } catch (error) {
      return rejectWithValue("");
    }
  }
);
export const settingUser = createAsyncThunk<
  Users,
  Users,
  { rejectValue: string }
>(
  "login/settingUser",
  async function (
    { position, association, country, region, city },
    { rejectWithValue }
  ) {
    try {
      let jData: any = {
        position,
        association,
        country,
        region,
        city,
      };

      const response: any = await api.post(
        Apiq + "api/auth/settingUser",
        jData
      );
      return (await response.data.data) as Users;
    } catch (error) {
      return rejectWithValue("");
    }
  }
);
export const statusFalse = createAsyncThunk<Users>(
  "login/statusFalse",
  async function (_, {}) {
    // dispatch(falseStatus())
    const f: any = false;
    return f as any;
  }
);
export const resetPass = createAsyncThunk<
  resetPassType,
  resetPassType,
  { rejectValue: string }
>(
  "login/resetPass",
  async function (
    { token, email, password, password_confirmation },
    { rejectWithValue }
  ) {
    try {
      let jData: any = {
        token,
        email,
        password,
        password_confirmation,
      };

      const response: any = await axios.post(
        Apiq + "api/auth/reset-password",
        jData
      );
      return (await response.data) as Users;
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.error ||
          error.response.data.message ||
          "Ошибка при сбросе пароля"
      );
    }
  }
);
export const recoverPass = createAsyncThunk<
  Users,
  Users,
  { rejectValue: string }
>(
  "login/recoverPass",
  async function ({ email }, { rejectWithValue /*dispatch*/ }) {
    try {
      let jData: any = {
        email,
      };

      const response: any = await axios.post(
        Apiq + "api/auth/forgot-password",
        jData
      );
      // dispatch(recoveryPassword(response.data));
      return (await response.data) as Users;
    } catch (error: any) {
      console.log({ error });
      return rejectWithValue(
        error.response.data.error || "Ошибка при сбросе пароля"
      );
    }
  }
);
export const hashPassword = createAsyncThunk<
  Users,
  Users,
  { rejectValue: string }
>("login/hashPassword", async function ({ hash_pass }, { rejectWithValue }) {
  try {
    let jData: any = {
      hash_pass,
    };

    const u = "reg/hashpass/hashpass";
    const response: any = await instancePOST.post(u, jData);
    // dispatch(hashP(response.data));
    const Anukc = response.data.TA;
    localStorage.setItem("attt", Anukc);
    const Rnukc = response.data.TR;
    sessionStorage.setItem("rttp", Rnukc);
    return (await response.data) as Users;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const nullEmailRecover = createAsyncThunk<any>(
  "login/nullEmailRecover",
  async function (_, {}) {
    // dispatch(recoveryPassword({ status: false }))
    const f: any = false;
    return f as any;
  }
);
export const Coaches = createAsyncThunk<
  coaches[],
  undefined,
  { rejectValue: string }
>("login/Coaches", async function (_, { rejectWithValue }) {
  try {
    const u = "reg/coaches/coaches";
    const response: any = await instanceGET(u);
    // dispatch(CoachesValue(response.data));
    return (await response.json()) as coaches[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const CountrySlise = createAsyncThunk<
  country[],
  undefined,
  { rejectValue: string }
>("login/CountrySlise", async function (_, { rejectWithValue }) {
  try {
    const response: any = await api.post(Apiq + "api/auth/GetCountry");
    // dispatch(countryValue(response.data));
    return (await response.data.data) as country[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const CitySlise = createAsyncThunk<
  city[],
  undefined,
  { rejectValue: string }
>("login/CitySlise", async function (_, { rejectWithValue }) {
  try {
    const response: any = await api.post(Apiq + "api/auth/GetCity");
    // dispatch(cityValue(response.data));
    return (await response.data.data) as city[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const RegionSlise = createAsyncThunk<
  region[],
  undefined,
  { rejectValue: string }
>("login/RegionSlise", async function (_, { rejectWithValue }) {
  try {
    const response: any = await api.post(Apiq + "api/auth/GetRegion");
    // dispatch(regionValue(response.data));
    return (await response.data.data) as region[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const updatePassword = createAsyncThunk<
  Users,
  Users,
  { rejectValue: string }
>("login/updatePassword", async function ({ password }, { rejectWithValue }) {
  try {
    let jData: any = {
      password,
    };

    const response: any = await api.post(Apiq + "api/auth/remove", jData);
    return (await response.data) as Users;
    // dispatch(recoveryPassword(response.data));
    return (await response.data) as Users;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const AddAva = createAsyncThunk<Users, Users, { rejectValue: string }>(
  "login/AddAva",
  async function ({ ava }, { rejectWithValue }) {
    try {
      const formData = new FormData();
      formData.append("avatar", ava);

      const response = await instancePOST.post("api/auth/avatar", formData);
      return (await response.data.data) as Users;
    } catch (error: any) {
      if (
        error.response.data.message === "Token has expired" ||
        error.response.data.message === "Unauthenticated." ||
        error.response.data.message === "Wrong number of segments"
      ) {
        return axios
          .post(
            Apiq + "api/auth/refresh",
            {},
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem("attt")}`,
              },
            }
          )
          .then(async (res) => {
            localStorage.setItem("attt", res.data.access_token);
            error.config.headers.Authorization = `Bearer ${localStorage.getItem(
              "attt"
            )}`;

            const res_1 = await instancePOST.request(error.config);
            return res_1.data.data as Users;
          });
      }
      return rejectWithValue("");
    }
  }
);
export const getAssociation = createAsyncThunk<
  AssociationType[],
  undefined,
  { rejectValue: string }
>("login/getAssociation", async function (_, { rejectWithValue }) {
  try {
    const response: any = await api.post(Apiq + "api/auth/GetAssociation");
    // dispatch(SportsName(response.data));
    return (await response.data.data) as AssociationType[];
  } catch (error) {
    return rejectWithValue("");
  }
});

export const registerOpenSlise = createAsyncThunk<any>(
  "login/registerOpenSlise",
  async function (_, {}) {
    // dispatch(ErrorReg())
    const f: any = false;
    return f as any;
  }
);
export const errorSlice = createAsyncThunk<
  any,
  undefined,
  { rejectValue: string }
>("login/errorSlice", async function (_, { rejectWithValue }) {
  return rejectWithValue("");
});

export const AddCountry = createAsyncThunk<
  Users,
  Users,
  { rejectValue: string }
>(
  "login/AddCountry",
  async function ({ id, country, region, city }, { rejectWithValue }) {
    let Data: any = {
      id,
      country,
      region,
      city,
    };
    return (await Data) as Users;
  }
);
export const AddChampCoach = createAsyncThunk<
  Users,
  Users,
  { rejectValue: string }
>("login/AddChampCoach", async function ({ id, coach }, { rejectWithValue }) {
  let Data: any = {
    id,
    coach,
  };
  return (await Data) as Users;
});

// const setError = (state, action) => {
//     state.status = 'rejected';
//     state.error = action.payload;
// }

export type stateType = {
  log: Users[];
  UsersStatus: boolean | null;
  sport: sport[];
  status: any;
  error: any;
  Token: boolean | null;
  user: Users;
  coach: coaches[];
  city: city[];
  region: region[];
  country: country[];
  mess: [];
  resetPassMessage: string;
  forgotPassMessage: string;
  resError: regStatusType;
  recPass: any;
  hashPasss: any;
  avaTrue: any;
  club: [];
  loading: string | boolean;
  loading_Login: boolean;
  Association: AssociationType[];
};
const initialState: stateType = {
  log: [
    {
      id: 0,
      login: "",
    },
  ],
  UsersStatus: null,
  sport: [],
  status: null,
  error: null,
  Token: null,
  user: {
    status: false,
  },
  Association: [],
  coach: [],
  city: [],
  region: [],
  country: [],
  mess: [],
  resError: {
    message: null,
    fields: null,
    status: false,
  },
  resetPassMessage: "",
  forgotPassMessage: "",
  recPass: {
    status: false,
  },
  hashPasss: {},
  avaTrue: {
    status: false,
  },
  club: [],
  loading_Login: false,
  loading: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    // addLogin(state, action) {
    //     state.login = action.payload;
    //     // state.login.push({
    //     //     id: new Date().toISOString(),
    //     //     log: action.payload.log,
    //     //     pass: action.payload.pass
    //     // });
    // },
    // addError(state, action) {
    //     state.resError = action.payload;
    // },
    // userValue(state, action) {
    //     state.user = action.payload;
    // },
    // userErrorNumRows(state, action) {
    //     state.user.status = action.payload.status;
    //     state.user.type = action.payload.type;
    //     state.user.message = action.payload.message;
    //     state.user.error = action.payload.error;
    //     state.user.fields = action.payload.fields;
    // },
    // recoveryPassword(state, action) {
    //     state.recPass = action.payload;
    // },
    // hashP(state, action) {
    //     state.hashPasss = action.payload;
    // },
    // CoachesValue(state, action) {
    //     state.coach = action.payload;
    // },
    // cityValue(state, action) {
    //     state.city = action.payload;
    // },
    // countryValue(state, action) {
    //     state.country = action.payload;
    // },
    // regionValue(state, action) {
    //     state.region = action.payload;
    // },
    // AvaAdd(state, action) {
    //     const avaID = state.login.find(item => item.id === action.payload.id);
    //     avaID.ava = action.payload.ava;
    // },
    // oNewAva(state, action) {
    //     state.avaTrue.status = action.payload;
    // },
    // stateMess(state, action) {
    //     state.mess.push(action.payload);
    // },
    // SportClub(state, action) {
    //     state.club = action.payload;
    // },
    // SportsName(state, action) {
    //     state.sport = action.payload;
    // },
    // falseStatus(state) {
    //     state.user.status = false;
    // },
    // OutLogin(state) {
    //     state.user = { out: true }
    // },
    // ErrorReg(state) {
    //     state.resError.status = false;
    // },
    // AssociationTrue(state, action) {
    //     const userID = state.login.find(item => item.id === action.payload.id);
    //     userID.isAssociations = action.payload.isAssociations;
    // },
    // UpdateAssociation(state, action) {
    //     const uID = state.login.find(item => item.id === action.payload.id);
    //     uID.country = action.payload.country;
    //     uID.sportsName = action.payload.sportsName;
    //     uID.association_names = action.payload.association_names;
    //     uID.city = action.payload.city;
    //     state.user = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInSlice.fulfilled, (state, action) => {
        state.loading = false;
        state.loading = false;

        if (action.payload.status === false) {
          state.Token = action.payload.status;
        } else {
          state.Token = true;
          state.user = action.payload;
          state.user.status = true;
        }
      })
      .addCase(meSlice.pending, (state, error) => {
        state.loading = true;
      })
      .addCase(meSlice.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === false) {
          state.Token = action.payload.status;
        } else {
          state.user = action.payload;
          state.user.status = true;
          state.Token = true;
        }
      })
      .addCase(axiosUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(axiosUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(resetPass.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPass.fulfilled, (state, action) => {
        state.resetPassMessage = action.payload?.message || "";
        state.loading = false;
      })
      .addCase(removeUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        state.user.name = action.payload.name;
        state.user.login = action.payload.login;
        state.user.tel = action.payload.tel;
        state.user.email = action.payload.email;
        state.user.birthday = action.payload.birthday;

        let g: Users =
          state.log.find((t: Users) => t.id === action.payload.id) || {};
        g.name = action.payload.name;
        g.login = action.payload!.login;
        state.loading = false;
      })
      .addCase(settingUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(settingUser.fulfilled, (state, action) => {
        state.user.position = action.payload.position;

        let g: Users =
          state.log.find((t: Users) => t.id === action.payload.id) || {};
        g.position = action.payload!.position;
        g.country = action.payload!.country;
        g.region = action.payload!.region;
        g.city = action.payload!.city;
        console.log("asd", action.payload!.position);
        state.loading = false;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        let log: Users[] = state.log;
        let f: Users =
          log.find((item: Users) => item?.id === action.payload?.id) || {};
        f.ava = action.payload?.ava;
        state.loading = false;
      })
      .addCase(getProfileId.pending, (state) => {
        state.loading_Login = true;
        state.error = null;
      })
      .addCase(allLogin.fulfilled, (state, action) => {
        if (action.payload) {
          state.log = action.payload;
          state.UsersStatus = true;
        }
      })
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProfileId.fulfilled, (state, action) => {
        state.loading_Login = false;
        let d: Users[] = state.log;
        let g: Users = action.payload;
        let fD: Users[] = d.filter((item: Users) => item?.id === g?.id);
        if (fD.length === 0) {
          d = [...d, g];
        }
        state.log = d;
        state.loading_Login = false;
      })
      .addCase(getAssociation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssociation.fulfilled, (state, action) => {
        state.Association = action.payload;
        state.loading = false;
      })
      .addCase(addNewChild.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addNewChild.fulfilled, (state, action) => {
        console.log({ ppp: action.payload });
        state.resError = action.payload;
        state.log.push({
          id: action.payload?.id,
          login: action.payload?.login,
        });
        state.loading = false;
      })
      .addCase(signInSlice.pending, (state, error) => {
        state.loading = true;
      })
      .addCase(statusFalse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(statusFalse.fulfilled, (state, action) => {
        state.user!.status = action.payload;
        state.loading = false;
      })
      .addCase(Coaches.fulfilled, (state, action) => {
        state.coach = action.payload;
      })
      .addCase(CountrySlise.fulfilled, (state, action) => {
        state.country = action.payload;
      })
      .addCase(RegionSlise.fulfilled, (state, action) => {
        state.region = action.payload;
      })
      .addCase(CitySlise.fulfilled, (state, action) => {
        state.city = action.payload;
      })
      .addCase(recoverPass.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(recoverPass.fulfilled, (state, action) => {
        state.forgotPassMessage = action.payload?.message || "";
        state.loading = false;
      })
      .addCase(hashPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(hashPassword.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(nullEmailRecover.fulfilled, (state, action) => {
        state.recPass!.status = action.payload;
      })
      .addCase(updatePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.recPass = action.payload;
        state.loading = false;
      })
      .addCase(AddAva.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddAva.fulfilled, (state, action) => {
        console.log(action.payload);
        const avaID = state.log!.find((item) => item.id === action.payload.id);
        avaID!.ava = action.payload.ava;
        state.loading = false;
        console.log(action.payload);
      })
      .addCase(out.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(out.fulfilled, (state, action) => {
        if (action.payload?.out === true) {
          state.user = {
            status: false,
          };
          state.UsersStatus = true;
          state.Token = false;
        }
        state.loading = false;
      })
      .addCase(registerOpenSlise.fulfilled, (state, action) => {
        state.resError!.status = action.payload;
      })
      .addCase(AddCountry.fulfilled, (state, action) => {
        let f: Users =
          state.log.find((item: Users) => item?.id === action.payload.id) || {};
        f.country = action.payload.country;
        f.region = action.payload.region;
        f.city = action.payload.city;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});
//     const uID = state.login.find(item => item.id === action.payload.id);
//     uID.country = action.payload.country;
//     uID.sportsName = action.payload.sportsName;
//     uID.association_names = action.payload.association_names;
//     uID.city = action.payload.city;
//     state.user = action.payload;

// const { addLogin, addError, ErrorReg, userErrorNumRows, userValue, recoveryPassword, hashP, CoachesValue, AvaAdd, stateMess, SportClub, SportsName, falseStatus, OutLogin, oNewAva, AssociationTrue, UpdateAssociation, cityValue, countryValue, regionValue } = loginSlice.actions;

export default loginSlice.reducer;
const isError = (action: AnyAction) => {
  return action.type.endsWith("rejected");
};
