import React, { FC } from 'react';
import '../style/home.css'
import { Link, Navigate } from 'react-router-dom';
// import { Player } from 'video-react';

const Home: FC<any> = ({ login }) => {
  if (!login) {
    return <Navigate to={"/signin"} />
  }
  return (
    <div className='home'>
      <h1>Hello</h1>
    </div>

  );
};

export default Home;