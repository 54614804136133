import Password from "./password";
import { Users, updatePassword } from "../../../store/loginSlice";
import { FC } from "react";
import { useAppDispatch } from "../../../store/hook";


const PasswordContainer: FC<any> = () => {
  const dispatch = useAppDispatch();

  const updatePass = (data: Users) => {
    let password: any = data.password;
    dispatch(updatePassword({ password }));
  }

  return (
    <div>
      <Password updatePass={updatePass} />
    </div>
  );
};

export default PasswordContainer;