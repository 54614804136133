import { useSelector } from "react-redux";
import { FC } from 'react'
import './style.css'
import { IoSend } from "react-icons/io5";
import { url } from "../../../store/url";
import { Users } from "../../../store/loginSlice";

export const Massenger: FC<any> = ({ id, getMassenger, uPage, mess, mass2, otpravit, updateMass, valueMass, pageId, profmap }) => {
    let Api = url();
    let UserPage: Users = profmap.find((item: Users) => item?.id === id)
    let ava = UserPage?.ava
    return (
        <div id='massenger'>
            <div id='pageDateMessanger'>
                <div id="avaMessage">
                    {ava ? <img className="avaImg" src={Api + 'reg/setAva/avatars/' + ava} alt="" /> : <img className="avaImg" src={"https://yaponaroll.ru/images/20200701_001.jpg"} alt="" />}
                </div>
                <div>{UserPage?.firstName} {UserPage?.lastName}</div>
            </div>
            {/* {m.map((ms) => (
                <div>
                    {ms.mess} {ms.uPage}
                </div>
            ))} */}

            <form id='formMassenger'>
                <input
                    id='inputMassege'
                    placeholder='  Напишите'
                    value={valueMass}
                    onChange={(e) => updateMass(e.target.value)}
                />
                <button type='button' id='btnMessage' onClick={otpravit}><IoSend id="iconSend" /></button>
            </form>
        </div>
    )
}