import { createSlice, createAsyncThunk, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { instanceGET, instancePOST } from './axiosInstans';

export type subsCoachType = {
    id: any
    pageData: any
    subscribed: any
    date: any
    approved: any
}

// export const setkaADD = createAsyncThunk<any, any, { rejectValue: string }>(
//     'setka/setkaADD',
//     async function ({ KG, idChild, indexS, createID, idChamp, indexArr }, { rejectWithValue }) {
//         try {
//             const jsonS = {
//                 KG,
//                 idChild,
//                 indexS,
//                 createID,
//                 idChamp,
//                 indexArr,
//             }
//             const u = 'setka/addSetkaDB/setkaAdd';
//             const response: any = await instancePOST.post(u, jsonS);
//             // dispatch(addsetka(response.data));
//             return await response.data as any
//         } catch (error) {
//             return rejectWithValue('Can\'t add task. Server error.');
//         }
//     }
// );
// export const setkaDelete = createAsyncThunk<any, any, { rejectValue: string }>(
//     'setka/setkaDelete',
//     async function ({ KG, idChamp }, { rejectWithValue }) {
//         try {
//             const jsonS = {
//                 KG,
//                 idChamp,
//             }
//             const u = 'setka/deleteSetka/deleteSetka';
//             const response: any = await instancePOST.post(u, jsonS);
//             // dispatch(addsetka(response.data));
//             return await response.data as any
//         } catch (error) {
//             return rejectWithValue('Can\'t add task. Server error.');
//         }
//     }
// );
export const getChildren = createAsyncThunk<subsCoachType[], any, { rejectValue: string }>(
    'children/getChildren',
    async function ({ id }, { rejectWithValue }) {
        try {
            let jData: any = {
                id
            }

            const u = 'children/children/post';
            const response: any = await instancePOST.post(u, jData)
            return (await response.data) as subsCoachType[]
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const getSubsChild = createAsyncThunk<any, any, { rejectValue: string }>(
    'children/getSubsChild',
    async function ({ id }, { rejectWithValue }) {
        try {
            return (await id) as any
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);

// const setError = (state, action) => {
//     state.status = 'rejected';
//     state.error = action.payload;
// }
type ChildStateType = {
    subscrabeChild: any[]
    getSubsChildState: any[]
    loading: any
    error: any
}

const initialState: ChildStateType = {
    subscrabeChild: [],
    getSubsChildState: [],
    loading: false,
    error: null,
}
const childrenSlice = createSlice({
    name: 'children',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getChildren.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getChildren.fulfilled, (state, action) => {
                state.subscrabeChild = action.payload;
            })
            .addCase(getSubsChild.fulfilled, (state, action) => {
                state.getSubsChildState.push(action.payload);
            })
    },
});
export default childrenSlice.reducer;
const isError = (action: AnyAction) => {
    return action.type.endsWith('rejectad');
}