import { FC, useEffect, useState } from "react";
import "../style.css";
import { Link } from "react-router-dom";
import { ChildrenComponent } from "../../../search/components/childrenSearch";
import { Users, getProfile } from "../../../../../store/loginSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hook";
import { SearchAdmin } from "./searchAdmin";
import { AdminType } from "../../../../../store/setkaSlice";
import { Admin } from "./Admin";

type AdminChampType = {
  userID: number;
  log: Users[];
};

export const AdminChamp: FC<AdminChampType> = ({ log }) => {
  const admins = useAppSelector((state) => state.setka.admins);

  const filterLogin = (searchText: string, listOfLogin: Users[]) => {
    let d = [...searchText];
    if (!searchText || d.length < 3) {
      return [];
    }
    return listOfLogin.filter(({ login }) =>
      login.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const [loginList, setLoginList] = useState<Users[]>([]);
  const [text, setText] = useState("");

  useEffect(() => {
    const Debounce = setTimeout(() => {
      const filteredLogin: any = filterLogin(text, log);
      setLoginList(filteredLogin);
    }, 500);
    return () => clearTimeout(Debounce);
  }, [text]);
  return (
    <div className="flex">
      <div className="Form">
        <input
          id="registerInput"
          type="search"
          placeholder="Поиск админов"
          onChange={(e) => setText(e.target.value)}
        />
        <form className="profile">
          {loginList.map((prof: any) => (
            <SearchAdmin prof={prof} admins={admins} />
          ))}
        </form>
        <div>
          <h2>Администраторы</h2>
          {admins.map((adm: AdminType) => (
            <Admin adm={adm} />
          ))}
        </div>
      </div>
    </div>
  );
};
