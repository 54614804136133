import { FC, useEffect, useState } from "react";
import { closeChamp, createCHampSlice, createChampType } from "../../../store/settingSlice";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { RootState } from "../../../store";
import { Users } from "../../../store/loginSlice";


export const CreateChampionships: FC<any> = ({ login }) => {
    const dispatch = useAppDispatch();
    const [name, setNameChamp] = useState({});
    const [ranking, setR] = useState({});
    const [ktgA, setCheckA] = useState(false);
    const [ktgB, setCheckB] = useState(false);
    const [ktgC, setCheckC] = useState(false);
    const [foto, setFoto] = useState('');
    const [restric, setRestric] = useState('');
    const [tournament_regulations, setProvisions0] = useState('');
    const [closeReg, setCloseReg] = useState(0);
    const [firstDate, setFirstDate] = useState(0);
    const [lastDate, setLastDate] = useState(0);
    const [img, setImg] = useState('');
    const [errorDate, setErrorDate] = useState(false);
    const [errorFirst, setErrorFirst] = useState(false);
    const [errorReg, setErrorReg] = useState(false);
    const [j, setJ] = useState(0);
    const [disabl, setDisabl] = useState(true);
    const CreateStatus = useAppSelector(state => state.setting.champCreateStatus);
    const CreateID = useAppSelector(state => state.setting.champId);

    const [close_register, setClose_register] = useState('');
    const [open_tournament, setOpen_tournament] = useState('');
    const [close_tournament, setClose_tournament] = useState('');
    
    const userg: Users = useAppSelector(state => state.login.user);

    const users = useAppSelector(state => state.login.log);
    let findUser: Users = users.find((item: Users) => item?.id === userg?.id) || {};
    let dataUserCopy: Users = {};
    const [User, setUser] = useState(dataUserCopy);

    const CountryStor = useAppSelector(state => state.login.country);
    const CityStor = useAppSelector(state => state.login.city);
    const RegionStor = useAppSelector(state => state.login.region);

    const [country2, setCountry] = useState(findUser?.country);
    // const [city, setCity] = useState(0);
    const [region2, setRegion] = useState(findUser?.region);
    useEffect(() => {
        setCountry(findUser?.country)
        setRegion(findUser?.region)
    }, [findUser]);
    let regionFilter = []
    let cityFilter = []
    regionFilter = RegionStor.filter(item => item.country === country2)
    cityFilter = CityStor.filter(item => item.region === region2)

    useEffect(() => {
        setUser({...dataUserCopy, ...userg, ...{country: findUser?.country}, ...{region: findUser?.region}, ...{city: findUser?.city}})
    }, [userg]);


    const closeRegister = (e: any) => {
        if (e) {
            setClose_register(e)
            setErrorReg(false)
            let er: any = Number(new Date(e));
            setCloseReg(er)
        }
    }

    // let date = new Date();
    let dateInt = Number(new Date()) - 86400000;
    const openDate = (e: any) => {
        if (e) {
            setOpen_tournament(e)
            setErrorDate(false)
            let f: number = Number(new Date(e));
            let d: number = Number(new Date(e));
            setFirstDate(f)

            if (d >= dateInt) {
                setErrorFirst(false)
            } else {
                setErrorFirst(true)
            }
        }
    }
    const closeDate = (e: any) => {
        setClose_tournament(e)
        setErrorDate(false)
        let l: number = Number(new Date(e));
        setLastDate(l)
    }

    let h: number = lastDate - firstDate;
    useEffect(() => {
        setErrorReg(false)
        if (firstDate && lastDate) {
            if (firstDate > lastDate) {
                setJ(0)
                setErrorDate(true)
            } else {
                setJ(h / 86400000 + 1);
            }
        }
        if (lastDate !== 0) {
            if (closeReg > lastDate) {
                setErrorReg(true)
            } else {
                setErrorReg(false)
            }
        }
        if (firstDate !== 0) {
            if (closeReg > firstDate) {
                setErrorDate(true)
            } else {
                setErrorDate(false)
            }
        }

        if (closeReg !== 0) {
            if (closeReg <= dateInt) {
                setErrorReg(true)
            }
        }
    }, [firstDate, lastDate, closeReg]);
    let t = true
    if (firstDate && lastDate) {
        t = false
    }

    function uploadLogo(e: React.ChangeEvent<any>) {
        let file = e.target.files[0];
        if (file) {
            setFoto(file)
            const selectedFile = file;
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.addEventListener('load', (event: any) => {
                let img = event.target.result;
                setImg(img)
            });
        }
    }
    const fileP = (e: React.ChangeEvent<any>) => {
        if (e.target.files[0]) {
            setProvisions0(e.target.files[0])
        }
    }
    const rankingS = (e: any) => setR(e);
    const checkboxA = () => setCheckA(!ktgA);
    const checkboxB = () => setCheckB(!ktgB);
    const checkboxC = () => setCheckC(!ktgC);
    const nameChampionship = (e: any) => setNameChamp(e);
    const Restriction = (e: any) => setRestric(e);

    const [buttonOf, setButtonOf] = useState(false);
    const createChamp = () => {
        setButtonOf(true)
        let checkA = 0;
        let checkB = 0;
        let checkC = 0;
        if(ktgA === true){
            checkA = 1;
        }
        if(ktgB === true){
            checkB = 1;
        }
        if(ktgC === true){
            checkC = 1;
        }
        let country = User?.country
        let region = User?.region
        let city = User?.city
        dispatch(createCHampSlice({ name, ranking, checkA, checkB, checkC, restric, close_register, open_tournament, close_tournament, foto, tournament_regulations, country, region, city }))

    }

    useEffect(() => {
        if (name && ranking && !buttonOf && (ktgA || ktgB || ktgC) && foto && restric && firstDate && lastDate && closeReg && User.country && User?.region && User?.city) {
            setDisabl(false)
        } else {
            setDisabl(true)
        }
    }, [name, ranking, ktgA, ktgB, ktgC, foto, restric,  tournament_regulations, closeReg, firstDate, lastDate, User]);

    if (CreateStatus!.status) {
        dispatch(closeChamp())
        return <Navigate to={"/" + login} />
    }

    const updateLogin = (e: React.ChangeEvent<HTMLSelectElement>, name: any) => {
        let data: Users = {...User}
        if (name === "country") {
            data.country = parseInt(e.target.value)
            data.region = 0
            data.city = 0
            setRegion(0)
            // setCity(0)
            setCountry(parseInt(e.target.value))
            setUser(data)
          }
          if (name === "region") {
            data.region = parseInt(e.target.value)
            data.city = 0
            setRegion(parseInt(e.target.value))
            setUser(data)
          }
          if (name === "city") {
            data.city = parseInt(e.target.value)
            setUser(data)
          }
    }

    return (
        <>
            <form>
                <h3>Создание турнира</h3>
                <label>
                    <div>Названия турнира</div>
                    <input type="text" onChange={(e) => nameChampionship(e.target.value)} />
                </label>
                <label>
                    <div>Рейтинг</div>
                    <select onChange={(e) => rankingS(e.target.value)}>
                        <option selected value="">не выбран</option>
                        <option value="1">H1</option>
                        <option disabled value="2">H2</option>
                        <option disabled value="3">H3</option>
                        <option disabled value="4">H4</option>
                        <option disabled value="5">H5</option>
                        <option disabled value="6">H6</option>
                        <option disabled value="7">H7</option>
                        <option disabled value="8">H8</option>
                        <option disabled value="9">H9</option>
                        <option disabled value="10">H10</option>
                    </select>
                </label>
                <label className="yearsRadioLabel">
                    <div>Категория сложности</div>
                    <span>
                        <input type="checkbox" checked={ktgA} onChange={checkboxA} />
                        <div>A (сложный)</div>
                    </span>
                    <span>
                        <input type="checkbox" checked={ktgB} onChange={checkboxB} />
                        <div>Б (средний)</div>
                    </span>
                    <span>
                        <input type="checkbox" checked={ktgC} onChange={checkboxC} />
                        <div>С (новичёк)</div>
                    </span>
                </label>
                <label >

                    {img && <div className="ava"><img className="avaImg" src={img} alt="" /></div>}

                </label>
                <label>
                    <div>Добавьте картинку турнира</div>
                    <input accept="image/*" type="file" onChange={(e) => uploadLogo(e)} />
                </label>
                <label>
                    <div>Положения турнира</div>
                    <input type="file" multiple onChange={(e) => fileP(e)} />
                </label>
                <label>
                    <div>Дата закрытие регистраций для спортсменов</div>
                    <input className={errorReg ? "borderRed" : ''} type="date" onChange={(e) => closeRegister(e.target.value)} />
                </label>
                <label>
                    <div>Начальная дата турнира</div>
                    <input className={errorFirst ? "borderRed" : ''} type="date" onChange={(e) => openDate(e.target.value)} />
                </label>
                <label>
                    <div>Конечная дата турнира</div>
                    <input className={errorDate ? "borderRed" : ''} type="date" onChange={(e) => closeDate(e.target.value)} />
                </label>
                {errorReg && <p className="red">Не правильная дата закрытие регистраций!</p>}
                {errorDate && <p className="red">Не правильная дата!</p>}
                {!errorReg && !errorDate && !errorFirst && j && <p className="green">Длительность соревнований: {j}д</p>}
                {errorFirst && <p className="red">Не можете выбрать прошлое время</p>}
                <label>
                    <div>Ограничение участников</div>
                    <input type="number" onChange={(e) => Restriction(e.target.value)} />
                </label>
                <label>
                    <select value={User?.country} className="s settingSel" id='registerInput' onChange={(e) => updateLogin(e, 'country')} >
                        <option value="">Страна</option>
                        {CountryStor.map((o) => (
                            <option value={o?.id}>{o?.name}</option>
                        ))}
                    </select>
                    {/* {errors.country && <span className='regErr'>{(errors as any).country.message}</span>} */}
                </label>
                        

                <select value={User?.region} className="s settingSel" id='registerInput' onChange={(e) => updateLogin(e, 'region')}>
                    <option value="">Город</option>
                    {regionFilter.map((or) => (
                        <option value={or?.id}>{or?.name}</option>
                    ))}
                </select>
                {/* {errors.region && <span className='regErr'>{(errors as any).region.message}</span>} */}

                <select value={User?.city} className="s settingSel" id='registerInput' onChange={(e) => updateLogin(e, 'city')} >
                    <option  value="">Село</option>
                    {cityFilter.map((oc) => (
                        <option value={oc?.id}>{oc?.name}</option>
                    ))}
                </select>
                {/* {errors.city && <span className='regErr'>{(errors as any).city.message}</span>} */}
                {userg?.error && <div className="JustefayCenter"><div className="red">{userg?.error}</div></div>}
                <input type="button" id={!disabl ? "submitIsValid" : "submitReg"} value="Создать турнир" onClick={createChamp} disabled={disabl} />
            </form>
        </>
    )

}