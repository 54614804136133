import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { NavLink, Navigate } from "react-router-dom";
import { FC, useState } from "react";
import { useAppSelector } from "../../../store/hook";
import { Users } from "../../../store/loginSlice";
import "./style.css";
import { yupResolver } from "@hookform/resolvers/yup";

const Remove: FC<any> = ({
  updateLogin,
  error,
  fields,
  message,
  login,
  removeProfile,
  name,
  tel,
  email,
  birthday,
  status,
  outLog,
  out,
}) => {
  const coaches = useAppSelector((state) => state.login.coach);

  const [touchedFields, setTouchedFields] = useState({
    name: false,
    login: false,
    tel: false,
    email: false,
    birthday: false,
  });

  const handleFieldChange = (fieldName: string) => {
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [fieldName]: true,
    }));
  };

  const outLogin = () => {
    let outL = window.confirm("Точно хотите выйты из аккаунта?");
    if (outL) {
      sessionStorage.removeItem("rttp");
      localStorage.removeItem("attt");
      outLog();
    }
  };

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    // reset,
    // control
  } = useForm({
    mode: "onBlur",
  });

  // if (idfirstName === "" && idlastName === "" && idpatronymic === "" && idlogin === "" && idtel === "" && idemail === "") {
  //   return <Navigate to={"/signin"} />
  // }
  // JQuery мен жасалган, дурыс емес

  return (
    <div className="flex">
      <div className="Form">
        <form onSubmit={handleSubmit(removeProfile)}>
          {out && <Navigate to={"/signin"} />}
          <h1 className="red">Редактор</h1>

          <label className="labelForm">
            {name === "" ? (
              <div className="registerInputDiv"></div>
            ) : (
              <div className="registerInputDiv">Фамилия и имя</div>
            )}
            <input
              id="registerInput"
              placeholder="Фамилия и имя"
              value={name}
              {...register("name", {
                onChange: (e) => {
                  updateLogin(e, "name");
                  handleFieldChange("name");
                },
                required: touchedFields.name
                  ? "Поле обязательно к заполнению"
                  : false, // Изменяем сообщение об ошибке в зависимости от touched
                minLength: {
                  value: 2,
                  message: "Минимум 2 символа!",
                },
                maxLength: {
                  value: 50,
                  message: "Максимум 50 символов!",
                },
              })}
            />
          </label>
          {(errors as any)?.name && (
            <div className="regErr">
              <p>{(errors as any)?.name?.message || "Error!"}</p>
            </div>
          )}

          <label className="labelForm">
            {login === "" ? (
              <div className="registerInputDiv"></div>
            ) : (
              <div className="registerInputDiv">Логин</div>
            )}
            <input
              id="registerInput"
              placeholder="Логин"
              type="text"
              value={login}
              {...register("login", {
                onChange: (e) => {
                  updateLogin(e, "login");
                  handleFieldChange("login");
                },
                required: touchedFields.login
                  ? "Поле обязательно к заполнению"
                  : false,
                pattern: {
                  value:
                    /^[A-Za-z]+([-_]?[A-Za-z0-9]+){0,2}\s$|^[A-Za-z]+([-_]?[A-Za-z0-9]+){0,2}$/,
                  message: "Не правильное формат логина",
                },
                minLength: {
                  value: 2,
                  message: "Минимум 2 символа!",
                },
                maxLength: {
                  value: 50,
                  message: "Максимум 50 символов!",
                },
              })}
            />
          </label>
          {(errors as any)?.login && (
            <div className="regErr">
              <p>{(errors as any)?.login?.message || "Error!"}</p>
            </div>
          )}
          {fields === "login" && (
            <div className="regErr">
              <p>{message || "Error!"}</p>
            </div>
          )}

          <label className="labelForm">
            {tel === "" ? (
              <div className="registerInputDiv"></div>
            ) : (
              <div className="registerInputDiv">Номер телефона</div>
            )}
            <input
              id="registerInput"
              type="tel"
              value={tel}
              placeholder="Номер телефона"
              {...register("tel", {
                onChange: (e) => {
                  updateLogin(e, "tel");
                  handleFieldChange("tel");
                },
                required: touchedFields.tel
                  ? "Поле обязательно к заполнению"
                  : false,
                pattern: {
                  value:
                    /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$|^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])\s$/,
                  message: "Не правильный номер телефона",
                },
                minLength: {
                  value: 11,
                  message: "Минимум 8 символов!",
                },
                maxLength: {
                  value: 50,
                  message: "Максимум 50 символов!",
                },
              })}
            />
          </label>
          {(errors as any)?.tel && (
            <div className="regErr">
              <p>{(errors as any)?.tel?.message || "Error!"}</p>
            </div>
          )}
          {fields === "phone" && (
            <div className="regErr">
              <p>{message || "Error!"}</p>
            </div>
          )}

          <label className="labelForm">
            {email === "" ? (
              <div className="registerInputDiv"></div>
            ) : (
              <div className="registerInputDiv">Электронная почта</div>
            )}
            <input
              id="registerInput"
              type="email"
              placeholder="Электронная почта"
              value={email}
              {...register("email", {
                onChange: (e) => {
                  updateLogin(e, "email");
                  handleFieldChange("email");
                },
                required: touchedFields.email
                  ? "Поле обязательно к заполнению"
                  : false,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message:
                    "Введенное значение не соответствует формату электронной почты",
                },
                minLength: {
                  value: 5,
                  message: "Минимум 4 символов!",
                },
                maxLength: {
                  value: 50,
                  message: "Максимум 50 символов!",
                },
              })}
            />
          </label>
          {(errors as any)?.email && (
            <div className="regErr">
              <p>{(errors as any)?.email?.message || "Error!"}</p>
            </div>
          )}
          {fields === "email" && (
            <div className="regErr">
              <p>{message || "Error!"}</p>
            </div>
          )}

          <label className="labelForm">
            <div className="registerInputDiv">Дата рождения</div>
            <div className="select">
              {/* <div>Дата рождения: {birthday}</div> */}
              <input
                type="date"
                id="registerInput"
                value={birthday}
                {...register("birthday", {
                  onChange: (e) => {
                    updateLogin(e, "birthday");
                    handleFieldChange("birthday");
                  },
                  required: touchedFields.birthday
                    ? "Поле обязательно к заполнению"
                    : false,
                })}
              />
            </div>
          </label>
          {(errors as any)?.birthday && (
            <div className="regErr">
              <p>{(errors as any)?.birthday?.message || "Error!"}</p>
            </div>
          )}
          {fields === "birthday" && (
            <div className="regErr">
              <p>{message || "Error!"}</p>
            </div>
          )}

          <div id="textDiv">
            <NavLink to="/password">
              <div className="clickPassword">Изменить пароль</div>
            </NavLink>
            <div onClick={outLogin} className="logOut">
              Выйти из аккаунта
            </div>
          </div>

          {status && (
            <div className="JustefayCenter">
              <div className="green">Сохранено успешно</div>
            </div>
          )}
          {error && (
            <div className="JustefayCenter">
              <div className="red">{error}</div>
            </div>
          )}

          <input type="submit" id="submitReg" value="Сохранить" />
        </form>
      </div>
    </div>
  );
};

export default Remove;
