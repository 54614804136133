import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  AnyAction,
} from "@reduxjs/toolkit";
import { api, instanceGET, instancePOST } from "./axiosInstans";
import { url } from "./url";

const Apiq = url();

export type SType = {
  id?: any;
  championship_id?: number;
  creator?: number;
  championship_participant_id?: number;
  i_grid?: number;
  i_inside_each_grid?: number;
  place?: number;
  point?: number;
  message?: string;
};
export type SBoolType = {
  status?: boolean | string;
};
export type SetkaStateType = {
  S: SBoolType;
  setka: SType[];
  delete: any;
  loading: boolean;
  error: null;
  admins: AdminType[];
  admissionAdmin: AdmissionAdminsType[];
  message: string;
};
export type KGtype = {
  kg: string;
  ktg: string;
  years: string;
};
export type dispatchData = {
  KG: KGtype;
  idChild: string;
  indexS: string;
  createID: string;
  idChamp: string;
  indexArr: any;
};

export type AdminType = {
  id?: number;
  admin?: number;
  creator?: number;
};
export type AdmissionAdminsType = {
  id?: number;
  championship_id?: number;
  child?: number;
  allow?: number;
};

export const setkaADD = createAsyncThunk<SType, SType, { rejectValue: string }>(
  "setka/setkaADD",
  async function (
    {
      championship_id,
      creator,
      championship_participant_id,
      i_grid,
      i_inside_each_grid,
      place,
    },
    { rejectWithValue }
  ) {
    try {
      const jsonS = {
        championship_id,
        creator,
        championship_participant_id,
        i_grid,
        i_inside_each_grid,
        place,
      };

      const response: any = await api.post(
        Apiq + "api/auth/AddChampionshipGrid",
        jsonS
      );
      return (await response.data.data) as any;
    } catch (error) {
      return rejectWithValue("");
    }
  }
);
export const setkaDelete = createAsyncThunk<
  SType,
  SType,
  { rejectValue: string }
>(
  "setka/setkaDelete",
  async function (
    {
      championship_id,
      creator,
      championship_participant_id,
      i_grid,
      i_inside_each_grid,
      place,
    },
    { rejectWithValue }
  ) {
    try {
      const jsonS = {
        championship_id,
        creator,
        championship_participant_id,
        i_grid,
        i_inside_each_grid,
        place,
      };

      const response: any = await api.post(
        Apiq + "api/auth/DeleteChampionshipGrid",
        jsonS
      );
      return (await response.data.data) as SType;
    } catch (error) {
      return rejectWithValue("");
    }
  }
);
export const getSetka = createAsyncThunk<SType[], any, { rejectValue: string }>(
  "setka/getSetka",
  async function ({ championship_id }, { rejectWithValue }) {
    try {
      const jsonS = {
        championship_id,
      };
      const response: any = await api.post(
        Apiq + "api/auth/GetChampionshipGrid",
        jsonS
      );
      // dispatch(addsetka(response.data));
      return (await response.data.data) as SType[];
    } catch (error) {
      return rejectWithValue("");
    }
  }
);
export const getPageGrid = createAsyncThunk<
  SType[],
  any,
  { rejectValue: string }
>("setka/getPageGrid", async function ({ child }, { rejectWithValue }) {
  try {
    const jsonS = {
      child,
    };
    const response: any = await api.post(
      Apiq + "api/auth/GetPageChampionshipGrid",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as SType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const addAdmin = createAsyncThunk<
  AdminType,
  AdminType,
  { rejectValue: string }
>("setka/addAdmin", async function ({ admin }, { rejectWithValue }) {
  try {
    const jsonS = {
      admin,
    };

    const response: any = await api.post(Apiq + "api/auth/AddAdmin", jsonS);
    return (await response.data.data) as any;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const getAdmin = createAsyncThunk<
  AdminType[],
  undefined,
  { rejectValue: string }
>("setka/getAdmin", async function (_, { rejectWithValue }) {
  try {
    const response: any = await api.post(Apiq + "api/auth/GetAdmin");
    // dispatch(addsetka(response.data));
    console.log("response", response.data.data);
    return (await response.data.data) as AdminType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const deleteAdmin = createAsyncThunk<
  AdminType,
  AdminType,
  { rejectValue: string }
>("setka/deleteAdmin", async function ({ id }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
    };

    const response: any = await api.post(Apiq + "api/auth/DeleteAdmin", jsonS);
    return (await response.data.data) as AdminType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const AddAdmissionAdmins = createAsyncThunk<
  AdmissionAdminsType,
  AdmissionAdminsType,
  { rejectValue: string }
>(
  "setka/AddAdmissionAdmins",
  async function ({ championship_id, child, allow }, { rejectWithValue }) {
    try {
      const jsonS = {
        championship_id,
        child,
        allow,
      };

      const response: any = await api.post(
        Apiq + "api/auth/AddAdmissionAdmins",
        jsonS
      );
      return (await response.data.data) as AdmissionAdminsType;
    } catch (error) {
      return rejectWithValue("");
    }
  }
);
export const GetAdmissionAdmins = createAsyncThunk<
  AdmissionAdminsType[],
  AdmissionAdminsType,
  { rejectValue: string }
>(
  "setka/GetAdmissionAdmins",
  async function ({ championship_id }, { rejectWithValue }) {
    try {
      const jsonS = {
        championship_id,
      };

      const response: any = await api.post(
        Apiq + "api/auth/GetAdmissionAdmins",
        jsonS
      );
      return (await response.data.data) as AdmissionAdminsType[];
    } catch (error) {
      return rejectWithValue("");
    }
  }
);
export const DeleteAdmissionAdmins = createAsyncThunk<
  AdmissionAdminsType,
  AdmissionAdminsType,
  { rejectValue: string }
>(
  "setka/DeleteAdmissionAdmins",
  async function ({ id, championship_id }, { rejectWithValue }) {
    try {
      const jsonS = {
        id,
        championship_id,
      };

      const response: any = await api.post(
        Apiq + "api/auth/DeleteAdmissionAdmins",
        jsonS
      );
      return (await response.data.data) as AdmissionAdminsType;
    } catch (error) {
      return rejectWithValue("");
    }
  }
);
export const updateWinner = createAsyncThunk<
  SType[],
  SType,
  { rejectValue: string }
>("setka/updateWinner", async function ({ id, place }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
      place,
    };
    const response: any = await api.post(
      Apiq + "api/auth/WinnerChampionshipGrid",
      jsonS
    );
    if (response.data.data !== undefined) {
      return (await response.data.data) as SType[];
    } else {
      return response;
    }
  } catch (error) {
    return rejectWithValue("");
  }
});

export const updateLoser = createAsyncThunk<
  SType,
  SType,
  { rejectValue: string }
>("setka/updateLoser", async function ({ id, place }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
      place,
    };

    const response: any = await api.post(
      Apiq + "api/auth/LoserChampionshipGrid",
      jsonS
    );

    if (response.data.data !== undefined) {
      return (await response.data.data) as SType;
    } else {
      return response;
    }
  } catch (error) {
    return rejectWithValue("");
  }
});
// export const medalSlice = createAsyncThunk(
//     'setka/medalSlice',
//     async function ({ id, medal }, { rejectWithValue, dispatch }) {
//         try {
//             const formData = new FormData();
//             formData.append("id1", id);
//             formData.append("medal", medal);

//             const response = await axios.post(Api + 'setka/medal/wins', formData, {
//                 withCredentials: true,
//                 headers: {
//                     // Overwrite Axios's automatically set Content-Type
//                     "Content-type": "multipart/form-date"
//                 },
//             });

//             dispatch(addsetka(response.data));
//             if (!response.ok) {
//                 throw new Error('Can\'t add task. Server error.');
//             }

//         } catch (error) {
//             return rejectWithValue(error.message);
//         }
//     }
// );
// export const deleteSliceSetka = createAsyncThunk(
//     'setka/deleteSliceSetka',
//     async function ({ delet }, { rejectWithValue, dispatch }) {
//         try {
//             const formData = new FormData();
//             formData.append("setkaID", delet);

//             const response = await axios.post(Api + 'setka/deleteSetka/deleteSetka', formData, {
//                 withCredentials: true,
//                 headers: {
//                     // Overwrite Axios's automatically set Content-Type
//                     "Content-type": "multipart/form-date"
//                 },
//             });

//             dispatch(addsetka(response.data));
//             if (!response.ok) {
//                 throw new Error('Can\'t add task. Server error.');
//             }

//         } catch (error) {
//             return rejectWithValue(error.message);
//         }
//     }
// );

// const setError = (state, action) => {
//     state.status = 'rejected';
//     state.error = action.payload;
// }

const initialState: SetkaStateType = {
  S: { status: true },
  setka: [],
  delete: { status: false },
  loading: false,
  error: null,
  admins: [],
  admissionAdmin: [],
  message: "",
};

const setkaSlice = createSlice({
  name: "setka",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setkaADD.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setkaADD.fulfilled, (state, action) => {
        state.setka = state.setka.filter(
          (item: SType) =>
            item?.championship_id !== action.payload.championship_id ||
            item?.championship_participant_id !==
              action.payload.championship_participant_id
        );
        state.setka.push(action.payload);
      })
      .addCase(setkaDelete.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setkaDelete.fulfilled, (state, action) => {
        state.setka = state.setka.filter(
          (t: SType) => t.id !== action.payload?.id
        );
      })
      .addCase(getSetka.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSetka.fulfilled, (state, action) => {
        state.setka = action.payload;
      })
      .addCase(getAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdmin.fulfilled, (state, action) => {
        state.admins = action.payload;
      })

      .addCase(getPageGrid.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPageGrid.fulfilled, (state, action) => {
        let d: any[] = state.setka;
        let g: any[] = action.payload;

        for (let i = 0; i < g.length; i++) {
          let fD: any[] = d.filter((item: any) => item?.id === g[i].id);
          if (fD.length === 0) {
            d = [...d, g[i]];
          }
        }
        state.setka = d;
      })
      .addCase(deleteAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAdmin.fulfilled, (state, action) => {
        state.admins = state.admins.filter(
          (t: SType) => t.id !== action.payload?.id
        );
      })
      .addCase(GetAdmissionAdmins.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAdmissionAdmins.fulfilled, (state, action) => {
        state.admissionAdmin = action.payload;
      })
      .addCase(AddAdmissionAdmins.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddAdmissionAdmins.fulfilled, (state, action) => {
        let d: AdmissionAdminsType[] = state.admissionAdmin;
        let g: AdmissionAdminsType = action.payload;
        if (g?.id !== undefined) {
          if (d.length === 0) {
            d = [...d, g];
            state.admissionAdmin = d;
          } else {
            let k: AdmissionAdminsType[] = [];
            for (let i = 0; i < d.length; i++) {
              if (d[i]?.id === g?.id) {
                k = [...k, g];
              }
            }
            if (k.length === 0) {
              d = [...d, g];
              state.admissionAdmin = d;
            }
          }
        }
        state.loading = false;
      })
      .addCase(DeleteAdmissionAdmins.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(DeleteAdmissionAdmins.fulfilled, (state, action) => {
        state.admissionAdmin = state.admissionAdmin.filter(
          (t: AdmissionAdminsType) => t.id !== action.payload?.id
        );
      })
      .addCase(updateWinner.fulfilled, (state, action) => {
        let d: SType[] = action.payload;
        for (let i = 0; i < d.length; i++) {
          if (d[i]?.message) {
            state.message = d[i]?.message || "";
          } else {
            const updateApproved: SType =
              state.setka.find((todo) => todo.id === d[i].id) || {};
            updateApproved!.place = d[i].place;
            updateApproved!.point = d[i].point;
          }
        }
      })
      .addCase(updateLoser.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.message = action.payload.message;
        } else {
          const updateApproved: SType =
            state.setka.find((todo) => todo.id === action.payload.id) || {};
          updateApproved!.place = action.payload.place;
          updateApproved!.point = action.payload.point;
        }
      })
      .addMatcher(isError, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

// const { addsetka, getSetkiler } = setkaSlice.actions;

export default setkaSlice.reducer;
const isError = (action: AnyAction) => {
  return action.type.endsWith("rejectad");
};
