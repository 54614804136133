import { FC } from "react"

export const CheckboxLeftRight: FC<any> = ({ isChecked, handleCheckboxChange }) => {



    const isCheck = () => {

    }
    return (
        <div>
            <div className='checkboxSlider' id={isChecked ? 'checkboxSlider1' : 'checkboxSlider2'}>
                <input type="checkbox"
                    id="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
                <div id={isChecked ? 'shar1' : 'shar2'}>
                </div>
            </div>
        </div>
    )
}