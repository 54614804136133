import { Link, Navigate } from "react-router-dom";
import { FC } from "react";
import { useAppDispatch } from "../../../store/hook";
import { out } from "../../../store/loginSlice";

export const Setting: FC<any> = ({ position }) => {
  const dispatch = useAppDispatch();
  const outLogin = () => {
    let outL = window.confirm("Точно хотите выйты из аккаунта?");
    if (outL) {
      // localStorage.removeItem('attt')
      dispatch(out());
    }
  };
  return (
    <div className="SettingFon">
      <div className="SettingBody">
        <div>
          <Link to="#">Безопасность</Link>{" "}
        </div>
        {position === 3 && (
          <div>
            <Link to="/CreateChampionships">Создать турнир</Link>{" "}
          </div>
        )}
        <div>
          <Link to="/remove">Изменить профиль</Link>
        </div>
        <div>
          <Link to="/settingProfile">Настройка</Link>
        </div>
        <div>
          <Link to="#" onClick={outLogin}>
            Выйти
          </Link>
        </div>
        <div>
          <Link to="#">Отмена</Link>
        </div>
      </div>
    </div>
  );
};
