import { FC, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../store/hook";
import { AddGrade, GradeType, SubjectADD, UpdateGrade, classADD, classADDType } from "../../../../../store/gradeSlice";
import { Link } from "react-router-dom";
import { RemoveChildrens } from "./removeChildrens";
import { ShowChildren } from "./showChildren";
import { ChildrenGrade } from "./remove/childrenGrade";
import { Inputs } from "./remove/inputs";
import { subscribeType } from "../../../../../store/subscribeSlice";
import { Assoc } from "./assoc";
import { Users } from "../../../../../store/loginSlice";
import { SelectOptions } from "./selectOptions";
import { SlArrowDown } from "react-icons/sl";
type RemoveGradeType = {
    grade: any
    sj: classADDType[]
    pageData: string
    subsCh: any
    Userter: Users[]
    User: Users
    subscribed: any
    MyChildFilter: subscribeType[]
    p: any
    cl: classADDType[]
    association_id: string | null
    arrAssoc_id: string[]
    ass: number
    position: number
    functionAssAndCoach: any
}

export const RemoveGrade: FC<RemoveGradeType> = ({ functionAssAndCoach, grade, sj, ass, pageData, subsCh, Userter, User, subscribed, MyChildFilter, p, cl, association_id, arrAssoc_id, position }) => {
    const dispatch = useAppDispatch();
    
    const users = useAppSelector(state => state.login.log);

    // useEffect(() => {
    //     functionAssAndCoach(subscribed)
    // }, []);

    const [classCh, setClassCh] = useState(cl[0]?.id);
    const [subjectCh, setSubjectCh] = useState(sj[0]?.id);

    const functionR = (status: any, a: any) => {
        if (status === "class") {
            setClassCh(a)
        }
        if (status === "subject") {
            setSubjectCh(a)
        }
    }

    const [show, setShow] = useState(false);
    const addChild = () => {
        setShow(!show)
    }

    const childCl = useAppSelector(state => state.grade.childClass);
    let q = childCl.filter(i => i.coach === User?.id && i.class_id === classCh);

    const [updateTime, setUpdateTime] = useState('01:30');
    const [study_time, setTime] = useState('');
    let kl = [...updateTime]
    let hours = kl[0] + kl[1];
    let min = kl[3] + kl[4]
    Number(hours)
    Number(min)
    let h = (Number(hours) * 60 * 60) + (Number(min) * 60)
    useEffect(() => {
        setTime(String(h))
    }, [updateTime]);

    const functionStar = (subject_id: any, class_id: any, child: any, grades: any) => {
        let coach = subscribed
        let assoc: any = "";
        if (User?.position === 3) {
            assoc = association_id
        }
        if (User?.position === 2) {
            assoc = ass
        }
        if (subject_id && class_id && child && coach) {

            dispatch(AddGrade({ subject_id, class_id, child, coach, grades, assoc, study_time }))
        }
    }
    const functionStarState = (id: any, grades: any) => {
        dispatch(UpdateGrade({ id, grades }))
    }

    const subsAssociation: subscribeType[] = useAppSelector(state => state.subscribe.subscribe);
    let subsAssoc: subscribeType = subsAssociation.find((item: subscribeType) => item?.subscribed === subscribed) || { id: "", pageData: "", subscribed: "", date: "", approved: "" };

    const [shadow, setShadow] = useState(false);
    const [assocSel, setAssocSel] = useState(false);
    const [classSel, setClassSel] = useState(false);
    const [subjectSel, setSubjectSel] = useState(false);
    const assocClick = () => {
        setShadow(true)
        setAssocSel(true)
    }
    const classClick = () => {
        setShadow(true)
        setClassSel(true)
    }
    const subjectClick = () => {
        setShadow(true)
        setSubjectSel(true)
    }

    const shadowOf = () => {
        setShadow(false)
        setClassSel(false)
        setSubjectSel(false)
        setAssocSel(false)
    }

    let findAss: Users = Userter.find((item: Users) => item?.id === ass) || {}
    let findCl: classADDType = cl.find((item: classADDType) => item?.id === classCh) || {}
    let findSj: classADDType = sj.find((item: classADDType) => item?.id === subjectCh) || {}




    return (
        <div>
            {show && <ShowChildren addChild={addChild} subsCh={subsCh} Userter={Userter} User={User} childCl={childCl} classCh={classCh} />}
            

            {shadow && <div onClick={shadowOf} className="settingAbsolut"></div>}
            {assocSel && shadow && <SelectOptions shadowOf={shadowOf} pageData={pageData} Userter={Userter} User={User} status={"assoc"} assocSel={assocSel} arr={arrAssoc_id} hide={false} functionR={functionAssAndCoach} R={ass} />}
            {classSel && shadow && <SelectOptions shadowOf={shadowOf} pageData={pageData} User={User} classSel={classSel} status={"class"} arr={cl} hide={false} functionR={functionR} R={classCh} />}
            {subjectSel && shadow && <SelectOptions shadowOf={shadowOf} pageData={pageData} User={User} status={"subject"} subjectSel={subjectSel} arr={sj} hide={false} functionR={functionR} R={subjectCh} />}

            <div className="selectGrade">
                {position === 2 && < div onClick={assocClick} className="classSubject">
                    <h3>Ассоциация: {findAss?.login}</h3>
                    <SlArrowDown id="SlArrowDown" />
                </div>}
                {<div onClick={classClick} className="classSubject">
                    <h3 >Классы: {findCl?.title}</h3>
                    <SlArrowDown id="SlArrowDown" />
                </div>}
                {<div onClick={subjectClick} className="classSubject">
                    <h3>Предметы: {findSj?.title}</h3>
                    <SlArrowDown id="SlArrowDown" />
                </div>}
            </div>
            <label htmlFor="" className='LabelTime'>
                <div>Время занятий</div>
                <input type="time" id='registerInput' value={updateTime} onChange={(e) => setUpdateTime(e.target.value)} name="" />
            </label>

            <div className="buttonAddChild" >
                <input type="button" value="Добавить учеников" onClick={addChild} />
            </div>
            <div className="childClass">
                {subjectCh && q.map((child) => (
                    <ChildrenGrade MyChildFilter={MyChildFilter} subsCh={subsCh} functionStar={functionStar} functionStarState={functionStarState} grade={grade} children={child} users={users} class_id={classCh} subject={subjectCh} dispatch={dispatch} coach={pageData} subscribed={subscribed} />
                ))}
            </div>
        </div >
    )
}

// {position === 2 && < div className="classSubject">
//                 <h3>Ассоциация:</h3>
//                 <main className="GradeRem">
//                     {arrAssoc_id.map((as: string) => (
//                         <Assoc id={as} Userter={Userter} functionAss={functionAss} ass={ass} />
//                     ))}
//                 </main>
//             </div>}
//             {cl.length > 0 && <div className="classSubject">
//                 <h3>Классы:</h3>
//                 <main className="GradeRem">
//                     {cl.map((c: classADDType) => (
//                         <RemoveChildrens User={User} status={'class'} name={c.name} id={c.id} association_id={c.association_id} dispatch={dispatch} functionR={functionR} R={classCh} />
//                     ))}
//                 </main>
//             </div>}
//             {sj.length > 0 && <div className="classSubject">
//                 <h3>Предмет:</h3>
//                 <main className="GradeRem">
//                     {sj.map((s: classADDType) => (
//                         <RemoveChildrens User={User} status={'subject'} name={s.name} id={s.id} association_id={s.association_id} dispatch={dispatch} functionR={functionR} R={subjectCh} />
//                     ))}
//                 </main>
//             </div>}