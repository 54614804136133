import { FC } from "react"

export const Inputs: FC<any> = ({ classSel, subjectSel, Subject, title, addClass, addSubject, newText, newSubject }) => {
    return (
        <div>
            {
               classSel && <label>
                    <input id='registerInput' value={title} type="text" placeholder="Введите класс" onChange={(e) => newText(e.target.value)} />
                    <input id='registerInput' type="button" value="Добавить класс" onClick={addClass} />
                </label>
            }
            {
                subjectSel && <label>
                    <input id='registerInput' value={Subject} type="text" placeholder="Введите предмет" onChange={(e) => newSubject(e.target.value)} />
                    <input id='registerInput' type="button" value="Добавить предмет" onClick={addSubject} />
                </label>
            }
        </div>
    )
}