import { FC, useEffect, useState } from "react"
import { Users } from "../../../../../store/loginSlice"
import { CheckboxLeftRight } from "../../../setting/checkboxLeftRight"
import { useAppDispatch } from "../../../../../store/hook"
import { childAddClass, childAddClassType, childDeleteClass, childDeleteClass2 } from "../../../../../store/gradeSlice"

type SubsChildrenderType = {
    ch: any
    Userter: Users[]
    User: Users
    childCl: childAddClassType[]
    classCh: any
}

export const SubsChildrender: FC<SubsChildrenderType> = ({ ch, Userter, User, childCl, classCh }) => {
    let subsU = Userter.find((item: Users) => item.id === ch)
    let q = childCl.filter(i => i.child === ch && i.coach === User?.id && i.class_id === classCh)
    const dispatch = useAppDispatch();
    let j = false;
    if (q.length !== 0) {
        j = true
    } else {
        j = false
    }
    let child = ch
    let class_id = classCh;

    const [isChecked, setIsChecked] = useState(j);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
        !isChecked ? dispatch(childAddClass({ child, class_id })) : dispatch(childDeleteClass2({ child, class_id }))
    };
    return (
        <div >
            <CheckboxLeftRight isChecked={isChecked} handleCheckboxChange={handleCheckboxChange} /> <div>{subsU?.name} </div>
        </div>
    )
}