import Remove from "./page";
import { FC, useEffect, useState } from "react";
import { Users, removeUser, statusFalse } from "../../../store/loginSlice";
import { click } from "@testing-library/user-event/dist/click";
import { RootState } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hook";



const RemoveContiner: FC<any> = ({ outLog }) => {
  const dispatch = useAppDispatch();

  const sports = useAppSelector((state: RootState) => state.login.sport);
  const clubs = useAppSelector((state: RootState) => state.login.club);
  const userg: Users = useAppSelector((state: Users) => state.login.user);
  const userLoading: boolean = useAppSelector((state: any) => state.login.loading);
  
let dataUserCopy: Users = {};

 const [User, setUser] = useState(dataUserCopy);

 useEffect(() => {
  setUser({...dataUserCopy, ...userg})
 }, [userg]);

  // if (userg.status) {
  //   setInterval(() => dispatch(statusFalse()), 6000);
  // }

  

const updateLogin = (e: React.ChangeEvent<HTMLInputElement>, name: any) => {
  let data: Users = {...User}
  if (name === "name") {
    data.name = e.target.value
    setUser(data)
  }
  if (name === "login") {
    data.login = e.target.value
    setUser(data)
  }
  if (name === "tel") {
    data.tel = e.target.value
    setUser(data)
  }
  if (name === "email") {
    data.email = e.target.value
    setUser(data)
  }
  if (name === "birthday") {
    data.birthday = e.target.value
    setUser(data)
  }
};

  const removeProfile = () => {
    let name,
      login,
      tel,
      email,
      birthday

      if(User.name !== userg.name){
        name = User.name
      }
      if(User.login !== userg.login){
        login = User.login
      }
      if(User.tel !== userg.tel){
        tel = User.tel
      }
      if(User.email !== userg.email){
        email = User.email
      }
      if(User.birthday !== userg.birthday){
        birthday = User.birthday
      }
    dispatch(removeUser({ name, login, tel, email, birthday }));
  }
  return (
    <div>
      < Remove
        name={User.name}
        removeProfile={removeProfile}
        tel={User.tel}
        email={User.email}
        updateLogin={updateLogin}
        login={User.login}
        birthday={User.birthday}
        outLog={outLog}
        out={userg.out}
        fields={userg.fields}
        message={userg.message}
        error={userg.error}
      />
    </div>

  );
};

export default RemoveContiner;
