import React, { FC, useState } from 'react';
import { CheckboxLeftRight } from './checkboxLeftRight';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import "./style.css"

export const Ass: FC<any> = ({ userID }) => {
    return(
        <div></div>
    )
//     const dispatch = useAppDispatch();
//     const login = useAppSelector(state => state.login.log);
//     const sports = useAppSelector(state => state.login.sport);
//     const CountryStor = useAppSelector(state => state.login.country);
//     const CityStor = useAppSelector(state => state.login.city);
//     const RegionStor = useAppSelector(state => state.login.region);
//     const [type_association, setType_association] = useState(null);
//     const [sportsName, setSportsName] = useState('');
//     const [association_names, setAssociation_names] = useState('');
//     const [country, setCountry] = useState(0);
//     const [city, setCity] = useState(0);
//     const [region, setRegion] = useState(0);

//     let H = login.find(item => item.id === userID);

//     let rC = []
//     let cC = []
//     rC = RegionStor.filter(item => item.country === country)
//     cC = CityStor.filter(item => item.region === region)



//     let l = false
//     if (H?.position === 3) {
//         l = true
//     }
//     else if (H?.position !== 3) {
//         l = false
//     }
//     const [isChecked, setIsChecked] = useState(l);

//     const handleCheckboxChange = () => {
//         let checked = 0;
//         if (!isChecked) {
//             setIsChecked(true)
//             checked = 1;
//         }
//         else {
//             setIsChecked(false)
//             checked = 0;
//         }
//         dispatch(isAssociation({ checked }))
//     };
//     const saveAssoc = () => {
//         dispatch(AssociationSave({ type_association, sportsName, association_names, country, city, region }))
//     }

//     const selectAssociation = (e: any) => {
//         setType_association(e)
//     }

//     const updateCountry = (e: number) => {
//         setRegion(0)
//         setCity(0)
//         setCountry(e)
//     }

//     const updateCity = (e: number) => {
//         setCity(e)
//     }

//     const updateRegion = (e: number) => {
//         setCity(0)
//         setRegion(e)
//     }

//     const updateSport = (e: any) => {
//         setSportsName(e)
//     }




//     return (
//         <div>
//             <div className='teloForm'>
//                 <div className='ass'>
//                     <h2>Учебная ассоциация</h2>
//                     <CheckboxLeftRight isChecked={isChecked} handleCheckboxChange={handleCheckboxChange} />
//                 </div>
//                 {isChecked && <FormAssoc selectAssociation={selectAssociation} isSC={type_association} saveAssoc={saveAssoc} updateCountry={updateCountry} updateCity={updateCity} updateSport={updateSport} country={H?.country} sportsName={H?.sportsName} city={H?.city} sports={sports} CountryStor={CountryStor} CityStor={CityStor} RegionStor={RegionStor} rC={rC} region={H?.region} updateRegion={updateRegion} cC={cC} />}
//             </div >
//         </div>
//     )
// }



// export const SelectSport: FC<any> = ({ updateSport, sportsName, sports }) => {
//     let A = sports.find((item: any) => item.id === sportsName);
//     return (
//         <div>
//             <label>
//                 <h3>Вид спорта</h3>
//                 <h5>{A?.sports}</h5>
//                 <select onChange={(e) => updateSport(e.target.value)}>
//                     <option selected value="">не выбрано</option>
//                     {sports.map((s: any) => (
//                         <option value={s.id}>{s.sports}</option>
//                     ))}
//                 </select>
//             </label>
//         </div>
//     )
// }



// export const FormAssoc: FC<any> = ({ selectAssociation, isSC, saveAssoc, updateCountry, updateCity, updateSport, country, sportsName, city, sports, CountryStor, CityStor, RegionStor, rC, region, updateRegion, cC }) => {
//     let m = CountryStor.find((item: any) => item.id === country);
//     let z = CityStor.find((item: any) => item.id === city);
//     let r = RegionStor.find((item: any) => item.id === region);

//     return (
//         <div className='containerRegisterAssoc'>
//             <label>
//                 <h3>Вид ассоциаций</h3>
//                 <select onChange={(e) => selectAssociation(e.target.value)}>
//                     <option selected value="0">не выбрано</option>
//                     <option value="1">Спортивный клуб</option>
//                     <option value="2">Учебный центр</option>
//                 </select>
//             </label>
//             {isSC === "1" && <SelectSport updateSport={updateSport} sportsName={sportsName} sports={sports} />}
//             <label>
//                 <h3>Страна</h3>
//                 {m?.name && <h5>{m?.name}</h5>}
//                 <select onChange={(e) => updateCountry(parseInt(e.target.value))} >
//                     <option selected value="">не выбрано</option>
//                     {(CountryStor).map((c: any) => (
//                         <option value={c.id}>{c.name}</option>
//                     ))}
//                 </select>
//             </label>
//             <label>
//                 <h3>Регион</h3>
//                 {r?.name && <h5>{r?.name}</h5>}
//                 <select onChange={(e) => updateRegion(parseInt(e.target.value))}>
//                     <option selected value="">не выбрано</option>
//                     {rC.map((r: any) => (
//                         <option value={r.id}>{r.name}</option>
//                     ))}
//                 </select>
//             </label>
//             <label>
//                 <h3>Город</h3>
//                 {z?.name && <h5>г.{z?.name}</h5>}
//                 <select onChange={(e) => updateCity(parseInt(e.target.value))}>
//                     <option selected value="">не выбрано</option>
//                     {cC.map((ci: any) => (
//                         <option value={ci.id}>{ci.name}</option>
//                     ))}
//                 </select>
//             </label>
//             <label>
//                 <input type="button" value='Сохранить' onClick={saveAssoc} />
//             </label>
//         </div>
//     )
}










