import { useForm } from "react-hook-form";
import { Link, Navigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { RootState } from "../../../store";
import "./style.css";
import {
  Users,
  allLogin,
  errorSlice,
  meSlice,
  regStatusType,
} from "../../../store/loginSlice";

const Login: FC<any> = ({ signIn, registerOpen }) => {
  const dispatch = useAppDispatch();

  const login = useAppSelector((state: RootState) => state.login.user);
  const Token = useAppSelector((state) => state.login.Token);
  const UsersStatus = useAppSelector((state) => state.login.UsersStatus);
  const resError: regStatusType = useAppSelector(
    (state) => state.login.resError
  );
  const StateError: string = useAppSelector((state) => state.login.error);
  const l: Users[] = useAppSelector((state) => state.login.log);
  let f: Users = l.find((item: Users) => item?.id === resError?.name) || {};

  let Adam = {
    name: "",
    password: "",
  };

  const [names, setNames] = useState(Adam);

  useEffect(() => {
    dispatch(meSlice());
    dispatch(errorSlice());
  }, []);
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur",
  });

  if (UsersStatus === true && Token === true) {
    return <Navigate to={"/" + login?.login} />;
  }

  const updateSignin = (e: React.ChangeEvent<HTMLInputElement>, name: any) => {
    setNames((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };
  console.log({ resError });
  registerOpen();
  return (
    <div className="flex">
      <div className="Form">
        <form onSubmit={handleSubmit(signIn)}>
          <h1 className="red">Вход</h1>
          {resError?.name && (
            <div className="regGreen">
              Поздравляем {resError?.name}! Регистрация прошло успешно!
            </div>
          )}
          <label className="labelForm">
            {names?.name === "" ? (
              <div className="registerInputDiv"></div>
            ) : (
              <div className="registerInputDiv">
                Телефон, логин или эл. адрес
              </div>
            )}
            <input
              id="registerInput"
              placeholder="Телефон, логин или эл. адрес"
              type="text"
              {...register("login", {
                onChange: (e) => {
                  updateSignin(e, "name");
                },
                required: "Поле обязательно к заполнению",
                minLength: {
                  value: 2,
                  message: "Минимум 2 символа!",
                },
              })}
            />
          </label>
          {(errors as any)?.login && (
            <div className="regErr">
              <p className="red">
                {(errors as any)?.login?.message || "Error!"}
              </p>
              <p>{login?.login}</p>
            </div>
          )}

          <label className="labelForm">
            {names?.password === "" ? (
              <div className="registerInputDiv"></div>
            ) : (
              <div className="registerInputDiv">Введите пароль</div>
            )}
            <input
              id="registerInput"
              type="password"
              placeholder="Введите пароль"
              {...register("password", {
                onChange: (e) => {
                  updateSignin(e, "password");
                },
                required: "Поле обязательно к заполнению",
                minLength: {
                  value: 5,
                  message: "Минимум 5 символов!",
                },
              })}
            />
          </label>
          {(errors as any)?.password && (
            <div className="regErr">
              <p className="red">
                {(errors as any)?.password?.message || "Error!"}
              </p>
            </div>
          )}

          {StateError && <div className="red errorText">{StateError}</div>}
          <input
            type="submit"
            value="Отправить"
            id="submitReg"
            disabled={!isValid}
          />
          <div className="textDivs">
            <Link to="/register" id="texts">
              Если нет аккаунта Зарегистрируйтесь!
            </Link>
          </div>
          <div className="textDivs">
            <Link to="/recover" id="texts">
              Если забыли пароль
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;

//   <label>
//         <input className="input"
//           placeholder='Логин'
//           value={log}
//           onChange={(e) => updateLog(e.target.value)}
//         />
//         <input className="input"
//           placeholder='Пароль'
//           value={pas}
//           onChange={(e) => updatePas(e.target.value)}
//         />
//         <button onClick={signIn}>Вход</button>
//         <ul>
//           {login.map((logi) => (
//               <Logines
//               key={logi.id}
//               {...logi}
//               />
//           ))}
//         </ul>
//     </label>
