import { FC, useEffect, useState } from 'react'
import { GradeType, SubjectADD, classADD, classADDType } from '../../../../../../store/gradeSlice';
import { Assoc } from '../assoc';
import { Users } from '../../../../../../store/loginSlice';
import { RemoveChildrens } from '../removeChildrens';
import { AllGrades } from '../AllGrades';
import { Coach } from '../coach';
import { subscribeType } from '../../../../../../store/subscribeSlice';
import { SelectOptions } from '../selectOptions';
import { SlArrowDown } from "react-icons/sl";
import { useAppDispatch } from '../../../../../../store/hook';
import { GradeContainer } from '../showAllGrade/components/GradeContainer';

type MyGradeType = {
    User: Users
    cl: classADDType[]
    sj: classADDType[]
    grade: GradeType[]
    Userter: Users[]
    arrChildOrCoach_Assoc: string[]
    functionAssAndCoach: Function
    ass: any
    position: number
    MyCoachForChild: subscribeType[]
    coach: any
    pageData: string
    NewMonth?: any
    NewYear?: any
    year: any[]
    month: any[]
}

export const MyGrade: FC<MyGradeType> = ({ NewMonth, NewYear, MyCoachForChild, arrChildOrCoach_Assoc, User, cl, sj, grade, Userter, ass, coach, position, functionAssAndCoach, pageData, year, month, }) => {
    const [classCh, setClassCh] = useState(cl[0]?.id);
    const [subjectCh, setSubjectCh] = useState(sj[0]?.id);
    const [yearS, setYearS] = useState('');
    const [monthS, setMonthS] = useState('');

    const dispatch = useAppDispatch();
    const [title, setTitle] = useState('');

    const newText = (e: any) => {
        setTitle(e)
    }

    const addClass = () => {
        dispatch(classADD({ title }))
        setTitle('')
    }

    const [Subject, setSubject] = useState('');

    const newSubject = (e: any) => setSubject(e)

    const addSubject = () => {
        dispatch(SubjectADD({ Subject }))
        setSubject('')
    }

    useEffect(() => {
        setYearS(String(NewYear))
        setMonthS(String(NewMonth))
    }, []);

    useEffect(() => {
        setClassCh(cl[0]?.id)
        setSubjectCh(sj[0]?.id)
    }, [cl, sj]);

    const functionR = (status: any, a: any) => {
        if (status === "class") {
            setClassCh(a)
        }
        if (status === "subject") {
            setSubjectCh(a)
        }
        if (status === "year") {
            setYearS(a)
        }
        if (status === "month") {
            setMonthS(a)
        }
    }
    let Grades: GradeType[] = []
    let GradesChildId: GradeType[] = []

    for (let i = 0; i < grade.length; i++) {
        let dateParse = Date.parse(grade[i]?.created_at);
        let date = new Date(dateParse);

        let getYear = date.getFullYear()
        let sMonth = date.getMonth()

        if (grade[i].class_id === classCh && grade[i].subject_id === subjectCh && getYear === Number(yearS) && sMonth === Number(monthS) && grade[i].child === pageData) {
            Grades = [...Grades, grade[i]]
            GradesChildId = [...GradesChildId, grade[i].child]
        }
    }
    let GradesChild: any[] = [...new Set(GradesChildId)];

    const [shadow, setShadow] = useState(false);
    const [assocSel, setAssocSel] = useState(false);
    const [coachSel, setCoachSel] = useState(false);
    const [classSel, setClassSel] = useState(false);
    const [subjectSel, setSubjectSel] = useState(false);
    const [yearSel, setYearSel] = useState(false);
    const [monthSel, setMonthSel] = useState(false);
    const assocClick = () => {
        setShadow(true)
        setAssocSel(true)
    }
    const coachClick = () => {
        setShadow(true)
        setCoachSel(true)
    }
    const classClick = () => {
        setShadow(true)
        setClassSel(true)
    }
    const subjectClick = () => {
        setShadow(true)
        setSubjectSel(true)
    }
    const yearClick = () => {
        setShadow(true)
        setYearSel(true)
    }
    const monthClick = () => {
        setShadow(true)
        setMonthSel(true)
    }
    const shadowOf = () => {
        setShadow(false)
        setClassSel(false)
        setSubjectSel(false)
        setYearSel(false)
        setMonthSel(false)
        setAssocSel(false)
        setCoachSel(false)
    }

    let findAss: Users = Userter.find((item: Users) => item?.id === ass) || {}
    let findCoach: Users = Userter.find((item: Users) => item?.id === coach) || {}
    let findCl: classADDType = cl.find((item: classADDType) => item?.id === classCh) || {}
    let findSj: classADDType = sj.find((item: classADDType) => item?.id === subjectCh) || {}
    let findMonth: classADDType = month.find((item: classADDType) => item?.id === monthS) || {}
    return (
        <div className="showAllGrade">
            {shadow && <div onClick={shadowOf} className="settingAbsolut"></div>}
            {assocSel && shadow && <SelectOptions shadowOf={shadowOf} pageData={pageData} Userter={Userter} User={User} status={"assoc"} assocSel={assocSel} arr={arrChildOrCoach_Assoc} hide={false} functionR={functionAssAndCoach} R={ass} />}
            {coachSel && shadow && <SelectOptions shadowOf={shadowOf} myGradeCoach={true} pageData={pageData} Userter={Userter} User={User} status={"coach"} coachSel={coachSel} arr={MyCoachForChild} hide={false} functionR={functionAssAndCoach} R={coach} />}
            {classSel && shadow && <SelectOptions Subject={Subject} title={title} addClass={addClass} addSubject={addSubject} newText={newText} newSubject={newSubject} shadowOf={shadowOf} pageData={pageData} User={User} classSel={classSel} status={"class"} arr={cl} hide={false} functionR={functionR} R={classCh} />}
            {subjectSel && shadow && <SelectOptions Subject={Subject} title={title} addClass={addClass} addSubject={addSubject} newText={newText} newSubject={newSubject} shadowOf={shadowOf} pageData={pageData} User={User} status={"subject"} subjectSel={subjectSel} arr={sj} hide={false} functionR={functionR} R={subjectCh} />}
            {yearSel && shadow && <SelectOptions shadowOf={shadowOf} pageData={pageData} User={User} status={"year"} yearSel={yearSel} arr={year} hide={false} functionR={functionR} R={yearS} />}
            {monthSel && shadow && <SelectOptions shadowOf={shadowOf} pageData={pageData} User={User} status={"month"} monthSel={monthSel} arr={month} hide={false} functionR={functionR} R={monthS} />}
            <div className="selectGrade">
                {position !== 3 && < div className="classSubject" onClick={assocClick}>
                    <h3>Ассоциация: {findAss?.login}</h3>
                    <SlArrowDown id="SlArrowDown" />
                </div>}
                {position !== 2 && < div className="classSubject" onClick={coachClick}>
                    <h3>Учителья: {findCoach?.login}</h3>
                    <SlArrowDown id="SlArrowDown" />
                </div>}
                {<div className="classSubject" onClick={classClick}>
                    <h3>Классы: {findCl?.title}</h3>
                    <SlArrowDown id="SlArrowDown" />
                </div>}
                {<div className="classSubject" onClick={subjectClick}>
                    <h3>Предметы: {findSj?.title}</h3>
                    <SlArrowDown id="SlArrowDown" />
                </div>}
                <div className="classSubject" onClick={yearClick}>
                    <h3>Год: {yearS}</h3>
                    <SlArrowDown id="SlArrowDown" />
                </div>
                <div className="classSubject" onClick={monthClick}>
                    <h3>Месяц: {findMonth?.title}</h3>
                    <SlArrowDown id="SlArrowDown" />
                </div>
            </div>
            {
                    <GradeContainer GradesChild={GradesChild} Userter={Userter} Grades={Grades} grade={grade} classCh={classCh} subjectCh={subjectCh}  />
            }

        </div >
    )
}