import { FC } from 'react'
import { subscribeType } from '../../../../store/subscribeSlice'
import { Users } from '../../../../store/loginSlice'
import { SubsMap } from './subsMap'
import { GradeType } from '../../../../store/gradeSlice'

type SubscribeShowType = {
    subs: subscribeType[] | number[]
    Userter: Users[]
    showAssoc?: boolean
    showSubsPage?: boolean
    showAllCoachChild?: boolean
    showAllCoach?: boolean
    showSubs?: boolean
    position?: number
    gradeAll?: GradeType[]
    pageData?: string
}

export const SubscribeShow: FC<SubscribeShowType> = ({ subs, Userter, showSubsPage, showAllCoachChild, showAllCoach, showAssoc, showSubs, position, gradeAll, pageData }) => {

    return (
        <div className="SettingFon">
            <div className='AllSubs'>
                {subs.map((ss) => (
                    <SubsMap ss={ss} gradeAll={gradeAll} pageData={pageData} Userter={Userter} showSubsPage={showSubsPage} showAssoc={showAssoc} showAllCoachChild={showAllCoachChild} showAllCoach={showAllCoach} showSubs={showSubs} position={position} />
                ))}
            </div>
        </div>
    )
}