import { FC } from "react"
import { SubsChildrender } from "./subsChildrender"

export const ShowChildren: FC<any> = ({ addChild, subsCh, Userter, User, childCl, classCh }) => {

    return (
        <div>
            <div className="selectMyChild" onClick={addChild} ></div>
            <div className="selChild">
                {subsCh.map((ch: any) => (
                    <SubsChildrender ch={ch} Userter={Userter} User={User} childCl={childCl} classCh={classCh} />
                ))}
            </div>
        </div>
    )
}