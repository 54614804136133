import Home from "./home";
import Login from "./signin/page";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Register from "./register/page";
import Profile from "./profile/page";
import { useAppSelector } from "../../store/hook";
import RemoveContiner from "./remove/RemoveContiner";
import Recover from "./recover";
import HashPass from "./hashpassword";
import PasswordContainer from "./remove/passContainer";
import "./header.css";
import "../style/loading.css";
import { IoExitOutline } from "react-icons/io5";
// import Massenger from "./header/massenger";
import { ImHome, ImSearch } from "react-icons/im";
import { BiMessageSquareDetail } from "react-icons/bi";
import { BsShop } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { Homework } from "./profile/homeWork/page";
import { CreateChampionships } from "./champinship/createChampionships";
import { SettingProfile } from "./setting/settingProfile";
import { Ass } from "./setting/ass";
import { ChampResult } from "./champinship/champResult/page";
import { Participant } from "./champinship/champResult/participant/page";
import React, { FC, ReactElement } from "react";
import { Users } from "../../store/loginSlice";
import { stateSettingType } from "../../store/settingSlice";
import { RootState } from "../../store";
import Search from "./search/page";
import { Massenger } from "./Messanger/page";
import { NoPageFound } from "./components/noPageFound";
import { SettingMyChamp } from "./champinship/champResult/settingMyChamp/page";
import { subscribeType } from "../../store/subscribeSlice";
import { AdminChamp } from "./champinship/champResult/components/AdminChamp";
import ResetPassword from "./resetPassword/page";
import Loader from "./loader";
import { ChampResultCopy } from "./champinship/champResult/ChampResultCopy";

type headerType = {
  signIn: any;
  signUp: any;
  uploader: any;
  pageId: any;
  newName: any;
  updateNewName: any;
  userId: any;
  valueMass: any;
  updateMass: any;
  recover: any;
  hashPass: any;
  nullEmail: any;
  outLog: any;
  registerOpen: any;
};

const Header: FC<headerType> = ({
  signIn,
  signUp,
  recover,
  updateNewName,
  userId,
  hashPass,
  nullEmail,
  uploader,
  outLog,
  updateMass,
  valueMass,
  pageId,
  newName,
  registerOpen,
}) => {
  const user = useAppSelector((state: RootState) => state.login.user);
  const champ = useAppSelector(
    (state: RootState) => state.setting.championships
  );
  const KG = useAppSelector((state: RootState) => state.setting.kg);
  const Part = useAppSelector((state) => state.setting.participant);
  const log = useAppSelector((state) => state.login.log);
  const Token = useAppSelector((state) => state.login.Token);
  const UsersStatus = useAppSelector((state) => state.login.UsersStatus);
  const loading = useAppSelector((state) => state.login.loading);

  const subscribe = useAppSelector((state) => state.subscribe.subscribe);
  let subsAllCoachChild: subscribeType[] = [];
  subsAllCoachChild = subscribe.filter(
    (item: subscribeType) =>
      item?.follower_id === user?.id && item?.approved === 1
  );
  return (
    <div className="name">
      {loading && (
        <div id="loading">
          <Loader id="loadingTSX" />
        </div>
      )}
      {Token && (
        <header className="header" id="header">
          <ul className="App-header">
            {/* <li>
              <Link className="headers" to="/sport">
                <ImHome />
              </Link>
            </li> */}
            <li className="headerMob">
              <Link className="headers" to="/search">
                <ImSearch />
              </Link>
            </li>
            {/* <li className="headerMob">
              <Link className="headers" to="/coach">
                <BiMessageSquareDetail />
              </Link>
            </li> */}
            {/* <li className="headerMob">
              <Link className="headers" to="/sss">
                <BsShop />
              </Link>
            </li> */}
            <li className="headerMob">
              <Link
                className="headers"
                to={"" + user?.login ? user?.login : "signin"}
              >
                <CgProfile />
              </Link>
            </li>
            {/* {profmap.map((prof) => (
                <li>
                    <Link to={'/' + prof.login}>{prof.login}</Link>
                </li>
                ))} */}
          </ul>
        </header>
      )}

      <Routes>
        <Route path="/" element={<Home login={user.login} />}></Route>
        <Route
          path="/signin"
          element={<Login signIn={signIn} registerOpen={registerOpen} />}
        ></Route>
        <Route
          path="/remove"
          element={<RemoveContiner outLog={outLog} />}
        ></Route>
        <Route path="/recover" element={<Recover recover={recover} />}></Route>
        <Route
          path="/hash_Password"
          element={<HashPass hashPass={hashPass} nullEmail={nullEmail} />}
        ></Route>
        <Route path="/register" element={<Register signUp={signUp} />}></Route>
        <Route path="/password" element={<PasswordContainer />}></Route>
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route
          path="/CreateChampionships"
          element={<CreateChampionships login={user?.login} />}
        ></Route>
        <Route path="/settingProfile" element={<SettingProfile />}></Route>
        <Route path="/search" element={<Search log={log} />}></Route>
        <Route
          path="/settingProfile/ass"
          element={<Ass userID={user.id} />}
        ></Route>
        <Route
          path="/settingChampionship"
          element={<SettingMyChamp userID={user.id} />}
        ></Route>
        <Route
          path="/AdministratorControl"
          element={<AdminChamp userID={user.id} log={log} />}
        ></Route>
        {log.map((prof) => (
          <Route
            path={"/massenger/" + prof.id}
            element={
              <Massenger
                profmap={log}
                // getMassenger={getMassenger}
                // otpravit={otpravit}
                updateMass={updateMass}
                valueMass={valueMass}
                pageId={pageId}
                // userID={user.id}
                {...prof}
                key={prof.id}
              />
            }
          ></Route>
        ))}
        {log?.map((prof: Users) => (
          <Route
            path={"/" + prof.login}
            element={
              <Profile
                profmap={log}
                uploader={uploader}
                userLogin={user.login}
                userID={user.id}
                userThisCoach={user.thisCoach}
                key={prof.id}
                prof={prof}
              />
            }
          ></Route>
        ))}
        {champ?.map((ch: any) => (
          <Route
            path={"/championship/" + ch.id}
            element={
              <ChampResult
                subsAllCoachChild={subsAllCoachChild}
                user={user}
                login={log}
                ch={ch}
              />
            }
          ></Route>
        ))}
        <Route path="/championship/*" element={<ChampResultCopy />} />

        {/* {champ?.map((ch: any) => (
                    KG?.map((k: any) => (
                        <Route path={'/' + ch.id + "/" + k.id} element={< Participant
                            KG={k}
                            Part={Part}
                            idChamp={ch.id}
                            />}></Route>
                            ))
                            ))} */}
        {UsersStatus === true && (
          <Route path="*" element={<NoPageFound Token={Token} />} />
        )}
      </Routes>
    </div>
  );
};

export default Header;
