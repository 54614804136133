import { FC, useEffect, useMemo, useState } from "react"
import "./style.css"
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { exerChildGet, exerChildPageOpen, exercisesADD, exercisesCoachType, exercisesDelete, exercisesGet, exercisesPageOpen } from "../../../../store/exercisesSlice";
import { Users } from "../../../../store/loginSlice";
import { ExercisesAll } from "./components/exercisesAll";
import { ChildExercises } from "./components/childExercises";
import { subscribeType } from "../../../../store/subscribeSlice";
import { CheckboxLeftRight } from "../../setting/checkboxLeftRight";
import { AssocExer } from "./components/AssocExer";

type HomeWorkType = {
    position: number
    pageData: string
    Userter: Users[]
    User: Users
    subsAllCoachChild: subscribeType[]
    arrChildCoachAssoc_id: string[]
    MyCoachForChild: subscribeType[]
    MyAssocForCoach: subscribeType[]
}

export const Homework: FC<HomeWorkType> = ({ position, subsAllCoachChild, pageData, Userter, User, arrChildCoachAssoc_id, MyCoachForChild, MyAssocForCoach }) => {
    const dispatch = useAppDispatch();
    let exercisesCoach: exercisesCoachType[] = useAppSelector(state => state.exercisess.exercisesCoach);
    const stateOpenPageExerCoach = useAppSelector(state => state.exercisess.stateOpenPageExerCoach);
    const findDuplicates = (arr: any) => arr.filter((item: any, index: any) => arr.indexOf(item) === index)

    let CA = useMemo(() => {
        let CoachAndAssoc: any[] = []
        if (position === 1) {
            for (let i = 0; i < MyCoachForChild.length; i++) {
                CoachAndAssoc = [...CoachAndAssoc, MyCoachForChild[i]?.follower_id]
            }
            CoachAndAssoc = [...CoachAndAssoc, ...arrChildCoachAssoc_id]
        }
        if (position === 2) {
            for (let i = 0; i < MyAssocForCoach.length; i++) {
                CoachAndAssoc = [...CoachAndAssoc, MyAssocForCoach[i]?.follower_id]
            }
        }
        CoachAndAssoc = [...CoachAndAssoc, pageData]
        return CoachAndAssoc
    }, [MyCoachForChild, arrChildCoachAssoc_id]);

    CA = findDuplicates(CA)

    useEffect(() => {
        for (let index = 0; index < CA.length; index++) {
            let creator = CA[index];
            let fE: any[] = stateOpenPageExerCoach.filter((item: any) => item === creator)
            if (fE.length === 0) {
                dispatch(exercisesGet({ creator }));
                dispatch(exercisesPageOpen({ creator }));
            }
        }
    }, [stateOpenPageExerCoach, pageData]);

    const exerChildPage = useAppSelector(state => state.exercisess.stateOpenPageExerChild);
    useEffect(() => {
        let f: string[] = exerChildPage.filter((item: string) => item === pageData)
        if (f.length === 0) {
            let doing = pageData
            let id = pageData
            dispatch(exerChildGet({ doing }))
            dispatch(exerChildPageOpen({ id }))
        }
    }, []);

    const [name, setText] = useState('');

    const updateText = (e: string) => {
        setText(e)
    }

    const [max_number, setMaxSquats] = useState(0);
    const updateSquats = (e: string) => {
        setMaxSquats(parseInt(e, 10)); // Парсим как число в десятичной системе, чтобы избежать неожиданного поведения
    }
   let numberCopy = Number(max_number) * 2 / 2
   

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }
    const btnSave = () => {
        let forChild = 0
        if (isChecked === true) {
            forChild = 1
        }else{
            forChild = 0
        }
        dispatch(exercisesADD({ name, max_number, forChild }))
        setText('')
        setMaxSquats(0)
        setIsChecked(false)
    }
    let h: exercisesCoachType[] = [...exercisesCoach]
    h.reverse()
    let isValid = true
    if (name === '' || max_number === 0) {
        isValid = true
    } else {
        isValid = false
    }

    const [exer, setExer] = useState(true);
    const [childAll, setChildAll] = useState(false);

    const clickExer = () => {
        setExer(true)
        setChildAll(false)
    }
    const clickChild = () => {
        setExer(false)
        setChildAll(true)
    }
    const clickMyExer = () => {
        setExer(false)
        setChildAll(false)
    }
    
    let coachAssocFilter = useMemo(() => {
        let CAF: any[] = [];
        for (let i = 0; i < CA.length; i++) {
            for (let v = 0; v < exercisesCoach.length; v++) {
                if(CA[i] === exercisesCoach[v].creator &&  exercisesCoach[v].forChild === 0){
                    CAF = [...CAF, CA[i]]
                }
            }
        }
        const unique = CAF.filter((item: any, index: any) => CAF.indexOf(item) === index);
        return unique
    }, [exercisesCoach, CA]);

    return (
        <>
            <ul className="coachUl">
                <li className={exer ? "clickLI" : ""} onClick={clickExer}>Все задание</li>
                {(User?.position === 2 || User?.position === 3) && pageData === User?.id && <li className={childAll ? "clickLI" : ""} onClick={clickChild}>Дети</li>}
            </ul>
            {(pageData === User?.id) && exer && < div className='addExercist'>
                {<div className='addExer'>
                    <h3>Добавить задания</h3>
                    <input type="text" placeholder="Названия" value={name} onChange={(e) => updateText(e.target.value)} />
                    <input type="number" placeholder="Максимальное количество в день" value={max_number !== 0 ? max_number.toString() : ""} onChange={(e) => updateSquats(e.target.value)} />
                    {(User?.position === 2 || User?.position === 3) && <div className='continerRadioExer'>
                        <div className='radioExer'>
                            <div>Для детей</div>
                            <CheckboxLeftRight isChecked={isChecked} handleCheckboxChange={handleCheckboxChange} />
                        </div>
                    </div>}
                    <input type="button" disabled={isValid} value="Сохранить" onClick={btnSave} />
                </div>}
                {/* {h.length > 0 && <h3 id='h3Exer'>Все задание</h3>}
                {h.map((ex) => (
                    <ExercisesAll User={User} ex={ex} />
                ))} */}
            </div >}
            {exer && coachAssocFilter.map((a) => (
                <AssocExer exercisesCoach={exercisesCoach} a={a} User={User} Userter={Userter} pageData={pageData} />
            ))}

            {childAll && <div>
                {subsAllCoachChild.map((o) => (
                    <ChildExercises o={o?.subscribed} Userter={Userter} />
                ))}
            </div>}
        </>
    )
}
// const [foto, setFoto] = useState('');
// const [img, setImg] = useState('');

// function uploadLogo(e: React.ChangeEvent<any>) {
//     let file = e.target.files[0];
//     if (file) {
//         setFoto(file)
//         const selectedFile = file;
//         const reader = new FileReader();
//         reader.readAsDataURL(selectedFile);
//         reader.addEventListener('load', (event: any) => {
//             let img = event.target.result;
//             setImg(img)
//         });

//     }
// }
// <div className='fotoExer'>
//     <div className='fotoExerName'>Добавьте картинку задания (не обязательно)</div>
//     {img && <div className="ava"><img className="avaImg" src={img} alt="" /></div>}
//     <input accept="image/*" type="file" onChange={(e) => uploadLogo(e)} />
// </div>