import { FC } from 'react'
import { exercisesChildType } from '../../../../../store/exercisesSlice'
import { number } from 'yup'
type StaticType = {
    eM: exercisesChildType
    maxSquats: number
    i: number
    h: exercisesChildType[]
}

export const Static: FC<StaticType> = ({ eM, maxSquats, i, h }) => {
    let dateParse = Date.parse(eM?.created_at);
    let oldDate = new Date(dateParse)
    let oldDay = oldDate.getDate()
    let old7 = oldDate.getDay()
    let oldMonth = oldDate.getMonth()
    let oldYear = oldDate.getFullYear()
    let ur7: string = ''
    let g: number = Number(old7)
    let nI = i + 1
    let next = h[nI]

    if (g === 1) {
        ur7 = "пн"
    }
    if (g === 2) {
        ur7 = "вт"
    }
    if (g === 3) {
        ur7 = "ср"
    }
    if (g === 4) {
        ur7 = "чт"
    }
    if (g === 5) {
        ur7 = "пц"
    }
    if (g === 6) {
        ur7 = "сб"
    }
    if (g === 0) {
        ur7 = "вс"
    }
    let pN = (Number(next?.quantity) * 100) / Number(maxSquats)
    let prNext = pN + 'px'
    let p = (Number(eM?.quantity) * 100) / Number(maxSquats)
    let pr = p + 'px'

    let yDiv = Math.abs(p - pN)
    let xDiv = 26
    let Gepotenuza = (yDiv * yDiv) + (xDiv * xDiv)
    Gepotenuza = Math.sqrt(Gepotenuza)
    let GepoH = "1px"
    let GepoW = "1px"
    let sinus = 0
    // из радияны в градус
    let BigP = ""
    let top = ""
    let bottom = ""

    if (p > pN) {
        GepoW = Gepotenuza + 'px'
        sinus = yDiv / Gepotenuza
        BigP = "top left"
        top = "50%"
    }
    if (p < pN) {
        GepoW = Gepotenuza + 'px'
        sinus = yDiv / Gepotenuza
        BigP = "bottom left"
        bottom = "50%"
    }
    if (p === pN) {
        GepoW = Gepotenuza + 'px'
        BigP = "top left"
        top = "50%"
    }
    let oo = Math.acos(sinus)
    let ACos = (oo * 180) / Math.PI
    ACos = 90 - ACos
    if (p < pN) {
        ACos = -ACos
        // ACos = ACos + 180
        // ACos = 90 - ACos
    }

    // из  градуса найти радияну
    // let ACos = (Math.PI * cos) / 180


    // let gf = "rotate(" + ACos + ")"
    // let sin = Math.sin(ACos)
    return (
        <div>
            <div id='staticBlock'>
                <div id='yStatic'>
                    <div id='yBlock' style={{ height: pr }}></div>
                    <div id='squatsResult'>
                        <div id='lineSquats' style={{ transform: 'rotate(' + ACos + 'deg)', height: GepoH, width: GepoW, transformOrigin: BigP, bottom: bottom, top: top }} ></div>
                        <div id="char"><div id='squatsNumber'>{eM?.quantity}</div></div>

                    </div>
                </div>
                <div id='xBlock'>
                    <div className="ur7">{ur7}</div>
                    <div>{oldDay}</div>
                </div>
            </div>
        </div >
    )
}