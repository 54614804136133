import React, { FC } from "react";

const Loader: FC<any> = () => {
  return (
    <svg
      viewBox="0 0 24 8"
      xmlns="http://www.w3.org/2000/svg"
      width="24%"
      height="24%"
      fill="none"
      style={{
        backgroundColor: "transparent",
        borderRadius: "8px",
        fill: "white",
      }} // Фон SVG
    >
      <style>
        {`
              @keyframes loader-18 {
                0% {
                  transform: scale(1) rotateZ(0);
                }
                50% {
                  transform: scale(1.5) rotateZ(180deg);
                }
                100% {
                  transform: scale(1) rotateZ(360deg);
                }
              }
            `}
      </style>
      <g
        style={{
          animation: "loader-18 1s ease-out infinite both",
          transformOrigin: "4px",
          animationDelay: "0s",
        }}
      >
        <circle cx="4" cy="4" r="1.5" fill="red" />
      </g>
      <g
        style={{
          animation: "loader-18 1s ease-out infinite both",
          transformOrigin: "11px",
          animationDelay: ".3s",
        }}
      >
        <circle cx="11" cy="4" r="1.5" fill="green" />
      </g>
      <g
        style={{
          animation: "loader-18 1s ease-out infinite both",
          transformOrigin: "18px",
          animationDelay: ".6s",
        }}
      >
        <circle cx="18" cy="4" r="1.5" fill="blue" />
      </g>
    </svg>
  );
};

export default Loader;
