import { FC, useState } from "react"
import { Users } from "../../../../../../store/loginSlice"
import { Star } from "../Star"
import { DeleteGrade, GradeType, childDeleteClass } from "../../../../../../store/gradeSlice"
import { useAppSelector } from "../../../../../../store/hook"
import { subscribeType } from "../../../../../../store/subscribeSlice"
import { StarState } from "../starState"
import { AiTwotoneDelete } from "react-icons/ai"

export const ChildrenGrade: FC<any> = ({ functionStar, MyChildFilter, subsCh, functionStarState, grade, children, users, coach, gradeFunction, class_id, subject, dispatch, subscribed }) => {
    let User: Users = users.find((i: Users) => i.id === children?.child)
    let user = User?.id
    let coachSubsF: subscribeType[] = MyChildFilter.filter((item: subscribeType) => item.subscribed === User?.id)
    let ch: string[] = subsCh.filter((item: string) => item === User?.id)

    let filterGrade: GradeType[] = grade.filter((it: GradeType) => it?.class_id === class_id && it?.subject_id === subject && it?.child === user && it?.coach === subscribed)
    
    let li = filterGrade.length - 1;
    let id = filterGrade[li]?.id

    let NewDate = new Date();
    let NewDay = NewDate.getDate();
    let NewYear = NewDate.getFullYear();
    let NewMonth = NewDate.getMonth();
    let NewDataGrade: any[] = []

    for (let i = 0; i < filterGrade.length; i++) {
        let dateParse = Date.parse(filterGrade[i]?.created_at);
        let date = new Date(dateParse);

        let day = date.getDate();
        let year = date.getFullYear();
        let month = date.getMonth();
        
        if (day === NewDay && month === NewMonth && year === NewYear) {
            NewDataGrade = [...NewDataGrade, filterGrade[i]]
        }
    }
    const childDelete = () => {
        let id = children?.id
        dispatch(childDeleteClass({ id }))
    }

    const clickDelete = () => {
        if (id) {
            dispatch(DeleteGrade({ id }))
        }
    }
    
    return (
        <div className="childrenGrade">
            <div className="chGradeName">
                {coachSubsF.length > 0 && <div className="GrenCoach">{User?.name}</div>}
                {ch.length > 0 && <div>{User?.name}</div>}
                {(coachSubsF.length === 0 && ch.length === 0) && <div className="redChildClass">{User?.name}</div>}
                <AiTwotoneDelete className="TwotoneDelete" onClick={childDelete} />
            </div>
            <div>
                {coachSubsF.length > 0 || ch.length > 0 ? (NewDataGrade.length === 0 ? <Star functionStar={functionStar} class_id={class_id} subject={subject} user={user} /> : <StarState NewDataGrade={NewDataGrade} functionStarState={functionStarState} id={id} li={li} class_id={class_id} subject={subject} user={user} grade={grade} subscribed={subscribed} NewDay={NewDay} NewYear={NewYear} NewMonth={NewMonth} />) : <div></div>}
            </div>
            <div>
                {coachSubsF.length > 0 || ch.length > 0 ? <input type="button" value="Очистить" onClick={clickDelete} /> : <div></div>}
            </div>
        </div>
    )
}