import { FC, useEffect, useMemo, useState } from "react";
import { ParticipantSelect } from "../../../profile/participantSelect";
import { SetkaContainer } from "./createGrid/setkaContainer";
import { Grid } from "./grid/page";
import {
  KGType,
  createChampType,
  createKTGType,
  participantType,
  subCategoryType,
} from "../../../../../store/settingSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hook";
import { Users, city, country, region } from "../../../../../store/loginSlice";
import { subscribeType } from "../../../../../store/subscribeSlice";
import {
  AddAdmissionAdmins,
  AdminType,
  AdmissionAdminsType,
  DeleteAdmissionAdmins,
  SType,
  getSetka,
} from "../../../../../store/setkaSlice";
import { url } from "../../../../../store/url";
import "./style.css";
import { ImCheckmark, ImCross } from "react-icons/im";
import KZ from "../../../kz";
import { Link } from "react-router-dom";
let Api = url();

type PartType = {
  allSetka: SType[];
  AdmissionAdmin: AdmissionAdminsType[];
  user: Users;
  ch: createChampType;
  gender: number;
  categoryABC: string; //useState
  championship_category_id: number;
  championship_sub_category_id: number;
  Part: participantType[];
  championship_id: number;
  subsAllCoachChild: subscribeType[];
};

export const Participant: FC<PartType> = ({
  allSetka,
  AdmissionAdmin,
  ch,
  user,
  gender,
  Part,
  categoryABC,
  championship_id,
  championship_category_id,
  championship_sub_category_id,
  subsAllCoachChild,
}) => {
  const championshipCategory = useAppSelector(
    (state) => state.setting.championshipCategory
  );
  const championshipSubCategory = useAppSelector(
    (state) => state.setting.championshipSubCategory
  );
  let fCategory: createKTGType =
    championshipCategory.find(
      (item: any) => item?.id === championship_category_id
    ) || {};
  let fSubCategory: subCategoryType =
    championshipSubCategory.find(
      (item: any) => item?.id === championship_sub_category_id
    ) || {};
  let date = new Date();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSetka({ championship_id }));
  }, []);

  let childPart = Part?.filter(
    (item: participantType) =>
      item.championship_id === championship_id &&
      item.championship_category_id === championship_category_id &&
      item.categoryABC === categoryABC &&
      item.gender === gender &&
      item.championship_sub_category_id === championship_sub_category_id
  );

  let allowChildPart: participantType[] = useMemo(() => {
    let a: participantType[] = [];
    for (let iP = 0; iP < childPart.length; iP++) {
      for (let iAdm = 0; iAdm < AdmissionAdmin.length; iAdm++) {
        if (
          childPart[iP]?.championship_id ===
            AdmissionAdmin[iAdm]?.championship_id &&
          childPart[iP]?.child === AdmissionAdmin[iAdm]?.child
        ) {
          a = [...a, childPart[iP]];
        }
      }
    }
    return a;
  }, [childPart, AdmissionAdmin]);

  let l = 0;
  for (let sl = 1; sl <= 16384; sl *= 2) {
    let sj = sl * 2;
    if (allowChildPart?.length > sl && allowChildPart.length <= sj) {
      l = sj; // 4, 8, 16, 32, 64
    }
  }
  const login = useAppSelector((state) => state.login.log);
  let allGridPart: SType[] = [];
  for (let s = 0; s < allSetka.length; s++) {
    for (let p = 0; p < Part.length; p++) {
      if (
        allSetka[s].championship_participant_id === Part[p]?.id &&
        Part[p]?.categoryABC === categoryABC &&
        Part[p]?.championship_category_id === championship_category_id &&
        Part[p].championship_sub_category_id === championship_sub_category_id
      ) {
        allGridPart = [...allGridPart, allSetka[s]];
      }
    }
  }
  // let sChild = login.filter(item => item.coach === user?.id && item.kg === KG.kg && (item.year * 1) <= year1 && (item.year * 1) >= year2)
  const [showMyCh, setShowMyCh] = useState(false);
  const addMyChild = () => {
    setShowMyCh(!showMyCh);
  };
  const [head, setHead] = useState(1);
  const PartClick = () => {
    setHead(1);
  };
  const showSetkaClick = () => {
    setHead(2);
  };
  const creatSetkaClick = () => {
    setHead(3);
  };
  useEffect(() => {
    setHead(1);
  }, [championship_sub_category_id]);

  const admin = useAppSelector((state) => state.setka.admins);
  let fAdmin: AdminType[] = admin.filter(
    (item: AdminType) =>
      item?.admin === user?.id && item?.creator === ch?.creator
  );

  return (
    <div className="bodyParticipant">
      {showMyCh && (
        <SelectMyChild
          gender={gender}
          Part={Part}
          championship_id={championship_id}
          login={login}
          user={user}
          subsAllCoachChild={subsAllCoachChild}
          addMyChild={addMyChild}
          categoryABC={categoryABC}
          championship_sub_category_id={championship_sub_category_id}
          championship_category_id={championship_category_id}
        />
      )}
      {categoryABC &&
      championship_category_id &&
      championship_sub_category_id ? (
        <SelectHeader
          ch={ch}
          user={user}
          fCategory={fCategory}
          fSubCategory={fSubCategory}
          childPart={childPart}
          categoryABC={categoryABC}
          date={date}
          PartClick={PartClick}
          creatSetkaClick={creatSetkaClick}
          showSetkaClick={showSetkaClick}
        />
      ) : (
        <div></div>
      )}
      {categoryABC &&
      championship_category_id &&
      championship_sub_category_id &&
      head === 1 ? (
        <Parti
          AdmissionAdmin={AdmissionAdmin}
          fAdmin={fAdmin}
          ch={ch}
          user={user}
          childPart={childPart}
          login={login}
          addMyChild={addMyChild}
        />
      ) : (
        <div></div>
      )}
      {categoryABC &&
      championship_category_id &&
      championship_sub_category_id &&
      head === 2 ? (
        <Grid
          fAdmin={fAdmin}
          AdmissionAdmin={AdmissionAdmin}
          user={user}
          champ={ch}
          childPart={allowChildPart}
          login={login}
          allGridPart={allGridPart}
        />
      ) : (
        <div></div>
      )}
      {categoryABC &&
      championship_category_id &&
      championship_sub_category_id &&
      head === 3 ? (
        <SetkaContainer
          fAdmin={fAdmin}
          AdmissionAdmin={AdmissionAdmin}
          childPart={allowChildPart}
          categoryABC={categoryABC}
          login={login}
          creator={user.id}
          championship_id={championship_id}
          allGridPart={allGridPart}
          l={l}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

type SelectHeaderType = {
  ch: createChampType;
  user: Users;
  fCategory: createKTGType;
  fSubCategory: subCategoryType;
  categoryABC: string;
  date: any;
  PartClick: any;
  creatSetkaClick: any;
  showSetkaClick: any;
  childPart: any;
};

export const SelectHeader: FC<SelectHeaderType> = ({
  ch,
  user,
  fCategory,
  fSubCategory,
  categoryABC,
  date,
  PartClick,
  creatSetkaClick,
  showSetkaClick,
  childPart,
}) => {
  return (
    <div>
      <div className="headYearKg">
        <div>{fCategory?.name}</div>
        <div>{fSubCategory?.name}</div>
      </div>
      <div className="SelectHeader">
        <div onClick={PartClick}>Участники</div>
        <div onClick={showSetkaClick}>Сетки</div>
        {ch?.creator === user?.id && (
          <div onClick={creatSetkaClick}>Создать сетку</div>
        )}
      </div>
    </div>
  );
};

const Parti: FC<any> = ({
  AdmissionAdmin,
  fAdmin,
  ch,
  childPart,
  login,
  addMyChild,
  user,
}) => {
  return (
    <div>
      <h2>Участники</h2>
      {user?.position === 2 && (
        <input
          type="button"
          value="Добавить участьников"
          onClick={addMyChild}
        />
      )}
      <div className="ChildParticipant">
        {childPart.map((P: any) => (
          <ChildParticipant
            user={user}
            fAdmin={fAdmin}
            AdmissionAdmin={AdmissionAdmin}
            ch={ch}
            P={P}
            login={login}
          />
        ))}
      </div>
    </div>
  );
};

type SelectMyChildType = {
  Part: participantType[];
  user: any;
  login: any;
  subsAllCoachChild: any;
  addMyChild: any;
  categoryABC: any;
  championship_category_id: any;
  championship_id: any;
  championship_sub_category_id: any;
  gender: number;
};

const SelectMyChild: FC<SelectMyChildType> = ({
  gender,
  Part,
  user,
  login,
  subsAllCoachChild,
  addMyChild,
  categoryABC,
  championship_category_id,
  championship_id,
  championship_sub_category_id,
}) => {
  return (
    <div>
      <div className="selectMyChild" onClick={addMyChild}></div>
      <div className="selChild">
        {subsAllCoachChild.map((s: any) => (
          <ParticipantSelect
            gender={gender}
            Part={Part}
            login={login}
            user={user}
            s={s}
            categoryABC={categoryABC}
            championship_category_id={championship_category_id}
            championship_id={championship_id}
            championship_sub_category_id={championship_sub_category_id}
          />
        ))}
      </div>
    </div>
  );
};

type ChildParticipantType = {
  user: Users;
  AdmissionAdmin: AdmissionAdminsType[];
  fAdmin: AdminType[];
  ch: createChampType;
  P: participantType;
  login: Users[];
};

const ChildParticipant: FC<ChildParticipantType> = ({
  user,
  AdmissionAdmin,
  fAdmin,
  ch,
  P,
  login,
}) => {
  let c: Users = login.find((item: Users) => item.id === P?.child) || {};
  const dispatch = useAppDispatch();
  let fAdmissionAdmin: AdmissionAdminsType =
    AdmissionAdmin.find(
      (item: AdmissionAdminsType) =>
        item?.championship_id === ch.id && item?.child === c?.id
    ) || {};
  const AdmissionAdmins = () => {
    dispatch(
      AddAdmissionAdmins({ championship_id: ch.id, child: c?.id, allow: 1 })
    );
  };
  const doNotAllow = () => {
    let deleteAllows = window.confirm("Точно хотите убрать " + c?.name + "?");
    if (deleteAllows) {
      dispatch(
        DeleteAdmissionAdmins({
          id: fAdmissionAdmin?.id,
          championship_id: ch.id,
        })
      );
    }
  };

  const countries = useAppSelector((state) => state.login.country);
  const regions = useAppSelector((state) => state.login.region);
  const cities = useAppSelector((state) => state.login.city);
  let country: country | any =
    countries.find((item) => item.id === c?.country) || {};
  let region: region | any =
    regions.find((item) => item.id === c?.region) || {};
  let city: city | any = cities.find((item) => item.id === c?.city) || {};
  let coach: Users = login.find((item: Users) => item.id === P?.coach) || {};
  return (
    <div className="participantMapContainer">
      <span className="ava" id="participantAva">
        {c?.ava ? (
          <img
            className="avaImg"
            src={Api + "storage/avatar/prev_" + c?.ava}
            alt=""
          />
        ) : (
          <img
            className="avaImg"
            src={"https://yaponaroll.ru/images/20200701_001.jpg"}
            alt=""
          />
        )}
      </span>
      <span className="dataParticipant">
        <span className="nameDataPart">{c?.name}</span>
        <Link to={"/" + c?.login} className="loginDataParti">
          login: {c?.login}
        </Link>
        <span className="yearDataParti">year: {c?.year}</span>
        <span className="coachDataParti">coach: {coach?.name}</span>
        <span className="adressParti">
          {region?.name} {city?.name}
        </span>

        {fAdmissionAdmin?.allow === 1 ? (
          <span className="allowAdmin">Allow</span>
        ) : (
          <></>
        )}
      </span>
      <span className="participantFlag">
        {country?.name === "Kazakhstan" ? <KZ /> : country?.name}
      </span>
      {(fAdmin.length > 0 || ch?.creator === user?.id) && (
        <span className="ParticipantCheckmark">
          <ImCheckmark
            style={{
              width: "20px",
              height: "20px",
              margin: "2px",
              fill: "rgb(3, 145, 98)",
            }}
            onClick={AdmissionAdmins}
          />

          <ImCross
            style={{
              width: "20px",
              height: "20px",
              margin: "3px",
              fill: "red",
            }}
            onClick={doNotAllow}
          />
        </span>
      )}
    </div>
  );
};
