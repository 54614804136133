import { createSlice, createAsyncThunk, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { api, instanceGET, instancePOST } from './axiosInstans';
import { url } from './url';

let Apiq = url();

export type paymentType = {
    id?: any
    supplier_id?: any
    client_id?: number
    amount?: any
    title?: string
    payment_date?: any
    created_at?: any
    updated_at?: any
    status?: any
    type?: any
}

export const PaymentADD = createAsyncThunk<any, paymentType, { rejectValue: string }>(
    'payment/PaymentADD',
    async function ({ client_id, title, amount }, { rejectWithValue }) {
        if (amount) {
            try {
                console.log({title});
                const jsonS = {
                    client_id,
                    amount,
                    title,
                }

                const response: any = await api.post(Apiq + 'api/auth/payment', jsonS);
                return await response.data.data as paymentType
            } catch (error) {
                return rejectWithValue('Can\'t add task. Server error.');
            }
        }
    }
);
export const PaymentDelete = createAsyncThunk<paymentType, paymentType, { rejectValue: string }>(
    'payment/PaymentDelete',
    async function ({ id }, { rejectWithValue }) {
        try {
            const jsonS = {
                id,
            }
            const response: any = await api.post(Apiq + 'api/auth/delpayment', jsonS);
            // dispatch(addsetka(response.data));
            return await response.data.data as paymentType
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const getPayment = createAsyncThunk<paymentType[], any, { rejectValue: string }>(
    'payment/getPayment',
    async function (_, { rejectWithValue }) {
        try {
            const response: any = await api.post(Apiq + 'api/auth/getpayment');
            return await response.data.data as paymentType[]
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);
export const pageOpenPayment = createAsyncThunk<any, any, { rejectValue: string }>(
    'payment/pageOpenPayment',
    async function ({ id }, { rejectWithValue }) {
        try {
            return (await id) as any
        } catch (error) {
            return rejectWithValue('Can\'t add task. Server error.');
        }
    }
);


export type paymentStateType = {
    payment: paymentType[]
    statePageOpen: string[]
    loading: boolean
    error: any
}

const initialState: paymentStateType = {
    payment: [],
    statePageOpen: [],
    loading: false,
    error: null,
}

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(PaymentADD.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(PaymentADD.fulfilled, (state, action) => {
                state.payment.push(action.payload);
            })
            .addCase(PaymentDelete.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(PaymentDelete.fulfilled, (state, action) => {
                state.payment = state.payment.filter((t: paymentType) => t.id !== action.payload.id);
            })
            .addCase(getPayment.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getPayment.fulfilled, (state, action) => {
                let d: paymentType[] = state.payment;
                let g: paymentType[] = action.payload;
                for (let i = 0; i < g.length; i++) {
                    let fD: paymentType[] = d.filter((item: paymentType) => item?.id === g[i].id)
                    if (fD.length === 0) {
                        d = [...d, g[i]];
                    }
                }
                state.payment = d;
                state.loading = false;
            })
            .addMatcher(isError, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
    },
});

// const { addsetka, getSetkiler } = setkaSlice.actions;

export default paymentSlice.reducer;
const isError = (action: AnyAction) => {
    return action.type.endsWith('rejectad');
}