import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { FC, useState } from "react";
import { useAppSelector } from "../../../store/hook";

const Password: FC<any> = ({ updatePass }) => {
  let Adam = {
    password: '',
    cpassword: '',
  }
  
const [names, setNames] = useState(Adam);
const updateSignin = (e: React.ChangeEvent<HTMLInputElement>, name: any) => {
  setNames(prevState => ({
    ...prevState,
    [name]: e.target.value
  }));
}
  
  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Поле обязательно к заполнению').min(5, 'Минимум 5 символа!').max(50, 'Максимум 50 символов!'),
    cpassword: Yup.string().oneOf([Yup.ref('password')], 'Пароли не совпадают')
  });

  const recPass: any = useAppSelector(state => state.login.recPass);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });
  // let loginERR;
  // if (!login.status){
  //   loginERR = <div>{login.message}</div>
  // } 

  // if(login.login) {
  //   return <Navigate to={"/" + login.login} />
  // }


  return (
    <div>
      <h1>Изменение пароля</h1>
      <form onSubmit={handleSubmit(updatePass)}>
        <label className="labelForm">
          {names?.password === '' ? <div className="registerInputDiv"></div> : <div className="registerInputDiv">Введите новый пароль</div>}
          <input
            id="registerInput"
            type="password"
            placeholder='Введите новый пароль'
            {...register("password", {
              onChange: (e) =>{ updateSignin(e, 'password') },
              required: "Поле обязательно к заполнению",
              minLength: {
                value: 5,
                message: 'Минимум 5 символов!'
              }
            })} />
        </label>
        <div style={{ height: 40 }} className="regErr">
          {errors?.password && <p>{(errors as any)?.password?.message || "Error!"}</p>}
        </div>

        <label className="labelForm">
          {names?.cpassword === '' ? <div className="registerInputDiv"></div> : <div className="registerInputDiv">Потверждение пароля</div>}
          <input
            id="registerInput"
            type="password"
            placeholder='Потверждение пароля'
            {...register("cpassword", {
              onChange: (e) =>{ updateSignin(e, 'cpassword') },
              required: "Поле обязательно к заполнению",
              minLength: {
                value: 5,
                message: 'Минимум 5 символов!'
              }
            })} />
        </label>
        <div style={{ height: 40 }} className="regErr">
          {errors?.cpassword && <p>{(errors as any)?.cpassword?.message || "Error!"}</p>}
        </div>

        {recPass?.status && <div className='regGreen'>Пароль сохранено успешно!</div>}

        <input type="submit" disabled={!isValid} />
      </form>
    </div>
  );
};

export default Password;