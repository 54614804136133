import { FC } from 'react'
import { RemoveChildrens } from './removeChildrens'
import { Users } from '../../../../../store/loginSlice'
import { Inputs } from './remove/inputs'

type SelectOptionsType = {
    classSel?: boolean
    assocSel?: boolean
    coachSel?: boolean
    subjectSel?: boolean
    yearSel?: boolean
    monthSel?: boolean
    arr?: any
    User?: Users
    Userter?: Users[]
    functionR?: any
    R?: any
    status?: string
    hide?: boolean
    pageData?: string
    myGradeCoach?: boolean
    shadowOf?: Function
    Subject?: any
    title?: string
    addClass?: any
    addSubject?: any
    newText?: any
    newSubject?: any
}

export const SelectOptions: FC<SelectOptionsType> = ({ shadowOf, myGradeCoach, pageData, coachSel, classSel, assocSel, status, arr, User, functionR, R, hide, subjectSel, yearSel, monthSel, Userter, Subject, title, addClass, addSubject, newText, newSubject, }) => {
    return (
        <div className="SettingFon">
            <div className="AllSubs">
                {assocSel && <h3 className='trOptions'>Ассоциация</h3>}
                {coachSel && <h3 className='trOptions'>Учителя</h3>}
                {classSel && <h3 className='trOptions'>Классы</h3>}
                {subjectSel && <h3 className='trOptions'>Предметы</h3>}
                {yearSel && <h3 className='trOptions'>Год</h3>}
                {monthSel && <h3 className='trOptions'>Месяц</h3>}

                {(User?.position === 3 && User?.id === pageData) && < Inputs classSel={classSel} subjectSel={subjectSel} Subject={Subject} title={title} addClass={addClass} addSubject={addSubject} newText={newText} newSubject={newSubject} />}
                {arr.map((c: any) => (
                    <RemoveChildrens shadowOf={shadowOf} myGradeCoach={myGradeCoach} pageData={pageData} coachSel={coachSel} classSel={classSel} assocSel={assocSel} status={status} subjectSel={subjectSel} User={User} Userter={Userter} hide={hide} title={(classSel || subjectSel || monthSel) ? c.title : c} id={(classSel || subjectSel || monthSel) ? c.id : c} functionR={functionR} R={R} />
                ))}
            </div>
        </div>
    )
}