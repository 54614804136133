import { FC, useEffect, useMemo, useState } from "react";
import {
  AdminType,
  AdmissionAdminsType,
  SType,
  updateLoser,
  updateWinner,
} from "../../../../../../store/setkaSlice";
import {
  Users,
  city,
  country,
  region,
} from "../../../../../../store/loginSlice";
import {
  createChampType,
  participantType,
} from "../../../../../../store/settingSlice";
import { GiPodiumWinner } from "react-icons/gi";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hook";
import { url } from "../../../../../../store/url";
import "./style.css";
import KZ from "../../../../kz";

const Api = url();

type ShowSetkaType = {
  AdmissionAdmin: AdmissionAdminsType[];
  fAdmin: AdminType[];
  champ: createChampType;
  allGridPart: SType[];
  login: Users[];
  childPart: participantType[];
  user: Users;
};

export const Grid: FC<ShowSetkaType> = ({
  AdmissionAdmin,
  fAdmin,
  user,
  champ,
  allGridPart,
  login,
  childPart,
}) => {
  let arrNumber: any = [];
  allGridPart.map((f: SType) => (arrNumber = [...arrNumber, f?.i_grid]));
  let massiv_num: any = arrNumber.map((i: any) => Number(i));

  var max = massiv_num.sort((a: any, b: any) => a - b)[arrNumber.length - 1];

  let lengthArr0 = allGridPart.filter((item: SType) => item?.i_grid === 0);
  let maxL = 0;
  for (let sl = 1; sl <= 16384; sl *= 2) {
    let sj = sl * 2;
    if (lengthArr0.length > sl && lengthArr0.length <= sj) {
      maxL = sj; // 4, 8, 16, 32, 64
    }
  }

  let BigPlace: number = 0; // logika chtobi uznat bolshuyu kolichestvo setku iz vnutry setki
  let Memo = useMemo(() => {
    for (let i = 0; i < allGridPart.length; i++) {
      let value = allGridPart[i].place;
      if (value !== undefined) {
        let currentValue = value;
        if (currentValue > BigPlace) {
          BigPlace = currentValue;
        }
      }
    }
    return BigPlace;
  }, [allGridPart]);
  BigPlace = Memo ? Memo : 0;

  let ca: any[] = [];
  for (let i = 0; i < maxL; i++) {
    ca = [...ca, 0];
  }
  let kk: any = [];
  for (let indGr = 0; indGr <= max; indGr++) {
    let jj: number[] = [];
    for (let index = 0; index < BigPlace; index++) {
      jj = [...jj, 0];
    }
    kk = [...kk, jj];
  }

  let ch: any[] = [];
  for (let ii = BigPlace; ii >= 1; ii /= 2) {
    ch = [...ch, ii];
  }

  const [arrGrid, setArrGrid] = useState<any[]>(kk);

  useEffect(() => {
    setArrGrid(kk);
  }, []);

  const clickHeaderGrid = (t: number, i: number) => {
    let li: number[] = [];
    for (let inx = 0; inx < t; inx++) {
      li = [...li, 0];
    }
    kk[i] = [...li];
    setArrGrid([...kk]);
  };

  const [globalClick, setGlobalClick] = useState(null);

  return (
    <div>
      <div className="showSetka">
        {arrGrid.map((ag: any[], i: number) => (
          <SekaNaOdnom
            fAdmin={fAdmin}
            user={user}
            champ={champ}
            globalClick={globalClick}
            setGlobalClick={setGlobalClick}
            BigPlace={BigPlace}
            ag={ag}
            i={i}
            ch={ch}
            clickHeaderGrid={clickHeaderGrid}
            childPart={childPart}
            allGridPart={allGridPart}
            login={login}
          />
        ))}
      </div>
    </div>
  );
};

type SekaNaOdnomType = {
  fAdmin: AdminType[];
  user: Users;
  champ: createChampType;
  ag: any[];
  i: number;
  ch: any[];
  clickHeaderGrid: any;
  childPart: participantType[];
  allGridPart: SType[];
  login: Users[];
  BigPlace: number;
  setGlobalClick: any;
  globalClick: number | null;
};

export const SekaNaOdnom: FC<SekaNaOdnomType> = ({
  fAdmin,
  user,
  champ,
  globalClick,
  setGlobalClick,
  BigPlace,
  ag,
  i,
  ch,
  clickHeaderGrid,
  childPart,
  allGridPart,
  login,
}) => {
  const [clickHeaderGridHover, setClickHeaderGridHover] = useState(BigPlace);

  useEffect(() => {
    if (globalClick !== i) {
      setClickHeaderGridHover(BigPlace);
    }
  }, [ag]);

  const d = BigPlace / ag.length;

  return (
    <nav>
      <ul className="HeaderGridNumUl">
        {ch.map((c) => (
          <HeaderGridNum
            setGlobalClick={setGlobalClick}
            c={c}
            i={i}
            clickHeaderGrid={clickHeaderGrid}
            setClickHeaderGridHover={setClickHeaderGridHover}
            clickHeaderGridHover={clickHeaderGridHover}
          />
        ))}
      </ul>
      <nav className="showSetka1">
        <main className="styleTextGrid">№ {i + 1}</main>
        <nav className="setkaShowDataContainer">
          {ag.map((item: any, index: any) => (
            <DataSetki
              fAdmin={fAdmin}
              champ={champ}
              user={user}
              BigPlace={BigPlace}
              d={d}
              place={ag.length}
              indexAg={i + 1}
              childPart={childPart}
              allGridPart={allGridPart}
              login={login}
              i_grid={i}
              i_inside_each_grid={index}
              clickHeaderGridHover={clickHeaderGridHover}
            />
          ))}
        </nav>
      </nav>
    </nav>
  );
};

type HeaderGridNumType = {
  c: number;
  clickHeaderGrid: any;
  i: number;
  setClickHeaderGridHover: any;
  clickHeaderGridHover: number;
  setGlobalClick: any;
};

export const HeaderGridNum: FC<HeaderGridNumType> = ({
  setGlobalClick,
  c,
  clickHeaderGrid,
  i,
  setClickHeaderGridHover,
  clickHeaderGridHover,
}) => {
  let l: any = c;
  if (c === 4) {
    l = "Полуфинал";
  }
  if (c === 2) {
    l = "Финал";
  }
  if (c === 1) {
    l = "Чемпион";
  }
  return (
    <li
      className="HeaderGridNum"
      id={clickHeaderGridHover === c ? "HeaderGridTrue" : ""}
      onClick={(e) => {
        clickHeaderGrid(c, i);
        setClickHeaderGridHover(c);
        setGlobalClick(i);
      }}
    >
      {l}
    </li>
  );
};

type dataSetkiType = {
  fAdmin: AdminType[];
  user: Users;
  champ: createChampType;
  allGridPart: SType[];
  login: Users[];
  i_grid: number;
  i_inside_each_grid: number;
  childPart: participantType[];
  indexAg: number;
  clickHeaderGridHover: number;
  place: number;
  BigPlace: number;
  d: number;
};
const DataSetki: FC<dataSetkiType> = ({
  fAdmin,
  user,
  champ,
  BigPlace,
  d,
  place,
  login,
  i_grid,
  i_inside_each_grid,
  allGridPart,
  childPart,
  indexAg,
  clickHeaderGridHover,
}) => {
  const dispatch = useAppDispatch();
  let setkalar: boolean = false;
  let participantGrid =
    allGridPart.find(
      (item) =>
        item.i_grid === i_grid &&
        (item.i_inside_each_grid || 0) <= (i_inside_each_grid + 1) * d - 1 &&
        (item.i_inside_each_grid || 0) > (i_inside_each_grid + 1) * d - d - 1 &&
        (item?.place || 0) <= place
    ) || {};
  let participant: participantType =
    childPart.find(
      (item: participantType) =>
        item?.id === participantGrid?.championship_participant_id
    ) || {};
  let c: Users = login.find((item) => item.id === participant?.child) || {};

  function isEven(value: any) {
    if (value % 2 == 0) return true;
    else return false;
  }
  let st = isEven(i_inside_each_grid + 1);

  const id = participantGrid?.id;

  const winner = () => {
    dispatch(updateWinner({ id, place }));
  };
  const loser = () => {
    dispatch(updateLoser({ id, place }));
  };

  const countries = useAppSelector((state) => state.login.country);
  const regions = useAppSelector((state) => state.login.region);
  const cities = useAppSelector((state) => state.login.city);
  let country: country | any =
    countries.find((item) => item.id === c?.country) || {};
  let region: region | any =
    regions.find((item) => item.id === c?.region) || {};
  let city: city | any = cities.find((item) => item.id === c?.city) || {};
  let coach: Users =
    login.find((item: Users) => item.id === participant?.coach) || {};

  return (
    <div className="DataSetkiContainer">
      {participantGrid?.place === place &&
        (fAdmin.length > 0 || champ?.creator === user?.id) && (
          <nav className="gridCheckmarkContainer">
            <ImCheckmark
              className="GridImCheckmark"
              style={{
                width: "20px",
                height: "20px",
                margin: "2px",
                fill: "rgb(3, 145, 98)",
              }}
              onClick={winner}
            />

            {BigPlace !== place && (
              <ImCross
                className="GridImCheckmark"
                style={{
                  width: "20px",
                  height: "20px",
                  margin: "3px",
                  fill: "red",
                }}
                onClick={loser}
              />
            )}
          </nav>
        )}
      {/* <span>
                {st ? <svg width='100%' height="100%" viewBox="0 0 300 300"><path d="M290.461996,148.509687h-202.055446c0,0-.737785-144.783905-.737785-144.783905" transform="matrix(1.01 0 0 1-1.511176 1.490313)" fill="none" stroke="#3f5787" stroke-width="70" stroke-linecap="round" stroke-linejoin="round" /></svg> : <svg width='100%' height="100%" viewBox="0 0 300 300"><path d="M290.461996,148.509687h-202.055446c0,0,.000004,144.78.000004,144.78" transform="matrix(1.01 0 0 1-1.511176 1.490313)" fill="none" stroke="#3f5787" stroke-width="70" stroke-linecap="round" stroke-linejoin="round" /></svg>}
            </span> */}
      {/* <span>{i_inside_each_grid + 1}</span> */}
      <nav className="DataSetkiName">
        <main className="ava noskew" id="avaShowSetka">
          {c?.ava ? (
            <img
              className="avaImg"
              src={Api + "storage/avatar/prev_" + c?.ava}
              alt=""
            />
          ) : (
            <img
              className="avaImg"
              src={"https://yaponaroll.ru/images/20200701_001.jpg"}
              alt=""
            />
          )}
        </main>
        {participantGrid?.i_grid === i_grid ? (
          <p className="dSetka">
            {" "}
            <p className="nameDataGrid">{c?.name}</p>
            <p className="yearDataGrid">year: {c?.year}</p>
            <p className="coachDataGrid">coach: {coach?.name}</p>
            <p className="adressGrid">
              {region?.name} {city?.name}
            </p>
            {!participantGrid?.championship_participant_id &&
              "-----------------------"}{" "}
          </p>
        ) : (
          <p className="dSetka">-----------------------</p>
        )}
        {clickHeaderGridHover === 1 ? (
          <GiPodiumWinner className="GiPodiumWinnerIcon noskew" />
        ) : (
          <nav
            // style={{ height: 20 }}
            id={clickHeaderGridHover === 1 ? "DataSetkiStrelkaNone" : ""}
            className={
              st ? "DataSetkiStrelkaDOWN noskew" : "DataSetkiStrelkaUP noskew"
            }
          ></nav>
        )}
        <nav className="kzFlagGrid">
          <KZ />
        </nav>
      </nav>
    </div>
  );
};
