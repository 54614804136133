import { FC, useEffect } from "react";
import "./style.css";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { getChampionships } from "../../../../store/settingSlice";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../loader";

export const ChampResultCopy: FC<any> = () => {
  // const dispatch = useAppDispatch();
  // useEffect(() => {
  //   dispatch(getChampionships());
  // }, []);
  //   const Params = useParams();
  return (
    <div id="loading">
      <Loader id="loadingTSX" />
      <Navigate to={"/"} />
    </div>
  );
};
