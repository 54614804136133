import { FC } from "react";
import "./style.css";
import { Users } from "../../../../../store/loginSlice";
import { BiSolidMedal } from "react-icons/bi";
import { url } from "../../../../../store/url";
import { BsFillPeopleFill } from "react-icons/bs";
import { participantType } from "../../../../../store/settingSlice";
let Api = url();

type CoachMapType = {
  index: number;
  coach: any;
  login: Users[];
  participant: participantType[];
  championship_id: number;
};

export const CoachMap: FC<CoachMapType> = ({
  index,
  coach,
  login,
  participant,
  championship_id,
}) => {
  let fUser: any = login.find((item: any) => item?.id === coach?.coach) || {};
  let fParticipant: participantType[] = participant.filter(
    (item: participantType) =>
      item?.championship_id === championship_id && item?.coach === coach?.coach
  );
  return (
    <div className="coachMap">
      <div className="coachIndex">{index + 1}</div>
      <div id="avaCoach" className="ava">
        {fUser?.ava ? (
          <img
            className="avaImg"
            src={Api + "storage/avatar/aver_" + fUser?.ava}
            alt=""
          />
        ) : (
          <img
            className="avaImg"
            src={"https://yaponaroll.ru/images/20200701_001.jpg"}
            alt=""
          />
        )}
      </div>
      <div className="medalAndName">
        <div className="coachName">{fUser?.name}</div>
        <div className="medalCoachContainer">
          <div className="medalCoach">
            <div>
              <BiSolidMedal className="BiSolidMedalGold BiSolidMedal" />
            </div>
            <div>{coach?.gold}</div>
          </div>
          <div className="medalCoach">
            <div>
              <BiSolidMedal className="BiSolidMedalSilver BiSolidMedal" />
            </div>
            <div>{coach?.silver}</div>
          </div>
          <div className="medalCoach">
            <div>
              <BiSolidMedal className="BiSolidMedalBronze BiSolidMedal" />
            </div>
            <div>{coach?.bronze}</div>
          </div>
          <div className="medalCoach">
            <div>
              <BsFillPeopleFill className="childCoach BiSolidMedal" />
            </div>
            <div className="childCoachLength">{fParticipant?.length}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
