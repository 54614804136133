import axios from "axios";
import { url } from "./url";
import { Navigate } from "react-router-dom";

let Apiq = url();

export const api = axios.create();

// start Request
api.interceptors.request.use(config => {
    if(localStorage.getItem('attt')){
        config.headers.Authorization = `Bearer ${localStorage.getItem('attt')}`;
    }
    return config;
}, error => {
    console.log(error.response);
    if (error.response.status === 401) {
        return <Navigate to={"/signin"} />
    }
});

//  end Request
api.interceptors.response.use(config =>{
    if(localStorage.getItem('attt')){
        config.headers.Authorization = `Bearer ${localStorage.getItem('attt')}`;
    }
    return config;
}, error => {
    const originalRequest = error.config;
    if (error.response.data.message === 'Token has expired' || error.response.data.message === "Unauthenticated.") {
        // return <Navigate to={"/signin"} />
        return axios.post(Apiq + 'api/auth/refresh', {}, {
            headers: {
                'authorization': `Bearer ${localStorage.getItem('attt')}`
            }
        }).then(res => {
            localStorage.setItem('attt', res.data.access_token);
            error.config.headers.Authorization = `Bearer ${localStorage.getItem('attt')}`;
            
            return api.request(error.config);
        }).catch(error => {
            error.config.headers.Authorization = `Bearer ${localStorage.getItem('attt')}`;
            const originalRequest2 = error.config;
            
            const retryResponse = axios.post(originalRequest.url, originalRequest.data, {
                headers: originalRequest2.headers
            });
            return retryResponse;
        })
    }
    
})

export const apiBot = axios.create();

apiBot.interceptors.request.use(config => {
    if(localStorage.getItem('attt')){
        config.headers.Authorization = `Bearer ${localStorage.getItem('attt')}`;
    }
    return config;
}, error => {
    console.log(error.response);
    if (error.response.status === 401) {
        return <Navigate to={"/signin"} />
    }
});

//  end Request
apiBot.interceptors.response.use(config =>{
    if(localStorage.getItem('attt')){
        config.headers.Authorization = `Bearer ${localStorage.getItem('attt')}`;
    }
    return config;
}, error => {
    const originalRequest = error.config;
    if (error.response.data.message === 'The token has been blacklisted') {
        console.log("ddd");
        
    }
    if (error.response.data.message === 'Token has expired' || error.response.data.message === "Unauthenticated." || error.response.data.message === "The token has been blacklisted") {
        console.log("asdasdasdasdasdasdasd");
        // return <Navigate to={"/signin"} />
        return axios.post(Apiq + 'api/auth/refresh', {}, {
            headers: {
                'authorization': `Bearer ${localStorage.getItem('attt')}`
            }
        }).then(res => {
            console.log("Aktan");
            localStorage.setItem('attt', res.data.access_token);
            error.config.headers.Authorization = `Bearer ${localStorage.getItem('attt')}`;
            
            return apiBot.request(error.config);
        }).catch(error => {
            // apiBot.request(error.config);
            error.config.headers.Authorization = `Bearer ${localStorage.getItem('attt')}`;
            const originalRequest2 = error.config;
            
            const retryResponse = axios.post(originalRequest.url, originalRequest.data, {
                headers: originalRequest2.headers
            });
            return retryResponse;
        })
    }
    console.log(error);
})


export const instancePOST = axios.create({
    baseURL: Apiq,
    headers: { 
        'authorization': `Bearer ${localStorage.getItem('attt')}`,
        'Content-Type': 'multipart/form-data',
     },
});
export const instanceJsonPOST = axios.create({
    baseURL: Apiq,
    headers: { 
        'authorization': `Bearer ${localStorage.getItem('attt')}`,
        "Content-type": "application/json",
     },
});
export const instanceGET = axios.create({
    baseURL: Apiq,
    headers: { "Content-type": "application/json" },
});