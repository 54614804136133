export const notDublicate = (numberArray: any[]) => {
  let numArr: any[] = [];
  for (let i = 0; i < numberArray.length; i++) {
    let f: any[] = numArr.filter((item: any) => item === numberArray[i]);
   
    if (f.length < 1) {
      numArr = [...numArr, numberArray[i]];
    }
  }
  return numArr;
};
