import { FC, useRef } from "react"
import { url } from "../../../../../store/url";
import { Users } from "../../../../../store/loginSlice";
import { useAppDispatch } from "../../../../../store/hook";
import { AdminType, addAdmin, deleteAdmin } from "../../../../../store/setkaSlice";

type SearchAdminType = {
    prof: Users
    admins: AdminType[]
}

export const SearchAdmin: FC<SearchAdminType> = ({ prof, admins }) => {
    let Api = url();
    const dispatch = useAppDispatch();

    const imageRef = useRef(null);

    let admin = prof?.id;
    const addAdm = () => {
        dispatch(addAdmin({ admin }))
    }
    let fAdmin: AdminType[] = admins.filter((item: AdminType) => item?.admin === admin)

    const deleteAdm = () => {
        let id = fAdmin[0]?.id
        dispatch(deleteAdmin({ id }))
    }

    return (
        <div className="sportUser">
            <div>
                <div className="ava">
                    {prof?.ava ? <img className="avaImg" ref={imageRef} src={Api + 'storage/avatar/prev_' + prof?.ava} alt="" /> : <img className="avaImg" ref={imageRef} src={"https://yaponaroll.ru/images/20200701_001.jpg"} alt="" />}
                </div>
            </div>
            <div>
                <div>Логин :{prof?.login}</div>
                <div>{prof?.name}</div>
            </div>
            {prof?.position !== 3 ? <div>
                {fAdmin.length === 0 ? <input type="button" value={'Добавить'} onClick={addAdm} /> : <input type="button" value={'Удалить'} onClick={deleteAdm} />}
            </div> : <div className="red">Association</div>}
        </div>
    )
}