import { MapAllChamp } from "./mapAllChamp";
import { Link } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { RootState } from "../../../store";
import { createChampType, getChampionships } from "../../../store/settingSlice";
import { Users } from "../../../store/loginSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { subscribeType } from "../../../store/subscribeSlice";

type WorldChampionshipsType = {};

export const WorldChampionships: FC<WorldChampionshipsType> = ({}) => {
  const champ = useAppSelector(
    (state: RootState) => state.setting.championships
  );
  const login = useAppSelector((state: RootState) => state.login.log);
  let champReverse = [...(champ as createChampType[])];
  champReverse.reverse();

  const CountryStor = useAppSelector((state) => state.login.country);
  const CityStor = useAppSelector((state) => state.login.city);
  const RegionStor = useAppSelector((state) => state.login.region);

  const [country, setCountry] = useState(0);
  const [city, setCity] = useState(0);
  const [region, setRegion] = useState(0);

  let regionFilter = [];
  let cityFilter = [];
  regionFilter = RegionStor.filter((item) => item.country === country);
  cityFilter = CityStor.filter((item) => item.region === region);

  const updateCountry = (e: number) => {
    setRegion(0);
    setCity(0);
    setCountry(e);
  };

  const updateRegion = (e: number) => {
    setCity(0);
    setRegion(e);
  };

  const updateCity = (e: number) => {
    setCity(e);
  };

  return (
    <div className="RRR">
      {/* <div className="selectTrophy">
                <select onChange={(e) => updateCountry(parseInt(e.target.value))} className="s" name="" id="">
                    <option value="">Страна</option>
                    {CountryStor.map((o) => (
                        <option value={o?.id}>{o?.name}</option>
                    ))}
                </select>
                <select onChange={(e) => updateRegion(parseInt(e.target.value))} className="s" name="" id="">
                    <option value="">Город</option>
                    {regionFilter.map((or) => (
                        <option value={or?.id}>{or?.name}</option>
                    ))}
                </select>
                <select onChange={(e) => updateCity(parseInt(e.target.value))} className="s" name="" id="">
                    <option value="">Село</option>
                    {cityFilter.map((oc) => (
                        <option value={oc?.id}>{oc?.name}</option>
                    ))}
                </select>
            </div> */}
      {champReverse.map((ch) => (
        <Link className="linkChamp" to={"/championship/" + ch.id}>
          <MapAllChamp ch={ch} login={login} />
        </Link>
      ))}
    </div>
  );
};
