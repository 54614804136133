import { FC } from 'react'

export const YearLi: FC<any> = ({ y, yearF, yearSel }) => {
    const click = () => {
        yearF(y)
    }
    return (
        <li onClick={click} id={yearSel === y ? 'liSelSquats' : ""} >
            {y}
        </li>
    )
}