import { FC } from 'react'
import { Navigate } from 'react-router-dom'

export const NoPageFound:FC<any> = ({Token}) => {
    return (
        <div>
            {Token === false && <Navigate to={"/signin"} />}
            404 Error page
        </div>
    )
}