import { useState, useEffect } from "react";
import "./App.css";
// import { setAuthToken } from './components/setAuthToken';
import Header from "./components/header/header";
import {
  addNewChild,
  signInSlice,
  axiosUser,
  Coaches,
  CountrySlise,
  CitySlise,
  RegionSlise,
  AddAva,
  recoverPass,
  hashPassword,
  nullEmailRecover,
  out,
  /*mass, MassengerButton,  getMessenger, SC,*/ registerOpenSlise,
  allLogin,
  meSlice,
  getAssociation,
} from "./store/loginSlice";
// import Massenger from './components/header/massenger';
import { Navigate } from "react-router-dom";
// import { getSportsmens, registerName, getPatencySlice } from './store/namysSlice';
import { getChampionships, getKG } from "./store/settingSlice";
import { useAppDispatch, useAppSelector } from "./store/hook";
import { Users } from "./store/loginSlice";
import { getAdmin } from "./store/setkaSlice";
import { getSubscribe } from "./store/subscribeSlice";

//check jwt token
// const token = localStorage.getItem("token");
// if (token) {
//     setAuthToken(token);
// }

export function App() {
  const [massenger, setMassenger] = useState("");
  const [userpage, setUserpage] = useState("");
  const [login, setLogin] = useState("");
  const [text, setText] = useState("");
  const [firstName, setFirstName] = useState("");
  const [ava, setAva] = useState();
  const { loading, error } = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();
  const logros = useAppSelector((state) => state.login.resError);
  const user = useAppSelector((state) => state.login.user);
  const [newName, setNewName] = useState("");
  let userId = user?.id;

  // const Sp = useAppSelector(state => state.namys.Sportsmens);
  //   const clickNewSportsmen =()=>{
  //     dispatch(registerName({newName, userId}))
  //     setNewName('')
  // }
  const updateNewName = (e: any) => {
    setNewName(e);
  };

  useEffect(() => {
    dispatch(getSubscribe());
    dispatch(allLogin());
    dispatch(getKG());
    dispatch(RegionSlise());
    dispatch(CountrySlise());
    dispatch(CitySlise());
    dispatch(Coaches());
    dispatch(axiosUser());
    dispatch(meSlice());
    dispatch(getAssociation());
    dispatch(getAdmin());
    dispatch(getChampionships());
  }, [dispatch]);

  function uploader(e: any) {
    if (e.target.files[0]) {
      setAva(e.target.files[0]);
    }
  }

  // useEffect(() => {
  //   dispatch(getSportsmens(userId));
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getPatencySlice({userId}));
  // }, [userId]);

  // const otpravit = () =>{
  //     dispatch(MassengerButton({idq, userpage, massenger}));
  //     setMassenger('')
  //   }
  // useEffect(() => {
  //   dispatch(getMessenger());
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(SC());
  // }, [dispatch]);

  const registerOpen = () => {
    dispatch(registerOpenSlise());
  };
  const outLog = () => {
    dispatch(out());
  };
  useEffect(() => {
    if (ava) {
      let id = user.id;
      dispatch(AddAva({ id, ava }));
    }
  }, [ava]);

  const nullEmail = () => {
    dispatch(nullEmailRecover());
  };
  const hashPass = (data: any) => {
    let hash_pass = data.hash;
    dispatch(hashPassword({ hash_pass }));
  };

  const recover = (data: Users) => {
    let email = data.email;
    dispatch(recoverPass({ email }));
  };

  const signIn = (data: Users) => {
    let login = data.login,
      password = data.password;
    // dispatch(fetchSetka());
    // dispatch(SC());
    dispatch(Coaches());
    dispatch(signInSlice({ login, password }));
  };
  // const removeProfile = (data) => {
  //   let firstName = data.firstName,
  //       lastName = data.lastName,
  //       coach = data.coach,
  //       patronymic = data.patronymic,
  //       login = data.login,
  //       tel = data.tel,
  //       email	 = data.email;
  //       // thisCoach = data.thisCoach,
  //       // sportsName = data.sportsName,
  //       // day = data.day,
  //       // month = data.month,
  //       // year = data.year,
  //       // kg = data.kg;

  //        dispatch(addNewChild({firstName, lastName, coach, patronymic, login, tel, email}));
  //    }
  //    if(login.trim().length) {
  //     dispatch(addNewTodo(text));
  //   }

  const signUp = (data: Users) => {
    let name = data.name,
      login = data.login,
      tel = data.tel,
      email = data.email,
      birthday = data.birthday,
      password = data.password;
    dispatch(addNewChild({ name, login, tel, email, password, birthday }));
    if (logros?.status) {
      return <Navigate to={"/signin"} />;
    }
  };
  // const updateLogin = () => {
  //     dispatch(removeUser({login}));
  // }

  // const avaObnovlenie = () => {
  //   dispatch(fetchSetka());
  // }

  // useEffect(() => {
  //   dispatch(fetchSetka());
  // }, [dispatch]);

  // useEffect(() => {
  //   const socket = new WebSocket('ws://toiib.kz/socket.php');

  //   socket.onopen = function() {
  //     console.log('Соединение установлено');
  //   };

  //   socket.onmessage = function(event) {
  //     console.log(`Получено сообщение: ${event.data}`);
  //   };

  //   socket.onclose = function(event) {
  //     console.log('Соединение закрыто!');
  //   };

  //   socket.onerror = function(error: any) {
  //     console.log(`Ошибка: ${error?.message}`);
  //   };
  // }, []);

  //   const WebSocket = require('ws');

  // const wss = new WebSocket.Server({ port: 8080 });

  // wss.on('connection', function connection(ws) {
  //   console.log('Соединение установлено');

  //   ws.on('message', function incoming(message) {
  //     console.log(`Получено сообщение: ${message}`);
  //   });

  //   ws.on('close', function close() {
  //     console.log('Соединение закрыто');
  //   });
  // });

  return (
    <div className="App">
      <Header
        signIn={signIn}
        signUp={signUp}
        uploader={uploader}
        pageId={setUserpage}
        newName={newName}
        updateNewName={updateNewName}
        userId={userId}
        valueMass={massenger}
        updateMass={setMassenger}
        recover={recover}
        hashPass={hashPass}
        nullEmail={nullEmail}
        outLog={outLog}
        registerOpen={registerOpen}
        // getMassenger={getMassenger}
        // otpravit={otpravit}
        // Sp={Sp}
        // clickNewSportsmen={clickNewSportsmen}
      />

      {loading === "loading" && <h2>Loading...</h2>}
      {error && <h2>An error occured: {error}</h2>}
    </div>
  );
}

// import React from "react"
// import Header from "./components/Header"

// class App extends React.Component {
//     constructor(props){
//         super(props)
//         this.state = {
//             helpText: "Help text",
//             userData: "sasas"
//         }

//         this.inputClick = this.inputClick.bind(this)

//     }

// render() {
//     return (<div className="name">
//     <Header title="Shapka saita!" className="ass" />
//     <Header title="Shapka saita" />
//     <Header title="!!!!!" />
//     <h1>{this.state.helpText}</h1>
//     <h2>{this.state.userData}</h2>
//     <input pleaceholder={this.state.helpText}
//         onChange={event => this.setState({userData: event.target.value})}
//         onClick={this.inputClick} onMouseEnter={this.mouseOver} />
//     <p>{this.state.helpText === "Help text!" ? "yes" : "No"}</p>
// </div>)
// }

// inputClick() {
//     this.setState({helpText: "Changed"})
//     console.log("Clicked")
// }
// mouseOver() {console.log("Mouse Over")}
// }

// export default App
