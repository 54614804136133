import { configureStore } from '@reduxjs/toolkit';
// import todoReducer from './todoSlice';
import loginSlice from './loginSlice';
// import namysSlice from './namysSlice';
import setkaSlice from './setkaSlice';
import settingSlice from './settingSlice';
import subscribeSlice from './subscribeSlice';
import gradeSlice from './gradeSlice';
import childrenSlice from './childrenSlice';
import paymentSlice from './payment';
import exercisesSlice from './exercisesSlice';

const store = configureStore({
  reducer: {
    // todos: todoReducer,
    login: loginSlice,
    // namys: namysSlice,
    setka: setkaSlice,
    setting: settingSlice,
    subscribe: subscribeSlice,
    grade: gradeSlice,
    children: childrenSlice,
    payment: paymentSlice,
    exercisess: exercisesSlice,
  },
});

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch