import { FC } from "react";
import { GradeType } from "../../../../../store/gradeSlice";
type TableGradeType = {
  ar: any;
  FGrades: GradeType[];
};

export const TableGrade: FC<TableGradeType> = ({ ar, FGrades }) => {
  let g = null;
  for (let i = 0; i < FGrades.length; i++) {
    let day = FGrades[i]?.created_at?.toString().slice(8).slice(0, -9);
    if (day === ar) {
      g = FGrades[i]?.grades;
    }
  }

  return (
    <th>
      {g === 7 ? (
        <div className="Nred">N</div>
      ) : (
        <div className={ar === "5" ? "green" : ""}>{g}</div>
      )}
    </th>
  );
};
