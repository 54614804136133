import { FC, useRef } from "react"
import { Users } from "../../../../store/loginSlice";
import { url } from "../../../../store/url";

export const ChildrenComponent: FC<any> = ({ prof, profmap, text }) => {
    let Api = url();

    const imageRef = useRef(null);

    let searchProf: Users = profmap.find((item: Users) => item.login === prof?.login)
    return (
        <div className="sportUser">
            <div>
                <div className="ava">
                    {searchProf?.ava ? <img className="avaImg" ref={imageRef} src={Api + 'storage/avatar/prev_' + searchProf?.ava} alt="" /> : <img className="avaImg" ref={imageRef} src={"https://yaponaroll.ru/images/20200701_001.jpg"} alt="" />}
                </div>
            </div>
            <div>
                <div>Логин :{prof?.login}</div>
                <div>{searchProf?.firstName} {searchProf?.lastName}</div>
                {searchProf?.sportsName && <div>{searchProf?.sportsName}</div>}
                {searchProf?.club && <div>Клуб: {searchProf?.club}</div>}
                {searchProf?.coach && <div>Тренер: {searchProf?.coach}</div>}
                {searchProf?.city && <div>Город: {searchProf?.city}</div>}
            </div>
        </div>
    )
}