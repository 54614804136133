import { FC } from 'react'
import { paymentType } from '../../../../../store/payment'
import { Check } from './check'
type ShowCheckType = {
    ShowCheckAll: any
    ChildCheck: paymentType[]
    name: string
    dispatch: any
    ava: string
}

export const ShowChild: FC<ShowCheckType> = ({ ShowCheckAll, ChildCheck, name, dispatch, ava }) => {
    ChildCheck.reverse()
    return (
        <div>
            <div className="selectMyChild" onClick={ShowCheckAll} ></div>
            <div className="selChild">
                {ChildCheck.map((check) => (
                    <Check dispatch={dispatch} check={check} name={name} ava={ava} />
                ))}
            </div>
        </div>
    )
}